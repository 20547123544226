import styled from "styled-components";

const PageCardHeader = ({
  title,
  label,
  siteCount,
  filteredSitesCount,
  children,
}) => {
  return (
    <PageCardHeaderContainer>
      <PageCardHeaderTitle>
        {title}
        {siteCount && (
          <SiteCountLabel
            siteCount={siteCount}
            filteredSitesCount={filteredSitesCount}
          />
        )}
      </PageCardHeaderTitle>
      <PageCardHeaderLabel>
        {label}
        {children}
      </PageCardHeaderLabel>
    </PageCardHeaderContainer>
  );
};

export default PageCardHeader;

const SiteCountLabel = ({ siteCount, filteredSitesCount }) => {
  if (siteCount === filteredSitesCount) {
    return (
      <PageCardHeaderTitleBadge>{siteCount} sites</PageCardHeaderTitleBadge>
    );
  }
  return (
    <PageCardHeaderTitleBadge>
      {filteredSitesCount} sites filtered from {siteCount} total sites
    </PageCardHeaderTitleBadge>
  );
};

const PageCardHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 24px;
  margin: 20px 0px;
  width: 100%;
`;

const PageCardHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #101828;
  margin-bottom: 8px;
`;

const PageCardHeaderTitleBadge = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #2779a7;
  background: #fafafa;
  margin-left: 10px;
  border-radius: 16px;
  align-self: center;
  padding: 2px;
`;

const PageCardHeaderLabel = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  flex-grow: 1;
`;
