import axiosConfig from "./axiosConfig";

const getSiteLog = async (authToken, siteId) => {
  const { data } = await axiosConfig.get(`/site/${siteId}/log`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return data;
};

export default getSiteLog;
