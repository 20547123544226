import styled from "styled-components";
import circleTick from "../images/icons/circleTick.svg";
import circleUntick from "../images/icons/circleUntick.svg";

// onClick={() => onChange(usageProfileOption)}

const RadioButton = ({
  usageProfileOption,
  selectedOption,
  onChange,
  testid,
}) => {
  return (
    <Box onClick={() => onChange(usageProfileOption)} data-testid={testid}>
      <RadioImg
        src={selectedOption === usageProfileOption ? circleTick : circleUntick}
      />
      <TextContainer>
        <Text>{usageProfileOption}</Text>
      </TextContainer>
    </Box>
  );
};

const RadioBox = ({
  usageProfileOptions,
  selectedOption,
  onOptionChange,
  testid,
}) => {
  return (
    <Container>
      {usageProfileOptions.map((usageProfileOption, index) => (
        <RadioButton
          usageProfileOption={usageProfileOption}
          selectedOption={selectedOption}
          onChange={onOptionChange}
          key={index}
          testid={`${testid}-${index}`}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const RadioImg = styled.img`
  padding-left: 10px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  min-width: 110px;
  background-color: #ffffff;
  border: 0.643403px solid #dfdfdf;
  border-radius: 5.14722px;
  padding-right: 16px;
  cursor: pointer;
`;

const TextContainer = styled.div`
  display: flex;
  padding: 9px;
  padding-left: 10px;
  padding-right: 0px;
  margin-bottom: 1px;
  align-items: center;
`;

const Text = styled.span`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.57px;
  align-items: center;
  color: ${({ theme }) => theme.colors.dark};
`;

export default RadioBox;
