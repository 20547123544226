import SimpleButton from "../buttons/SimpleButton";

const SiteLink = ({ siteId, orgId }) => {
  const siteUrl = `/org/${orgId}/site/${siteId}`;
  return (
    <SimpleButton
      fontWeight="400"
      label="View site"
      to={siteUrl}
      data-testid={`site-link-${siteId}`}
    />
  );
};

export default SiteLink;
