import { HeaderContainer, TabLink } from ".";
import EnergyActive from "../../images/icons/EnergyActive.svg";
import EnergyInActive from "../../images/icons/EnergyInActive.svg";
import PVSystemActive from "../../images/icons/PVSystemActive.svg";
import PVSystemInActive from "../../images/icons/PVSystemInActive.svg";
import SummaryActive from "../../images/icons/SummaryActive.svg";
import SummaryInActive from "../../images/icons/SummaryInActive.svg";
import PortfolioTab from "../PortfolioTab";
import SimpleButton from "../buttons/SimpleButton";
import { Row } from "../layout/Flex";
import ShareLinkWithUser from "../modal/ShareLinkWithUser";

const SiteViewHeader = ({
  siteId,
  currentUrl,
  rootPath,
  dashboardMode,
  shareAttributes,
  isOwnerOccupier,
  isOperational,
  energyOffer, // { label, acceptOfferFn, acceptOfferUrl }
  showPvSystemTab,
  showEnergySavingsTab,
}) => {
  const summaryUrl = `${rootPath}site/${siteId}`;
  const energySavingsUrl = `${rootPath}site/${siteId}/energy-savings`;
  const pvSystemUrl = `${rootPath}site/${siteId}/pv-system`;
  const billingsAndStatementsUrl = `${rootPath}site/${siteId}/billings-and-statements`;

  const savingsLabel =
    dashboardMode === "Tenant" ? "Energy Procurement" : "Tenant Savings";
  const energyOfferWidth = energyOffer ? "140px" : "0px";
  return (
    <HeaderContainer>
      <Row
        $padding={"0px"}
        $margin={"0px"}
        $alignItems={"center"}
        $justifyContent={"space-between"}
      >
        <TabLinkItem
          label="Site Summary"
          destinationUrl={summaryUrl}
          currentUrl={currentUrl}
          icon={currentUrl === summaryUrl ? SummaryActive : SummaryInActive}
          testid="site-summary-tab"
        />
        {showEnergySavingsTab && (
          <TabLinkItem
            label={savingsLabel}
            destinationUrl={energySavingsUrl}
            currentUrl={currentUrl}
            icon={
              currentUrl === energySavingsUrl ? EnergyActive : EnergyInActive
            }
            testid="energy-savings-tab"
          />
        )}
        {showPvSystemTab && (
          <TabLinkItem
            label="PV System"
            destinationUrl={pvSystemUrl}
            currentUrl={currentUrl}
            icon={
              currentUrl === pvSystemUrl ? PVSystemActive : PVSystemInActive
            }
            testid="pv-system-tab"
          />
        )}
        {isOperational && (
          <TabLinkItem
            label="Billings & Statements"
            destinationUrl={billingsAndStatementsUrl}
            currentUrl={currentUrl}
            icon={
              currentUrl === billingsAndStatementsUrl
                ? SummaryActive
                : SummaryInActive
            }
            testid="billings-and-statements-tab"
          />
        )}
        <Row
          $padding={"0px"}
          $margin={"0px 0px 0px auto"}
          $gap={"10px"}
          $alignItems={"center"}
          $justifyContent={"space-between"}
        >
          {shareAttributes && (
            <ShareLinkWithUser
              button={isOwnerOccupier}
              dashboardMode={dashboardMode}
              shareAttributes={shareAttributes}
              energyOfferOffset={energyOfferWidth}
            />
          )}
          {energyOffer && (
            <SimpleButton
              fontWeight="400"
              height="40px"
              width={energyOfferWidth}
              label={energyOffer.label}
              to={energyOffer.acceptOfferUrl}
              onClick={energyOffer.acceptOfferFn}
            />
          )}
        </Row>
      </Row>
    </HeaderContainer>
  );
};

//work to be done on shades active icon and inactive icon
const TabLinkItem = ({ label, destinationUrl, currentUrl, icon, testid }) => {
  return (
    <TabLink to={destinationUrl} data-testid={testid}>
      <PortfolioTab
        active={currentUrl === destinationUrl}
        tabName={label}
        icon={icon}
      />
    </TabLink>
  );
};

export default SiteViewHeader;
