import { mapboxKey } from "@inrange/building-manager-api-client";
import { useState } from "react";
import styled from "styled-components";
import magnifyingGlass from "../../images/icons/magnifyingGlass.svg";
import IconInput from "../input/IconInput";

const AddSiteAddressBar = ({ suggestionHandler }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [currentValue, setCurrentValue] = useState("");

  const mapboxAddressSearch = (input) => {
    return `https://api.mapbox.com/geocoding/v5/mapbox.places/${input}.json?access_token=${mapboxKey}&autocomplete=true`;
  };

  const searchAddress = (e) => {
    const input = e.target.value;
    setCurrentValue(input);
    fetch(mapboxAddressSearch(input))
      .then((res) => res.json())
      .then((data) => {
        setSuggestions(data?.features || []);
      });
  };

  const handleValidationOnBlur = (e) => {
    const focusedElement = e.relatedTarget ?? {};
    // Check if a search suggestion was clicked
    const compareString =
      focusedElement?.id === "suggestion"
        ? focusedElement?.innerText
        : e.target.value;
    const addressInSuggestion = suggestions.filter(
      (suggestion) => suggestion.place_name === compareString
    )[0];
    suggestionHandler(addressInSuggestion);
    setCurrentValue(
      addressInSuggestion ? addressInSuggestion.place_name : compareString
    );
    setSuggestions([]);
  };

  return (
    <AddressBarWrapper>
      <IconInput
        type={"search"}
        onChange={searchAddress}
        icon={magnifyingGlass}
        onFocus={searchAddress}
        onBlur={handleValidationOnBlur}
        placeholder="Enter site address"
        value={currentValue}
        clear={true}
        width={"100%"}
        height={"40px"}
        padding={"10px"}
        ellipsis={true}
        borderRadius={"0.25rem"}
        testId={"addsite-addressbar"}
      />
      <Suggestions length={suggestions.length} id="suggestions">
        {suggestions.map((suggestion, index) => (
          <Suggestion
            key={suggestion.id}
            id="suggestion"
            tabIndex={0}
            data-testid={`addsite-suggestion-${index}`}
          >
            {suggestion.place_name}
          </Suggestion>
        ))}
      </Suggestions>
    </AddressBarWrapper>
  );
};

const AddressBarWrapper = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  background-color: transparent;
`;

const Suggestions = styled.div`
  position: absolute;
  width: 100%;
  height: fit-content;
  z-index: 10;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  ${({ length }) => `
    border: ${length ? "1px solid #e5e7eb" : "none"};`}
  background-color: #ffffff;
`;

const Suggestion = styled.div`
  border-bottom: 1px solid #e5e7eb;
  padding: 10px;
  font-size: 14px;
`;

export default AddSiteAddressBar;
