const theme = {
  colors: {
    grey100: "#F5F5F5",
    grey200: "#EEEEEE",
    grey250: "#E5E7EB",
    grey300: "#9f9f9f",
    grey400: "#DFDFDF",
    grey700: "#666666",
    grey800: "#9F9F9F",
    grey900: "#808080",
    greyFaint: "#2779a7",

    blueFaint: "#EBF2F6",
    blueSolid: "#4077A3",
    blueTabColor: "#5386ac",

    greenFaint: "#EFFDF4",
    greenSolid: "#54B471",

    redFaint: "#FAE5E3",
    redSolid: "#DE5242",

    primary: "#00022D",
    primaryLight: "#000239",
    dark: "#00022F",
  },
  fonts: {
    primary: '"Manrope", sans-serif',
  },
  fontSize: {},
};

export default theme;
