import axiosConfig from "./axiosConfig";

const deleteSiteMarketplaceMatch = async (authToken, siteId, match) => {
  const { data } = await axiosConfig.delete(`/site/${siteId}/marketplace`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    data: match,
  });
  return data;
};

export default deleteSiteMarketplaceMatch;
