import { calcCfeScore } from "@inrange/calculations/calcCfeScore";
import {
  getScoreBySiteID,
  inrangeScoreStatusColor,
  siteStatusMap,
} from "@inrange/theme-components";
import styled from "styled-components";
import { sqmToSqFtNearestTen, sqmToSqFtRaw } from "../../formatting/formatArea";
import { formatCurrencyToNearestTen } from "../../formatting/formatCurrency";
import formatMass from "../../formatting/formatMass";
import roundUpPayback from "../../utils/roundUpPayback";
import GenerationHeadroomCell from "./GenerationHeadroomCell";
import SiteLink from "./SiteLink";
import SiteNameAddressData from "./SiteNameAddressData";
import TableCellData from "./TableCellData";

import { SiteScore } from "@inrange/theme-components";

const SiteRow = ({
  site,
  organisation,
  mode,
  leaseRevenue,
  licenseRevenue,
  irr,
  payback,
  emptyRow,
  displayInRangeScoreCol,
  allSitesNotStarted,
  showTenantColumn,
}) => {
  if (emptyRow) {
    const emptyDescription = `There are no sites currently set to ${mode}. You can change this on the site summary for any site.`;

    return (
      <tr>
        <td colSpan={5}>
          <TableCellData>{emptyDescription}</TableCellData>
        </td>
      </tr>
    );
  }

  const carbonEmissions = site.calculations.emissionsAvoided.totalAvoidance;
  const carbonFreeEnergy = calcCfeScore(site.energyFlowAnnual) * 100;
  let tenants = [];
  if (showTenantColumn) {
    tenants = site.siteOwnerships
      .filter((ownership) => ownership.ownership === "tenant")
      .map((tenant) => tenant.name);
  }
  const tenantString =
    tenants.length > 3
      ? tenants.slice(0, 3).join("\r\n") + "..."
      : tenants.join("\r\n");
  return (
    <SiteRowView
      site={site}
      mode={mode}
      siteId={site.id}
      siteName={site.name}
      siteAddress={site.address}
      carbonEmissions={carbonEmissions}
      carbonFreeEnergy={carbonFreeEnergy}
      payback={payback}
      rooftopArea={site.totalBuildingArea}
      orgID={organisation.id}
      irr={irr}
      licenseRevenue={licenseRevenue}
      leaseRevenue={leaseRevenue}
      tenantString={tenantString}
      showTenantColumn={showTenantColumn}
      displayInRangeScoreCol={displayInRangeScoreCol}
      allSitesNotStarted={allSitesNotStarted}
    />
  );
};

const SiteRowView = ({
  site,
  mode,
  irr,
  payback,
  licenseRevenue,
  leaseRevenue,
  tenantString,
  showTenantColumn,
  carbonEmissions,
  carbonFreeEnergy,
  rooftopArea,
  orgID,
  displayInRangeScoreCol,
  allSitesNotStarted,
}) => {
  const score = getScoreBySiteID(orgID, site.id);

  if (displayInRangeScoreCol) {
    return (
      <LandlordSiteRowViewInrangeScoreTrue
        site={site}
        mode={mode}
        irr={irr}
        payback={payback}
        licenseRevenue={licenseRevenue}
        leaseRevenue={leaseRevenue}
        tenantString={tenantString}
        showTenantColumn={showTenantColumn}
        carbonEmissions={carbonEmissions}
        carbonFreeEnergy={carbonFreeEnergy}
        rooftopArea={rooftopArea}
        orgID={orgID}
        score={score}
      />
    );
  }
  return (
    <LandlordSiteRowViewInrangeScoreFalse
      site={site}
      mode={mode}
      irr={irr}
      payback={payback}
      licenseRevenue={licenseRevenue}
      leaseRevenue={leaseRevenue}
      carbonEmissions={carbonEmissions}
      carbonFreeEnergy={carbonFreeEnergy}
      rooftopArea={rooftopArea}
      orgID={orgID}
      allSitesNotStarted={allSitesNotStarted}
    />
  );
};

export default SiteRow;

const LandlordSiteRowViewInrangeScoreTrue = ({
  site,
  mode,
  irr,
  payback,
  licenseRevenue,
  leaseRevenue,
  tenantString,
  showTenantColumn,
  carbonEmissions,
  carbonFreeEnergy,
  rooftopArea,
  orgID,
  score,
}) => {
  return (
    <tr key={site.id} id={site.id}>
      <td style={{ width: "170px" }}>
        {site.operationalStatus === "notStarted" ? (
          <SiteScore
            siteScore={score}
            statusColor={inrangeScoreStatusColor[score]}
            removePointer
          />
        ) : (
          <StatusContainer>
            {siteStatusMap[site.operationalStatus].map((status, i) => {
              return <span key={i}>{status}</span>;
            })}
          </StatusContainer>
        )}
      </td>
      <td>
        <TableCellData>
          <SiteNameAddressData
            siteName={site.name}
            siteAddress={site.address}
            siteId={site.id}
            orgId={orgID}
          />
        </TableCellData>
      </td>

      {mode === "license" && (
        <LicenseRows
          irr={irr}
          payback={payback}
          licenseRevenue={licenseRevenue}
        />
      )}
      {mode === "lease" && (
        <LeaseRows
          rooftopArea={rooftopArea}
          leaseRevenue={leaseRevenue}
          displayInRangeScoreCol={true}
          tenantString={tenantString}
          showTenantColumn={showTenantColumn}
        />
      )}
      <td>
        <TableCellData>{formatMass(carbonEmissions)}</TableCellData>
        <TableCellData>{carbonFreeEnergy.toFixed(1)}% CFE</TableCellData>
      </td>
      <td style={{ width: "170px" }}>
        <SiteLink siteId={site.id} orgId={orgID} />
      </td>
    </tr>
  );
};

const LandlordSiteRowViewInrangeScoreFalse = ({
  site,
  mode,
  irr,
  payback,
  licenseRevenue,
  leaseRevenue,
  carbonEmissions,
  carbonFreeEnergy,
  rooftopArea,
  orgID,
  allSitesNotStarted,
}) => {
  return (
    <tr key={site.id} id={site.id}>
      {!allSitesNotStarted && (
        <td style={{ width: "170px" }}>
          <StatusContainer>
            {site.operationalStatus === "notStarted"
              ? "Feasibility study"
              : siteStatusMap[site.operationalStatus].map((status, i) => {
                  return <span key={i}>{status}</span>;
                })}
          </StatusContainer>
        </td>
      )}
      <td>
        <TableCellData>
          <SiteNameAddressData
            siteName={site.name}
            siteAddress={site.address}
            siteId={site.id}
            orgId={orgID}
          />
        </TableCellData>
      </td>
      <td>
        <GenerationHeadroomCell site={site} />
      </td>
      {mode === "license" && (
        <LicenseRows
          irr={irr}
          payback={payback}
          licenseRevenue={licenseRevenue}
        />
      )}
      {mode === "lease" && (
        <LeaseRows
          rooftopArea={rooftopArea}
          leaseRevenue={leaseRevenue}
          displayInRangeScoreCol={false}
        />
      )}
      <td>
        <TableCellData>{formatMass(carbonEmissions)}</TableCellData>
        <TableCellData>{carbonFreeEnergy.toFixed(1)}% CFE</TableCellData>
      </td>
      <td style={{ width: "170px" }}>
        <SiteLink siteId={site.id} orgId={orgID} />
      </td>
    </tr>
  );
};

const LicenseRows = ({ irr, payback, licenseRevenue }) => {
  return (
    <>
      <td>{(irr * 100).toFixed(1)}%</td>
      <td>{roundUpPayback(payback)} months</td>
      <td>{formatCurrencyToNearestTen(licenseRevenue, "GBP", 0, false)}</td>
    </>
  );
};

const LeaseRows = ({
  rooftopArea,
  leaseRevenue,
  displayInRangeScoreCol,
  tenantString,
  showTenantColumn,
}) => {
  return (
    <>
      {displayInRangeScoreCol ? null : (
        <td>{sqmToSqFtNearestTen(rooftopArea)}</td>
      )}
      <td>{formatCurrencyToNearestTen(leaseRevenue, "GBP", 0, false)}</td>
      <td>£{(leaseRevenue / sqmToSqFtRaw(rooftopArea)).toFixed(2)}</td>
      {displayInRangeScoreCol && showTenantColumn ? (
        <td style={{ whiteSpace: "pre" }}>{tenantString}</td>
      ) : null}
    </>
  );
};

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
`;
