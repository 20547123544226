import { Link } from "react-router-dom";
import styled from "styled-components";

const BreadCrumbs = ({ crumbs, rootPath }) => {
  const dashboardUrl = `${rootPath}dashboard`;
  const crumbName = crumbs[crumbs.length - 1];

  return (
    <BreadCrumbsContainer>
      {crumbs.length > 2 ? (
        <>
          {crumbs.slice(0, -1).map((crumb, index) => (
            <Fragment key={crumb}>
              <BreadCrumb
                isLast={index !== crumbs.length - 2}
                data-testid={`breadcrumb-${index}`}
              >
                <BreadCrumbText
                  index={index}
                  crumb={crumb}
                  dashboardUrl={dashboardUrl}
                />
              </BreadCrumb>
              {index !== crumbs.length - 2 && <Separator>/</Separator>}
            </Fragment>
          ))}
        </>
      ) : (
        crumbName && (
          <BreadCrumb data-testid={`breadcrumb-0`}>{crumbName}</BreadCrumb>
        )
      )}
    </BreadCrumbsContainer>
  );
};

const BreadCrumbText = ({ index, crumb, dashboardUrl }) => {
  if (index === 0) {
    return <Link to={dashboardUrl}>{crumb} Portfolio</Link>;
  }
  return crumb;
};

const BreadCrumbsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Fragment = styled.div`
  display: flex;
  align-items: center;
`;

const BreadCrumb = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  a {
    color: ${({ theme }) => theme.colors.grey900};
    text-decoration: none;
  }
  a:hover {
    color: black;
  }
  ${({ isLast, theme }) =>
    isLast && {
      color: theme.colors.grey900,
    }}
`;

const Separator = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #000000;
  margin: 0 8px;
`;

export default BreadCrumbs;
