import { DesktopOnly } from "@inrange/theme-components";
import { useEffect, useState } from "react";

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: 1051, // Assume window is wide enough until we know otherwise
  });

  useEffect(() => {
    function handleResize() {
      setWindowDimensions({
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

const TooNarrow = ({ children }) => {
  const { width } = useWindowDimensions();
  return width > 1050 ? children : <DesktopOnly />;
};

export default TooNarrow;
