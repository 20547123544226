import { trackUserInteraction } from "@inrange/building-manager-api-client/events";
import styled from "styled-components";

const DownloadFile = ({
  downloadDisabled,
  fileName,
  site,
  userEmail,
  downloadURL,
  refetchURL,
}) => {
  const onClick = async (e) => {
    const element = e.target;
    const timeNowEpoch = new Date().getTime() / 1000;
    const urlExpiry = downloadURL ? Number(downloadURL.split("=").pop()) : 0;
    if (urlExpiry < timeNowEpoch) {
      // Prevent the default action of opening the download if the URL has expired
      e.preventDefault();
      // Refetch the URL and update the href attribute of the anchor tag
      const response = await refetchURL();
      const newURL = response.data.url;
      element.href = newURL;
      // Trigger the click event again to open the download
      element.click();
    } else {
      trackUserInteraction(
        {
          site_id: site.id,
          // This event uses siteName rather than site_name
          // Fixing that at this point doesn't seem worth it
          siteName: site.name,
          site_operational_status: site.operationalStatus,
          fileName,
          userEmail,
        },
        "BASIC_ACTION",
        "SITE_FILE_DOWNLOAD",
        userEmail.toLowerCase(),
        "customer-app"
      );
    }
  };
  return (
    <DownloadLink
      fontWeight="400"
      label="Download"
      disabled={downloadDisabled}
      download={fileName}
      onClick={onClick}
      data-testid={`${fileName}-download`}
    >
      Download
    </DownloadLink>
  );
};

export default DownloadFile;

const DownloadLink = styled.a`
  display: flex;
  align-items: center;
  background: ${({ background, theme }) => background || theme.colors.primary};
  color: white;
  border-radius: 8px;
  height: 44px;
  width: fit-content;
  text-decoration: none;
  padding: 16px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  ${({ disabled }) =>
    disabled && {
      opacity: 0.5,
      cursor: "not-allowed",
    }}
`;
