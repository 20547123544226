import axios from "axios";
import { getApiHostname } from "../config/getApiHostname";

const hostname = getApiHostname();

axios.defaults.baseURL = hostname;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common.accept = "application/json";

const instance = axios.create({
  baseURL: hostname,
});

export default instance;
