import axios from "axios";
import hash from "object-hash";
import { useQuery } from "react-query";

const API_URL = "https://d3ec9b5jthpueq.cloudfront.net";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common.accept = "application/json";

const getPVAUrl = () => {
  const isProduction = import.meta.env.VITE_KINDE_AUTH_ENV === "PROD";
  if (isProduction) {
    return "https://7rbvbbr755.execute-api.us-east-1.amazonaws.com";
  }
  return "https://nh41zqd7q4.execute-api.us-east-1.amazonaws.com";
};

const queryOptions = (opts) => {
  return {
    cacheTime: 1000 * 60 * 60, // 1 hour
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    refetchOnWindowFocus: false,
    retry: 3,
    ...opts,
  };
};

// ************************************************
//       Buildings - Geo location of Buildings
// ************************************************
function getBounds(mapBounds) {
  if (mapBounds === undefined) return { north: 0, south: 0, west: 0, east: 0 };
  return {
    north: mapBounds.getNorth(),
    south: mapBounds.getSouth(),
    west: mapBounds.getWest(),
    east: mapBounds.getEast(),
  };
}

async function getBuildingsByBounds(north, south, west, east) {
  const res = await axios.get(
    `${API_URL}/buildings/bounds_postgis/${north}/${south}/${west}/${east}`
  );
  const hashMap = {};
  res.data.buildings.forEach((building) => {
    const key = hash(JSON.stringify(building.geometry.coordinates));
    hashMap[key] = { ...building, key };
  });
  return hashMap;
}

export const useBuildingsByBounds = (mapBounds) => {
  const { north, south, west, east } = getBounds(mapBounds);

  let mapSizeMeters = 100_000;
  if (mapBounds !== undefined)
    mapSizeMeters =
      mapBounds.getNorthEast().distanceTo(mapBounds.getSouthWest()) || 100_000;

  const data = useQuery(
    ["buildings_by_bounds", north, south, west, east],
    async () => {
      return await getBuildingsByBounds(north, south, west, east);
    },
    queryOptions({ enabled: !!mapBounds && mapSizeMeters < 7000 })
  );
  return data;
};

// ************************************************
// PV System
// ************************************************

async function getPvGeoJson(siteId, panel) {
  const pvaUrl = getPVAUrl();
  const url = `${pvaUrl}/pv-geojson?siteid=${siteId}&panelWidth=${panel.width}&panelHeight=${panel.height}`;

  const panelsPointerResponse = await axios.get(url, { timeout: 30_000 });

  const panelsUrl = panelsPointerResponse.data?.panels;
  if (!panelsUrl) {
    throw new Error("No panels found");
  }

  return await axios.get(panelsUrl, {
    timeout: 30_000,
  });
}

export const usePvGeoJson = (siteId, panelData) => {
  return useQuery(
    ["pv_geo_json", siteId, panelData],
    async () => {
      return await getPvGeoJson(siteId, panelData);
    },
    queryOptions({
      // Allow query to be mounted multiple times on the same page without re-fetching
      staleTime: 5 * 60 * 1000,
      enabled: !!siteId && !!panelData,
    })
  );
};

async function requestPvSystemDesign(
  siteData,
  panel,
  buildings,
  options,
  customObjects = undefined
) {
  const data = {
    site: siteData,
    panel,
    buildings,
    custom_objects: customObjects,
    options,
  };

  const pvaUrl = getPVAUrl();
  const url = `${pvaUrl}/pv-design?siteid=${siteData.id}`;
  return await axios.post(url, data, { timeout: 30_000 });
}

export const usePvSystemDesign = (
  siteData,
  panelData,
  buildings,
  autoRefresh
) => {
  return {
    query: useQuery(
      // NOTE: The args here need to match the args passed in:
      // queryClient.invalidateQueries(["pv_system_design", site?.id, panel]);
      // in PVSystem.jsx
      ["pv_system_design", siteData?.id, panelData, autoRefresh],
      async () => {
        return await requestPvSystemDesign(siteData, panelData, buildings, {
          disable_run: autoRefresh,
        });
      },
      {
        enabled: !!siteData && !!panelData && !!buildings,
        refetchInterval: autoRefresh ? 30_000 : false,
        refetchOnWindowFocus: autoRefresh,
      }
    ),
    requestPvSystemDesign,
  };
};

// ************************************************
//       Deeds - Ownership of land
// ************************************************

async function getTitlesByBounds(north, south, west, east) {
  const res = await axios.get(
    `${API_URL}/land_registrations/bounds_postgis/${north}/${south}/${west}/${east}`
  );
  const hashMap = {};
  res.data.land_registrations.forEach((building) => {
    building.isTitleDeed = true;
    const key = hash(building.geometry.coordinates);
    hashMap[key] = { ...building };
  });
  return hashMap;
}

export const useDeedsByBounds = (mapBounds) => {
  const { north, south, west, east } = getBounds(mapBounds);

  const data = useQuery(
    ["deeds_by_bounds", north, south, west, east],
    async () => {
      return await getTitlesByBounds(north, south, west, east);
    },
    queryOptions({ enabled: !!mapBounds })
  );
  return data;
};

async function getGhiByLatLng(latitude, longitude) {
  const res = await axios.get(
    `https://aqodrf0s5k.execute-api.eu-west-1.amazonaws.com/energy/ghi/latlon/${latitude}/${longitude}`
  );
  return res;
}

export const useGhiByLatLng = (lat, lng) => {
  const data = useQuery(
    ["ghi_by_latlng", lat, lng],
    async () => {
      return await getGhiByLatLng(lat, lng);
    },
    queryOptions({ enabled: !!lat && !!lng })
  );
  return data;
};
