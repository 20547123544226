import styled from "styled-components";

const MetricsCard = ({ title, data, dataUnit, arrow, label }) => {
  return (
    <AnnualCarbonSavingsDetail>
      <AnnualCarbonSavingsDetailHeader>{title}</AnnualCarbonSavingsDetailHeader>
      <AnnualCarbonSavingsDetailData>
        {data}
        {dataUnit}
      </AnnualCarbonSavingsDetailData>
      <AnnualCarbonSavingsDetailLabel>
        <AnnualCarbonSavingsDetailLabelArrow>
          <img src={arrow} />
        </AnnualCarbonSavingsDetailLabelArrow>
        <AnnualCarbonSavingsDetailLabelText>
          {label}
        </AnnualCarbonSavingsDetailLabelText>
      </AnnualCarbonSavingsDetailLabel>
    </AnnualCarbonSavingsDetail>
  );
};

const AnnualCarbonSavingsDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 160px;
  height: 106px;
  background: #fafafa;
  border-radius: 16px;
  flex-grow: 1;
`;

const AnnualCarbonSavingsDetailHeader = styled.div`
  width: 100%;
  height: 20px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #2779a7;
  flex-grow: 1;
`;

const AnnualCarbonSavingsDetailData = styled.div`
  width: 100%;
  height: 22px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  flex-grow: 1;
`;

const AnnualCarbonSavingsDetailLabel = styled.div`
  width: 100%;
  height: 18px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  justify-content: flex-start;
  color: #039855;
  flex-grow: 1;
`;

const AnnualCarbonSavingsDetailLabelArrow = styled.div`
  margin-right: 10px;
`;

const AnnualCarbonSavingsDetailLabelText = styled.div`
  display: flex;
  flex-grow: 1;
`;

export default MetricsCard;
