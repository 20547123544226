import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useQuery } from "react-query";
import getSiteFileDownloadUrl from "../http/getSiteFileDownloadUrl";

const useSiteFileDownload = ({
  siteId,
  file,
  isAttachment,
  enabled,
  userOrgId,
}) => {
  const { getToken, getClaim } = useKindeAuth();
  const kindeTokenOrgId = getClaim("external_org_id")?.value;
  userOrgId = kindeTokenOrgId === "inrange.io" ? userOrgId : undefined;

  const fetchSiteFileURL = useQuery(
    ["siteFile", siteId, file],
    async () => {
      return getSiteFileDownloadUrl(
        await getToken(),
        siteId,
        file.fileType,
        file.fileName,
        file.fileGuid,
        isAttachment,
        userOrgId
      );
    },
    {
      enabled,
    }
  );

  return {
    fetchSiteFileURL,
  };
};

export default useSiteFileDownload;
