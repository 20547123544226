import styled from "styled-components";
import getFirstLetter from "../../utils/getFirstLetter";

const ShareLinkWithUserInfoBox = ({ email, id }) => {
  return (
    <Content>
      <InfoStyle>
        <CircleContainer>
          <Circle>
            <InnerCircle>
              <InnerCircle>
                <White>{getFirstLetter(email)}</White>
              </InnerCircle>
            </InnerCircle>
          </Circle>
        </CircleContainer>
        <SharedLink>
          <UserName data-testid={`user-email-list-item-${id}`}>
            {email}
          </UserName>
        </SharedLink>
      </InfoStyle>
    </Content>
  );
};

export default ShareLinkWithUserInfoBox;

const CircleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InfoStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin: 16px 0px 16px 0px;
`;

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 15px 0px 0px;
  background-color: ${({ theme }) => theme.colors.grey400};
`;

const White = styled.div`
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
`;

const InnerCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.dark};
`;

const UserName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.dark};
`;

const SharedLink = styled.div`
  display: flex;
  flex-direction: column;
`;
