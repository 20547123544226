export const getLatestActualsMonthIndex = (site, testMonthToday) => {
  // January is 0, February is 1 etc., we add 1 as the keys in our site attributes are 1-12 for each month
  const monthToday = testMonthToday || new Date().getMonth() + 1;
  let lastMonth = monthToday == 1 ? 12 : monthToday - 1;
  if (!site.energyFlowMonthlyActuals[lastMonth]["metadata"]) {
    lastMonth = lastMonth == 1 ? 12 : lastMonth - 1;
    if (!site.energyFlowMonthlyActuals[lastMonth]["metadata"]) {
      // No actuals for the previous month, or the one before
      lastMonth = undefined;
    }
  }
  return lastMonth;
};

export const getBehindMeterCost = (matchedEnergy) => {
  let behindMeterCost = 0;
  for (const matchedEnergyItem of matchedEnergy) {
    if (
      matchedEnergyItem.sinkType === "demand" &&
      matchedEnergyItem.sourceType != "gridImport"
    ) {
      behindMeterCost +=
        parseFloat(Number(matchedEnergyItem.energyFlow).toFixed(2)) *
        matchedEnergyItem.energyMatchConfig.tariff;
    }
  }
  return behindMeterCost;
};
