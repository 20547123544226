import { useSite } from "@inrange/building-manager-api-client";
import { createContext, useEffect, useState } from "react";

const SiteCalculationsContext = createContext();

const SiteCalculationsProvider = ({
  userOrgId,
  site,
  setSaveDisabled,
  children,
}) => {
  const { previewSite } = useSite({ userOrgId });

  const defaultProjectCosts = site.projectCosts || {};
  const defaultfinancialModels = site.financialModels || {};
  const defaultHalfHourlyGeneration = site.halfHourlyGeneration || {};
  const defaultEnergyFlowAnnual = site.energyFlowAnnual || { generation: 0 };
  const defaultDno = site.dno || {};
  const defaultSubstationData = site.substationData || {};
  const defaultTotalBuildingArea = site.totalBuildingArea || 0;
  const defaultMonthlyTenantEnergyShare =
    site.monthlyTenantEnergyShare || Array(12).fill(0);
  const defaultInvestmentAppraisal = site.investmentAppraisal || {};
  const defaultPVPanelCount = site.pvPanelCount || 0;
  const defaultTenantAnnualDemand = site.tenantAnnualDemandKwh || 0;
  const defaultCostInputs = site.costInputs || {};
  const defaultLeaseLengthMonths = site.leaseLengthMonths;

  const [invalidStates, setInvalidStates] = useState([]);
  const [errors, setErrors] = useState({});
  const [siteCalculations, setSiteCalculations] = useState({
    projectCosts: defaultProjectCosts,
    financialModels: defaultfinancialModels,
    tenantAnnualDemandKwh: defaultTenantAnnualDemand,
    totalBuildingArea: defaultTotalBuildingArea,
    investmentAppraisal: defaultInvestmentAppraisal,
    halfHourlyGeneration: defaultHalfHourlyGeneration,
    energyFlowAnnual: defaultEnergyFlowAnnual,
    monthlyTenantEnergyShare: defaultMonthlyTenantEnergyShare,
    dno: defaultDno,
    substationData: defaultSubstationData,
    pvPanelCount: defaultPVPanelCount,
    costInputs: defaultCostInputs,
    leaseLengthMonths: defaultLeaseLengthMonths,
    blendedExportTariff: site.blendedExportTariff,
  });

  useEffect(() => {
    const updatedErrors = {};
    invalidStates.forEach((error) => {
      // the error response inside invalidStates can look like this:
      //   {
      //       "loc": [
      //           "costInputs",
      //           "panelRate"
      //       ],
      //       "msg": "Must be a number.",
      //       "type": "type_error.float"
      //   }
      // we can therefore join the loc array to get the path to the error
      // i.e. in this case, it would be "costInputs.panelRate"
      updatedErrors[error.loc.join(".")] = error.msg;
    });
    setErrors(updatedErrors);
  }, [invalidStates]);

  if (previewSite.isSuccess) {
    setInvalidStates([]);
    setSiteCalculations({
      ...siteCalculations,
      ...previewSite.data.site,
      // Give local code access to a millisecond accuracy time of when this calculation was completed
      updatedAt: Date.now() / 1000.0,
    });
    previewSite.reset();
  }

  if (previewSite.isError) {
    setInvalidStates(previewSite.error.response?.data?.invalid_states ?? []);
    previewSite.reset();
  }

  const updateCalculations = (site) => {
    if (setSaveDisabled) {
      setSaveDisabled(true);
    }
    previewSite.mutate(
      { site },
      {
        onSettled: () => {
          if (setSaveDisabled) {
            setSaveDisabled(false);
          }
        },
      }
    );
  };

  return (
    <SiteCalculationsContext.Provider
      value={{
        errors,
        siteCalculations,
        setSiteCalculations,
        updateCalculations,
        isCalculating: previewSite.isLoading,
      }}
    >
      {previewSite.isLoading && (
        <LoadingIndicator
          label="Calculating ..."
          testid="calculating-indicator"
        />
      )}
      {children}
    </SiteCalculationsContext.Provider>
  );
};

export { SiteCalculationsContext, SiteCalculationsProvider };

const LoadingIndicator = ({ label, testid }) => {
  return (
    <div
      className="alert alert-primary text-center"
      style={{
        position: "fixed",
        top: 10,
        right: 0,
        width: "100%",
        zIndex: 1000,
      }}
      role="alert"
      data-testid={testid}
    >
      {label}
    </div>
  );
};
