export const sortBy = (sortValueFn) => {
  return (a, b) => {
    const aValue = sortValueFn(a);
    const bValue = sortValueFn(b);
    if (aValue < bValue) return -1;
    else if (aValue > bValue) return 1;
    else return 0;
  };
};

export const sortByAdditional = (sortValueFn, sortFn) => {
  return (a, b) => {
    const result = sortFn(a, b);
    if (result !== 0) return result;
    return sortBy(sortValueFn)(a, b);
  };
};

export const reversed = (sortValueFn) => {
  return (a, b) => {
    return sortValueFn(b, a);
  };
};
