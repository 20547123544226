import postEvent from "../http/postEvent";

const trackUserInteraction = async (
  data,
  subType,
  action,
  userEmail,
  service
) => {
  const eventData = buildData({ data, subType, action, userEmail, service });
  const response = await postEvent(eventData);
  return response;
};

export default trackUserInteraction;

const buildData = ({ data, subType, action, userEmail, service }) => {
  return {
    metadata: {
      type: ["USER_INTERACTION"],
      subType,
      action: action,
      version: "1.0.0",
      timestamp: Date.now(),
      userEmail,
      service,
      hostname: window.location.hostname,
    },
    data: data,
  };
};
