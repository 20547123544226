import {
  LastMonthCostHeader,
  LastMonthDemandFulfilledHeader,
  LastMonthGenerationHeader,
  LastMonthProcurementHeader,
  LastMonthRevenueHeader,
  LastMonthSavingsHeader,
  SiteHeader,
} from "./OperationalSiteListHeaderTooltip";
import { TableHeaderCell } from "./TableHeaderCell";

const OperationalSiteListHeader = ({
  mode,
  handleColumnClick,
  sortColumn,
  sortAscending,
}) => {
  const headerData = [
    { columnName: "name", sortValue: "name", HeaderComponent: SiteHeader },
    {
      columnName: "lastMonthCost",
      sortValue: "lastMonthCost",
      HeaderComponent: LastMonthCostHeader,
    },
    {
      columnName: "lastMonthProcurement",
      sortValue: "lastMonthProcurement",
      HeaderComponent: LastMonthProcurementHeader,
    },
    {
      columnName:
        mode === "leased" ? "lastMonthDemandFulfilled" : "lastMonthRevenue",
      sortValue:
        mode === "leased" ? "lastMonthDemandFulfilled" : "lastMonthRevenue",
      HeaderComponent:
        mode === "leased"
          ? LastMonthDemandFulfilledHeader
          : LastMonthRevenueHeader,
    },
    {
      columnName: "lastMonthSavings",
      sortValue: "lastMonthSavings",
      HeaderComponent: LastMonthSavingsHeader,
    },
    {
      columnName: "lastMonthGeneration",
      sortValue: "lastMonthGeneration",
      HeaderComponent: LastMonthGenerationHeader,
    },
  ];

  return (
    <tr>
      {headerData.map((header) => (
        <TableHeaderCell
          key={header.columnName}
          handleColumnClick={handleColumnClick}
          columnName={header.columnName}
          sortColumn={sortColumn}
          sortAscending={sortAscending}
          sortValue={header.sortValue}
          HeaderComponent={header.HeaderComponent}
        />
      ))}
      <th></th>
    </tr>
  );
};

export default OperationalSiteListHeader;
