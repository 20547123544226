import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useMutation, useQueries, useQueryClient } from "react-query";
import getOrganisationUsers from "../http/getOrganisationUsers";
import postOrganisationUser from "../http/postOrganisationUser";

const useOrgListUsers = (orgIds, siteId, userOrgId) => {
  const queryClient = useQueryClient();
  const { getToken, getClaim } = useKindeAuth();
  const kindeTokenOrgId = getClaim("external_org_id")?.value;
  userOrgId = kindeTokenOrgId === "inrange.io" ? userOrgId : undefined;

  const orgListUsers = useQueries(
    orgIds.map((orgId) => ({
      queryKey: ["org", orgId, "users"],
      queryFn: async () => {
        if (!orgId) {
          return [];
        }

        return getOrganisationUsers(await getToken(), orgId, siteId, userOrgId);
      },
    }))
  );

  const addOrganisationUser = useMutation(
    async (userData) => {
      const { orgId, ...data } = userData;
      return postOrganisationUser(await getToken(), orgId, data, userOrgId);
    },
    {
      onSuccess: (_, userData) => {
        const { orgId } = userData;
        queryClient.invalidateQueries(["org", orgId]);
      },
    }
  );

  return {
    orgListUsers,
    addOrganisationUser,
  };
};

export default useOrgListUsers;
