import styled from "styled-components";
import formatCurrency from "../../formatting/formatCurrency";

const SiteDemandDisplay = ({ tariff, behindMeter, networkImport }) => {
  const total = (behindMeter + networkImport) * tariff;
  const behindMeterValue = behindMeter * tariff;
  const networkImportValue = networkImport * tariff;
  return (
    <SiteDemandDisplayWrapper>
      <ProcurementData>
        <h3>Tariff</h3>
        <div>
          <h2>InRange Fixed Tariff 10YR</h2>
          <p>{(tariff * 100).toFixed(2)}p / kWh</p>
        </div>
        <div>
          <h2>On-site behind the meter procured</h2>
          <p>{formatCurrency(behindMeterValue)}</p>
        </div>
        <div>
          <h2>Aggregated from InRange Network</h2>
          <p>{formatCurrency(networkImportValue)}</p>
        </div>
      </ProcurementData>
      <TotalData>
        <h2>Total Energy procured from Inrange</h2>
        <h3>{formatCurrency(total)}</h3>
        <p>10YR - As Generated</p>
      </TotalData>
    </SiteDemandDisplayWrapper>
  );
};

export default SiteDemandDisplay;

const SiteDemandDisplayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  height: 520px;
`;

const ProcurementData = styled.div`
  border-radius: 16px;
  width: 480px;
  background: white;
  padding: 8px 16px;
  //padding: 8px 54px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  h2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }
`;

const TotalData = styled.div`
  border-radius: 16px;
  width: 480px;
  height: 184px;
  background: ${({ theme }) => theme.colors.primary}};
  padding: 32px 16px 8px;
  display: flex;
  flex-direction: column;
  color: white;
  h2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    margin: 8px 0 4px;
    padding: 0;
  }
  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 28px;
    margin: 0;
    padding: 0;
    text-align: center;
  }
`;
