import styled from "styled-components";
import MockForm from "./mockForm.png";

const SiteDemandUserForm = () => {
  return (
    <Wrapper>
      <img src={MockForm} alt="Mock Form" />
    </Wrapper>
  );
};

export default SiteDemandUserForm;

const Wrapper = styled.div`
  width: 480px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 540px;
    margin-top: 20px;
  }
`;
