import { useMemo } from "react";
import { useParams } from "react-router-dom";
import LandlordSiteListView from "../../views/LandlordSiteListView";
import OperationalSiteListView from "../../views/OperationalSiteListView";
import { getSitesByType } from "./dashboard-utils";

const LandlordSiteList = ({ organisation, sites, isLeaseMetrics }) => {
  let { siteType } = useParams();
  if (siteType === undefined) siteType = "landlord";
  const orgID = organisation.id;
  const [sitesByType, landlordSites] = useMemo(() => {
    const newSitesByType = getSitesByType(sites, orgID, [
      "landlord",
      "ownerOccupier",
      "operational",
    ]);
    return [
      newSitesByType,
      [...newSitesByType["landlord"], ...newSitesByType["ownerOccupier"]],
    ];
  }, [sites, orgID]);

  if (siteType === "operational") {
    return (
      <OperationalSiteListView
        operationalSites={sitesByType["operational"]}
        organisation={organisation}
        startingSortColumn={"name"}
      />
    );
  }

  return (
    <LandlordSiteListView
      sites={landlordSites}
      organisation={organisation}
      isLeaseMetrics={isLeaseMetrics}
    />
  );
};

export default LandlordSiteList;
