const getSiteType = (site, orgId) => {
  const orgOwnership = site.siteOwnerships.find(
    (ownership) => ownership.orgID === orgId
  );
  return site.operationalStatus === "operational"
    ? "operational"
    : orgOwnership.ownership;
};

export const getSitesByType = (sites, orgID, allowedTypes) => {
  return sites.reduce(
    (acc, site) => {
      const siteType = getSiteType(site, orgID);
      if (acc[siteType] !== undefined) {
        acc[siteType].push(site);
      }
      return acc;
    },
    allowedTypes.reduce((acc, type) => ({ ...acc, [type]: [] }), {})
  );
};
