import { useParams } from "react-router-dom";
import LandlordSiteListView from "../../views/LandlordSiteListView";
import OperationalSiteListView from "../../views/OperationalSiteListView";
import TenantSiteListView from "../../views/TenantSiteListView";
import { getSitesByType } from "./dashboard-utils";

const TenantSiteList = ({ organisation, sites, isLeaseMetrics }) => {
  let { siteType } = useParams();
  const orgID = organisation.id;
  const sitesByType = getSitesByType(sites, orgID, [
    "tenant",
    "ownerOccupier",
    "operational",
  ]);

  if (siteType === undefined) {
    if (sitesByType["tenant"].length > 0) {
      siteType = "tenant";
    } else if (sitesByType["ownerOccupier"].length > 0) {
      siteType = "ownerOccupier";
    } else {
      siteType = "operational";
    }
  }

  if (siteType === "ownerOccupier") {
    return (
      <LandlordSiteListView
        sites={sitesByType["ownerOccupier"]}
        organisation={organisation}
        isOwnerOccupied={true}
        isLeaseMetrics={isLeaseMetrics}
      />
    );
  }

  if (siteType === "operational") {
    return (
      <OperationalSiteListView
        operationalSites={sitesByType["operational"]}
        organisation={organisation}
        startingSortColumn={"name"}
      />
    );
  }

  return (
    <TenantSiteListView
      sites={sitesByType["tenant"]}
      organisation={organisation}
      ownershipFilter={siteType}
    />
  );
};

export default TenantSiteList;
