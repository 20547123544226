// Usage:
// import { Icons } from "@inrange/theme-components/icons";
// <img src={Icons.bell} />

import InRangeLogo from "../images/inrange-logo.svg";

import InRangeLogoWhite from "../images/inrange-logo-white.svg";

const reqSvgs = import.meta.glob("../images/icons/*.svg", {
  as: "url",
  eager: true,
});
const svgPaths = Object.keys(reqSvgs);
const Icons = svgPaths.reduce((images: Record<string, string>, path) => {
  const iconName = path.replace("../images/icons/", "").replace(".svg", "");
  images[iconName] = reqSvgs[path];
  return images;
}, {});

export { Icons, InRangeLogo, InRangeLogoWhite };
