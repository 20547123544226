import axiosConfig from "./axiosConfig";

const deleteOrganisationUser = async (authToken, orgId, user) => {
  const { data } = await axiosConfig.delete(`/organisation/${orgId}/user`, {
    data: user,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return data;
};

export default deleteOrganisationUser;
