import { ResponsiveBar } from "@nivo/bar";
import { colors, theme } from ".";

const BarChart = ({
  chartData,
  indexBy,
  keys,
  tooltipFn,
  paddingProp,
  tickRotationProp,
  barColors,
  legend,
  stacked,
}) => {
  let tickRotation = tickRotationProp;
  if (tickRotation === undefined) tickRotation = indexBy === "month" ? 0 : 90;
  let padding = paddingProp;
  if (padding === undefined) padding = 0.3;
  if (barColors === undefined) barColors = colors;

  return (
    <ResponsiveBar
      data={chartData}
      indexBy={indexBy}
      groupMode={stacked ? "stacked" : "grouped"}
      keys={keys}
      margin={{
        left: 70,
        bottom: 60,
        top: 10,
      }}
      padding={padding}
      colors={barColors}
      colorBy={chartData.index}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisLeft={{
        tickSize: 0,
        tickPadding: 1,
        tickRotation: 0,
        legendPosition: "middle",
        legendOffset: -50,
        legend: legend,
        format: (value) => {
          if (legend === "Thousands of £") {
            return `${value / 1_000}`;
          }
          return `${value}`;
        },
      }}
      axisBottom={{
        tickSize: 0,
        tickPadding: 10,
        tickRotation: tickRotation,
        legendPosition: "middle",
        legendOffset: 32,
      }}
      theme={theme}
      label={false}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      tooltip={tooltipFn}
    />
  );
};
export default BarChart;
