import calcIsOwnerOccupied from "../../utils/calcIsOwnerOccupied";
import { getBehindMeterCost } from "../../views/sitelist-utils";

export const calcTotalAnnualGeneration = (sites) => {
  const totalAnnualGeneration = sites.reduce((acc, site) => {
    return acc + site.energyFlowAnnual.generation;
  }, 0);
  return totalAnnualGeneration;
};

export const calcTotalAnnualDemand = (sites) => {
  const totalAnnualDemand = sites.reduce((acc, site) => {
    return acc + site.energyFlowAnnual.demand;
  }, 0);
  return totalAnnualDemand;
};

export const calcTotalEnergyProcured = (sites) => {
  const totalEnergyProcured = sites.reduce((acc, site) => {
    return acc + site.energyFlowAnnual.procurement;
  }, 0);
  return totalEnergyProcured;
};

export const calcTotalEnergyProcuredCost = (sites) => {
  const totalEnergyProcuredCost = sites.reduce((acc, site) => {
    return acc + getBehindMeterCost(site.energyFlowAnnual.matchedEnergy);
  }, 0);
  return totalEnergyProcuredCost;
};

export const calcEmissionsAvoided = (sites) => {
  const emissionsAvoided = sites.reduce((acc, site) => {
    return acc + Number(site.calculations.emissionsAvoided.totalAvoidance);
  }, 0);
  return emissionsAvoided;
};

export const calcTotalHardwareCost = (sites) => {
  const totalHardwareCost = sites.reduce((acc, site) => {
    return acc + site.projectCosts.hardware;
  }, 0);
  return totalHardwareCost;
};

export const calcTotalInitialCost = (sites) => {
  const totalInitialCost = sites.reduce((acc, site) => {
    return acc + site.projectCosts.initialInvestment;
  }, 0);
  return totalInitialCost;
};

export const calcTotalMaintenanceCost = (sites) => {
  const totalMaintenanceCost = sites.reduce((acc, site) => {
    return acc + site.projectCosts.maintenance;
  }, 0);
  return totalMaintenanceCost;
};

export const calcTotalInstallationCost = (sites) => {
  const totalInstallationCost = sites.reduce((acc, site) => {
    return acc + site.projectCosts.installation;
  }, 0);
  return totalInstallationCost;
};

export const calcTotalProjectCost = (sites) => {
  const totalProjectCost = sites.reduce((acc, site) => {
    return acc + site.projectCosts.total;
  }, 0);
  return totalProjectCost;
};

export const calcTotalSavings = (sites, organisation) => {
  let totalRevenue = 0;
  totalRevenue = sites.reduce((acc, site) => {
    const isOwnerOccupier = calcIsOwnerOccupied(site, organisation);
    return (
      acc +
      (isOwnerOccupier
        ? site.financialModels.ownerOccupier.lease.savings
        : site.financialModels.tenant.savings)
    );
  }, 0);
  return totalRevenue;
};

export const calcTotalOnSiteGenerationConsumed = (sites) => {
  const totalOnSiteGenerationConsumed = sites.reduce((acc, site) => {
    return acc + site.energyFlowAnnual.behindMeter;
  }, 0);
  return totalOnSiteGenerationConsumed;
};

export const calcTotalNetworkImport = (sites) => {
  const totalNetworkImport = sites.reduce((acc, site) => {
    return acc + site.energyFlowAnnual.networkImport;
  }, 0);
  return totalNetworkImport;
};

export const calcTotalGridImport = (sites) => {
  const totalGridImport = sites.reduce((acc, site) => {
    return acc + site.energyFlowAnnual.gridImport;
  }, 0);
  return totalGridImport;
};

export const calcTotalTariff = (sites) => {
  const totalTariff = sites.reduce((acc, site) => {
    return acc + site.tenantTariff;
  }, 0);
  return totalTariff;
};

export const calcAverageTariff = (sites) => {
  if (sites.length === 0) return 0;
  const totalTariff = calcTotalTariff(sites);
  const averageTariff = totalTariff / sites.length;
  return averageTariff;
};

export const calcAverageMarketTariff = (sites) => {
  const totalTariff = sites.reduce((acc, site) => {
    return acc + site.marketTariff;
  }, 0);
  const averageTariff = totalTariff / sites.length;
  return averageTariff;
};

export const calcTotalProjectCostPerKgCo2 = (sites) => {
  const totalProjectCost = calcTotalProjectCost(sites);
  const annualProjectCost = totalProjectCost / 20;
  const emissionsAvoided = calcEmissionsAvoided(sites);
  const totalProjectCostPerKgCo2 =
    annualProjectCost / (emissionsAvoided / 1_000);
  return totalProjectCostPerKgCo2;
};

export const countSitesByOwnershipAndOperationalStatus = (
  sites,
  orgSiteOwnerships
) => {
  const siteById = sites.reduce((acc, site) => {
    return { ...acc, [site.id]: site };
  }, {});
  const siteOwnershipsWithOperationalStatus = orgSiteOwnerships.map(
    (siteOwnership) => {
      const operationalStatus =
        siteById[siteOwnership.siteID]?.operationalStatus;
      return {
        ...siteOwnership,
        operationalStatus,
      };
    }
  );

  const countSitesByOwnership = siteOwnershipsWithOperationalStatus.reduce(
    (acc, siteOwnershipWithOperationalStatus) => {
      if (
        siteOwnershipWithOperationalStatus.operationalStatus === "operational"
      ) {
        acc["operational"] = acc["operational"] + 1 || 1;
      } else {
        acc[siteOwnershipWithOperationalStatus.ownership] =
          acc[siteOwnershipWithOperationalStatus.ownership] + 1 || 1;
      }
      return acc;
    },
    {}
  );
  return countSitesByOwnership;
};

export const calcTotalRevenue = (organisation, sites, investmentModel) => {
  let totalRevenue = 0;
  totalRevenue = sites.reduce((acc, site) => {
    const isOwnerOccupier = calcIsOwnerOccupied(site, organisation);
    return (
      acc +
      (isOwnerOccupier
        ? site.financialModels.ownerOccupier[investmentModel].revenue
        : site.financialModels.landlord[investmentModel].revenue)
    );
  }, 0);
  return totalRevenue;
};

export const calcTotalRoofspace = (sites) => {
  const total = sites.reduce((acc, site) => {
    return acc + site.totalBuildingArea;
  }, 0);
  return total;
};
