import styled from "styled-components";

const InputContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 14px;
  align-items: center;
  padding: 10px;
  gap: 8px;
  width: ${(props) => props.$width};
  height: 40px;
  background-color: #ffffff;
  border: 1px solid ${({ theme }) => theme.colors.grey250};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 0.25rem;
  align-self: stretch;
`;

export default InputContainer;
