import styled from "styled-components";
import Sidebar from "../components/Sidebar";

const ErrorSidebar = ({ children, onClickLogout }) => {
  return (
    <Container>
      <FixedSidebar>
        <Sidebar links={[]} onClickLogout={onClickLogout} width={150} />
      </FixedSidebar>
      <MainContainer>
        <ChildContainer>{children}</ChildContainer>
      </MainContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 0;
  margin: 0;
  background: ${({ theme }) => theme.colors.grey100};
  height: 100vh;
`;

const MainContainer = styled.div`
  background: ${({ theme }) => theme.colors.grey100};
  margin: 0 0 0 100px;
  padding: 0 0 50px 0;
`;

const ChildContainer = styled.div`
  border-top: 5px solid ${({ theme }) => theme.colors.grey100};
  margin: 0;
  padding: 0;
`;

export default ErrorSidebar;

const FixedSidebar = styled.div`
  background: white;
  border-right: 5px solid ${({ theme }) => theme.colors.grey100};
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow-x: hidden;
`;
