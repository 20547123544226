export const colors = ["#00022F", "#2779A7", "#9f9f9f", "#6f6f6f"];
export const colorsWithBattery = [
  "#00022F",
  "#2A2C76",
  "#2779A7",
  "#9f9f9f",
  "#6f6f6f",
];

export const theme = {
  textColor: "rgb(128, 128, 128, 0.7)",
  axis: {
    fontColor: "rgb(128, 128, 128, 0.7)",
    ticks: {
      line: {
        stroke: "rgb(128, 128, 128, 0.7)",
      },
      text: {
        fill: "rgb(128, 128, 128, 0.7)",
      },
    },
  },
  grid: {
    line: {
      stroke: "rgb(223, 223, 223, 0.3)",
    },
  },
};
