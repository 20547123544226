import { v4, v5 } from "uuid";

export const generateId = () => {
  return v4();
};

export const generateIdFromText = (text) => {
  // Generate a UUID v5 based on the text
  const id = v5(text, "2a9618d0-aabd-46a1-9ee5-2d7f2a0034f4");
  return id;
};
