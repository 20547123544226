import styled from "styled-components";

const DualButton = ({ button1, button2, middleButton, ...props }) => {
  return (
    <ButtonContainer {...props}>
      <Button1
        {...props}
        $active={button1.active}
        onClick={button1.onClick}
        data-testid={button1.testId}
      >
        <span>{button1.label}</span>
      </Button1>
      {middleButton && (
        <Button2
          {...props}
          $active={middleButton.active}
          onClick={middleButton.onClick}
          itsMiddleBtn
          data-testid={middleButton.testId}
        >
          <span>{middleButton.label}</span>
        </Button2>
      )}
      <Button2
        {...props}
        $active={button2.active}
        onClick={button2.onClick}
        data-testid={button2.testId}
      >
        <span>{button2.label}</span>
      </Button2>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #f3f3f3;
`;

const Button1 = styled.button`
  display: flex;
  align-items: center;
  ${({ $active }) => ($active ? `color:white` : `color:#00022f`)};
  ${({ $active }) => ($active ? `background:#00022f` : "background: white")};
  border-radius: 8px 0px 0px 8px;
  border: none;
  height: 44px;
  padding: 16px;
  cursor: pointer;
  span {
    width: 100%;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }
  ${({ disabled }) =>
    disabled && {
      opacity: 0.5,
      cursor: "not-allowed",
    }}
`;

const Button2 = styled.button`
  display: flex;
  align-items: center;
  ${({ $active }) => ($active ? `color:white` : `color:#00022f`)};
  ${({ $active }) => ($active ? `background:#00022f` : "background: white")};
  ${({ itsMiddleBtn }) =>
    itsMiddleBtn ? "border-radius: 0px" : "border-radius: 0px 8px 8px 0px"};
  border: none;
  height: 44px;
  padding: 16px;
  cursor: pointer;
  span {
    width: 100%;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }
  ${({ disabled }) =>
    disabled && {
      opacity: 0.5,
      cursor: "not-allowed",
    }}
`;

export default DualButton;
