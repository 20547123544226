import {
  CarbonSavingsHeader,
  IRRHeader,
  NetworkAvailabilityHeader,
  PaybackHeader,
  RevenueHeader,
  RevenuePerAreaHeader,
  RooftopLeasedHeader,
  SiteHeader,
  SiteStatusHeader,
  TenantsHeader,
} from "./LandlordSiteListHeaderTooltip";
import { TableHeaderCell } from "./TableHeaderCell";

const LandlordSiteListHeader = ({
  mode,
  handleColumnClick,
  sortColumn,
  displayInRangeScoreCol,
  allSitesNotStarted,
  sortAscending,
  showTenantColumn,
}) => {
  if (mode === "lease") {
    return (
      <LandlordSiteListHeaderLease
        displayInRangeScoreCol={displayInRangeScoreCol}
        mode={mode}
        handleColumnClick={handleColumnClick}
        sortColumn={sortColumn}
        sortAscending={sortAscending}
        allSitesNotStarted={allSitesNotStarted}
        showTenantColumn={showTenantColumn}
      />
    );
  }
  return (
    <LandlordSiteListHeaderLicense
      displayInRangeScoreCol={displayInRangeScoreCol}
      handleColumnClick={handleColumnClick}
      sortColumn={sortColumn}
      sortAscending={sortAscending}
      allSitesNotStarted={allSitesNotStarted}
    />
  );
};

const LandlordSiteListHeaderLicense = ({
  displayInRangeScoreCol,
  allSitesNotStarted,
  handleColumnClick,
  sortColumn,
  sortAscending,
}) => {
  if (displayInRangeScoreCol) {
    return (
      <LandlordSiteListHeaderLicenseInrangeScoreTrue
        handleColumnClick={handleColumnClick}
        sortColumn={sortColumn}
        sortAscending={sortAscending}
      />
    );
  }
  return (
    <LandlordSiteListHeaderLicenseInrangeScoreFalse
      handleColumnClick={handleColumnClick}
      sortColumn={sortColumn}
      sortAscending={sortAscending}
      allSitesNotStarted={allSitesNotStarted}
    />
  );
};

const LandlordSiteListHeaderLease = ({
  displayInRangeScoreCol,
  allSitesNotStarted,
  mode,
  sortColumn,
  sortAscending,
  handleColumnClick,
  showTenantColumn,
}) => {
  if (displayInRangeScoreCol) {
    return (
      <LandlordSiteListHeaderLeaseInrangeScoreTrue
        mode={mode}
        handleColumnClick={handleColumnClick}
        sortColumn={sortColumn}
        sortAscending={sortAscending}
        showTenantColumn={showTenantColumn}
      />
    );
  }
  return (
    <LandlordSiteListHeaderLeaseInrangeScoreFalse
      mode={mode}
      handleColumnClick={handleColumnClick}
      sortColumn={sortColumn}
      sortAscending={sortAscending}
      allSitesNotStarted={allSitesNotStarted}
    />
  );
};

export default LandlordSiteListHeader;

const LandlordSiteListHeaderLicenseInrangeScoreTrue = ({
  sortColumn,
  sortAscending,
  handleColumnClick,
}) => {
  const headerData = [
    {
      columnName: "siteStatusLicense",
      sortValue: "siteStatusLicense",
      HeaderComponent: SiteStatusHeader,
    },
    { columnName: "name", sortValue: "name", HeaderComponent: SiteHeader },
    { columnName: "irr", sortValue: "irr", HeaderComponent: IRRHeader },
    {
      columnName: "payback",
      sortValue: "payback",
      HeaderComponent: PaybackHeader,
    },
    {
      columnName: "licenseRevenue",
      sortValue: "licenseRevenue",
      HeaderComponent: RevenueHeader,
    },
    {
      columnName: "carbonSavings",
      sortValue: "carbonSavings",
      HeaderComponent: CarbonSavingsHeader,
    },
  ];

  return (
    <tr>
      {headerData.map((header) => (
        <TableHeaderCell
          key={header.columnName}
          handleColumnClick={handleColumnClick}
          columnName={header.columnName}
          sortColumn={sortColumn}
          sortAscending={sortAscending}
          sortValue={header.sortValue}
          HeaderComponent={header.HeaderComponent}
          displayInRangeScoreCol={true}
        />
      ))}
      <th></th>
    </tr>
  );
};

const LandlordSiteListHeaderLicenseInrangeScoreFalse = ({
  handleColumnClick,
  sortColumn,
  sortAscending,
  allSitesNotStarted,
}) => {
  const headerData = [
    { columnName: "name", sortValue: "name", HeaderComponent: SiteHeader },
    {
      columnName: "networkAvailabilityLicense",
      sortValue: "networkAvailabilityLicense",
      HeaderComponent: NetworkAvailabilityHeader,
    },
    { columnName: "irr", sortValue: "irr", HeaderComponent: IRRHeader },
    {
      columnName: "payback",
      sortValue: "payback",
      HeaderComponent: PaybackHeader,
    },
    {
      columnName: "licenseRevenue",
      sortValue: "licenseRevenue",
      HeaderComponent: RevenueHeader,
    },
    {
      columnName: "carbonSavings",
      sortValue: "carbonSavings",
      HeaderComponent: CarbonSavingsHeader,
    },
  ];

  if (!allSitesNotStarted) {
    headerData.unshift({
      columnName: "siteStatusLicense",
      sortValue: "siteStatusLicense",
      HeaderComponent: SiteStatusHeader,
    });
  }

  return (
    <tr>
      {headerData.map((header) => (
        <TableHeaderCell
          key={header.columnName}
          handleColumnClick={handleColumnClick}
          columnName={header.columnName}
          sortColumn={sortColumn}
          sortAscending={sortAscending}
          sortValue={header.sortValue}
          HeaderComponent={header.HeaderComponent}
          displayInRangeScoreCol={false}
        />
      ))}
      <th></th>
    </tr>
  );
};

const LandlordSiteListHeaderLeaseInrangeScoreTrue = ({
  handleColumnClick,
  mode,
  sortColumn,
  sortAscending,
  showTenantColumn,
}) => {
  const headerData = [
    {
      columnName: "siteStatusLease",
      sortValue: "siteStatusLease",
      HeaderComponent: SiteStatusHeader,
    },
    { columnName: "name", sortValue: "name", HeaderComponent: SiteHeader },
    {
      columnName: "leaseRevenue",
      sortValue: "leaseRevenue",
      HeaderComponent: RevenueHeader,
    },
    {
      columnName: "leaseRevenuePerArea",
      sortValue: "leaseRevenuePerArea",
      HeaderComponent: RevenuePerAreaHeader,
    },
    {
      columnName: "tenants",
      sortValue: "tenants",
      HeaderComponent: TenantsHeader,
    },
    {
      columnName: "carbonSavings",
      sortValue: "carbonSavings",
      HeaderComponent: CarbonSavingsHeader,
    },
  ];

  if (!showTenantColumn) {
    headerData.splice(4, 1);
  }

  return (
    <tr>
      {headerData.map((header) => (
        <TableHeaderCell
          key={header.columnName}
          handleColumnClick={handleColumnClick}
          columnName={header.columnName}
          sortColumn={sortColumn}
          sortAscending={sortAscending}
          sortValue={header.sortValue}
          HeaderComponent={header.HeaderComponent}
          mode={mode}
          displayInRangeScoreCol={true}
        />
      ))}
      <th>{/* View Site */}</th>
    </tr>
  );
};

const LandlordSiteListHeaderLeaseInrangeScoreFalse = ({
  handleColumnClick,
  mode,
  sortColumn,
  sortAscending,
  allSitesNotStarted,
}) => {
  const headerData = [
    { columnName: "name", sortValue: "name", HeaderComponent: SiteHeader },
    {
      columnName: "networkAvailabilityLease",
      sortValue: "networkAvailabilityLease",
      HeaderComponent: NetworkAvailabilityHeader,
    },
    {
      columnName: "roofArea",
      sortValue: "roofArea",
      HeaderComponent: RooftopLeasedHeader,
    },
    {
      columnName: "leaseRevenue",
      sortValue: "leaseRevenue",
      HeaderComponent: RevenueHeader,
    },
    {
      columnName: "leaseRevenuePerArea",
      sortValue: "leaseRevenuePerArea",
      HeaderComponent: RevenuePerAreaHeader,
    },
    {
      columnName: "carbonSavings",
      sortValue: "carbonSavings",
      HeaderComponent: CarbonSavingsHeader,
    },
  ];

  if (!allSitesNotStarted) {
    headerData.unshift({
      columnName: "siteStatusLease",
      sortValue: "siteStatusLease",
      HeaderComponent: SiteStatusHeader,
    });
  }

  return (
    <tr>
      {headerData.map((header) => (
        <TableHeaderCell
          key={header.columnName}
          handleColumnClick={handleColumnClick}
          columnName={header.columnName}
          sortColumn={sortColumn}
          sortAscending={sortAscending}
          sortValue={header.sortValue}
          HeaderComponent={header.HeaderComponent}
          mode={mode}
          displayInRangeScoreCol={false}
        />
      ))}
      <th>{/* View Site */}</th>
    </tr>
  );
};
