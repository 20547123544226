// TODO - This will soon be migrated to the backend as an
// attribute of the site model. The reason to hold off on
// implementing the tidier solution (attr on backend) is
// that this value is currently exclusively used in the
// customer app frontend. Once this value is required on
// the Admin app or desired in a report for management,
// we can think about refactoring.
export const calcCfeScore = (energyFlow) => {
  const { demand, behindMeter, networkImport } = energyFlow;
  if (demand === 0) return 1;
  return (behindMeter + networkImport) / demand;
};

export const calcBehindTheMeter = (energyFlow) => {
  const { demand, behindMeter } = energyFlow;
  if (demand === 0) return 1;
  return behindMeter / demand;
};

export const calcInRangeNetwork = (energyFlow) => {
  const { demand, networkImport } = energyFlow;
  if (demand === 0) return 1;
  return networkImport / demand;
};
