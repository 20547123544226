import styled, { keyframes } from "styled-components";

const Loading = ({ label }) => {
  return (
    <LoadingContainer>
      <LoadingDot1></LoadingDot1>
      <LoadingDot2></LoadingDot2>
      <LoadingDot3></LoadingDot3>
      <LoadingText data-testid="loading-label">{label}</LoadingText>
    </LoadingContainer>
  );
};

const LoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const breatheAnimation = keyframes`
 from {
    transform: scale(0);
  }
  to {
    transform: scale(8);
  }
}
`;

const LoadingDot1 = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  left: 43.5%;
  border-radius: 9999px;
  background: #2779a7;
  box-shadow: 0px 9.18172px 18.3634px rgba(0, 2, 47, 0.25);
`;

const LoadingDot2 = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  left: 43.5%;
  border-radius: 9999px;
  background: #2779a7;
  opacity: 0.3;
  box-shadow: 0px 9.18172px 18.3634px rgba(0, 2, 47, 0.25);
  animation-name: ${breatheAnimation};
  animation-duration: 4s;
  animation-iteration-count: infinite;
`;

const LoadingDot3 = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  left: 43.5%;
  border-radius: 9999px;
  background: #2779a7;
  opacity: 0.1;
  box-shadow: 0px 9.18172px 18.3634px rgba(0, 2, 47, 0.25);
  animation-name: ${breatheAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;

const LoadingText = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #2779a7;
  margin-top: 100px;
`;

export default Loading;
