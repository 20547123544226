import axiosConfig from "./axiosConfig";

const deleteSite = async (authToken, siteId) => {
  const { data } = await axiosConfig.delete(`/site/${siteId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return data;
};

export default deleteSite;
