import { ResponsiveRadialBar } from "@nivo/radial-bar";
import styled from "styled-components";
import Tooltip from "./Tooltip";
import { SectionTitle } from "./Typography";

const AnnualCarbonSavings = ({
  emissionsAvoided,
  cfePeriodPercent,
  determineSiteFromMetricData,
}) => {
  return (
    <AnnualCarbonSavingsContainer>
      <MetricTooltip>
        <Tooltip
          text={
            determineSiteFromMetricData === "landlord"
              ? "CFE is calculated based on hourly data as (# MWh contracted with InRange)  + (#MWh consumed from grid * proportion of regional grid CFE)) / (# MWh total demand).\n\nEmissions avoided in the first year after installation across all sites where the CO2e multiplier per MWh is based on the annual carbon intensity of the grid at each location."
              : "CFE is calculated based on hourly data as (# MWh contracted with InRange)  + (#MWh consumed from grid * proportion of regional grid CFE)) / (# MWh total demand).\n\nEmissions avoided in year 1 across all sites where the CO2e multiplier per MWh is based on the annual carbon intensity of the grid at each location."
          }
        />
      </MetricTooltip>
      <AnnualCarbonSavingsHeader>
        Carbon savings in year 1
      </AnnualCarbonSavingsHeader>
      <AnnualCarbonSavingsChart>
        <MyRadialBar
          emissionsAvoided={emissionsAvoided}
          cfePeriodPercent={cfePeriodPercent}
        />
      </AnnualCarbonSavingsChart>
      <AnnualCarbonSavingsDetailsSection>
        <Metric
          title="Carbon free energy (CFE)"
          data={(cfePeriodPercent * 100).toFixed(1)}
          dataUnit="%"
          // arrow={upArrowSVG}
          // label={`${hoursPerYear.toFixed(0)} hours per year`}
        />
        <Metric
          title="Total emissions avoided in year 1"
          data={emissionsAvoided}
          dataUnit=""
          // arrow={upArrowSVG}
          // label="15%"
        />
      </AnnualCarbonSavingsDetailsSection>
    </AnnualCarbonSavingsContainer>
  );
};

const Metric = ({ title, data, dataUnit, arrow, label }) => {
  return (
    <AnnualCarbonSavingsDetail>
      <AnnualCarbonSavingsDetailHeader>{title}</AnnualCarbonSavingsDetailHeader>
      <AnnualCarbonSavingsDetailData>
        {data}
        {dataUnit}
      </AnnualCarbonSavingsDetailData>
      <AnnualCarbonSavingsDetailLabel>
        <AnnualCarbonSavingsDetailLabelArrow>
          <img src={arrow} />
        </AnnualCarbonSavingsDetailLabelArrow>
        <AnnualCarbonSavingsDetailLabelText>
          {label}
        </AnnualCarbonSavingsDetailLabelText>
      </AnnualCarbonSavingsDetailLabel>
    </AnnualCarbonSavingsDetail>
  );
};

const MyRadialBar = ({ emissionsAvoided, cfePeriodPercent }) => {
  const chartData = [
    {
      id: "annualCarbonSavings",
      data: [{ y: cfePeriodPercent * 100 }],
    },
  ];
  const Metric = ({ center }) => {
    return (
      <>
        <text
          x={center[0]}
          y={center[1] - 2}
          textAnchor="middle"
          dominantBaseline="auto"
          style={{
            fontSize: 22,
            fill: "#00022F",
            fontFamily: "Manrope",
            fontWeight: 600,
            lineHeight: 48,
          }}
        >
          {emissionsAvoided.replace("CO2e", "")}
        </text>
        <text
          x={center[0]}
          y={center[1] + 2}
          textAnchor="middle"
          dominantBaseline="hanging"
          style={{
            paddingTop: "8px",
            fontSize: 22,
            fill: "#00022F",
            fontFamily: "Manrope",
            fontWeight: 400,
            lineHeight: 48,
          }}
        >
          CO2e/yr
        </text>
      </>
    );
  };

  return (
    <ResponsiveRadialBar
      width={194}
      height={194}
      maxValue={100}
      startAngle={0}
      animate={true}
      endAngle={360}
      cornerRadius={100}
      innerRadius={0.8}
      colors={"#00022F"}
      isInteractive={true}
      data={chartData}
      layers={["tracks", "bars", Metric]}
      tooltip={() => {}}
    />
  );
};

const AnnualCarbonSavingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  width: 335px;
  height: 450px;
  background: #ffffff;
  border-radius: 8px;
  flex-grow: 0;
`;

const AnnualCarbonSavingsHeader = styled(SectionTitle)`
  width: 294px;
  height: 30px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #00022f;
  flex-grow: 1;
  margin-top: 0px;
`;

const AnnualCarbonSavingsChart = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 194px;
  height: 195px;
  flex-grow: 1;
`;

const AnnualCarbonSavingsDetailsSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  padding: 8px;
  gap: 8px;
  width: 100%;
  height: 118px;
  flex-grow: 1;
`;

const AnnualCarbonSavingsDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 145px;
  height: 106px;
  background: #fafafa;
  border-radius: 16px;
  flex-grow: 1;
`;

const AnnualCarbonSavingsDetailHeader = styled.div`
  width: 100%;
  height: 20px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #2779a7;
  flex-grow: 1;
  margin-bottom: 12px;
`;

const AnnualCarbonSavingsDetailData = styled.div`
  width: 100%;
  height: 22px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  flex-grow: 1;
`;

const AnnualCarbonSavingsDetailLabel = styled.div`
  width: 100%;
  height: 18px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  justify-content: flex-start;
  color: #039855;
  flex-grow: 1;
`;

const AnnualCarbonSavingsDetailLabelArrow = styled.div`
  margin-right: 10px;
`;

const AnnualCarbonSavingsDetailLabelText = styled.div`
  display: flex;
  flex-grow: 1;
`;

const MetricTooltip = styled.div`
  display: flex;
  align-self: flex-end;
`;

export default AnnualCarbonSavings;
