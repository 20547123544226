export const sortByDescription = (listedFiles, sortAscending) => {
  listedFiles = listedFiles.sort((a, b) => {
    return sortAscending
      ? a.description.localeCompare(b.description)
      : b.description.localeCompare(a.description);
  });
};

export const sortInvoiceStatementNumber = (listedFiles, sortAscending) => {
  listedFiles = listedFiles.sort((a, b) => {
    const aCompareValue = a.invoiceNumber ? a.invoiceNumber : a.statementNumber;
    const bCompareValue = b.invoiceNumber ? b.invoiceNumber : b.statementNumber;

    return sortAscending
      ? aCompareValue.localeCompare(bCompareValue)
      : bCompareValue.localeCompare(aCompareValue);
  });
};

export const sortByProperty = (
  listedFiles,
  sortAscending,
  propertyAccessor
) => {
  listedFiles.sort((a, b) => {
    const propertyA = propertyAccessor(a);
    const propertyB = propertyAccessor(b);
    return sortAscending ? propertyA - propertyB : propertyB - propertyA;
  });
};

export const sortInvoiceStatementAmount = (listedFiles, sortAscending) => {
  sortByProperty(listedFiles, sortAscending, (file) => {
    return file.invoiceAmount ? file.invoiceAmount : file.statementAmount;
  });
};

export const sortByDate = (listedFiles, sortAscending, sortColumn) => {
  sortByProperty(listedFiles, sortAscending, (file) => {
    return file[sortColumn];
  });
};

export function sortByColumn(listedFiles, sortColumn, sortAscending) {
  const sortFunctions = {
    description: sortByDescription,
    number: sortInvoiceStatementNumber,
    amount: sortInvoiceStatementAmount,
    date: sortByDate,
    invoiceDueDate: sortByDate,
    issueDate: sortByDate,
  };

  const sortFunction = sortFunctions[sortColumn];

  if (sortFunction) {
    if (sortFunction === sortByDate) {
      sortFunction(listedFiles, sortAscending, sortColumn);
    } else {
      sortFunction(listedFiles, sortAscending);
    }
  }
}

export const filterFilesByText = (files, textFilter) => {
  return files.filter((file) =>
    file.description.toLowerCase().includes(textFilter.toLowerCase())
  );
};

export const toggleSortAscending = (setSortAscending) => {
  setSortAscending((prevSortAscending) => !prevSortAscending);
};

export const defaultSortAscendingValues = {
  description: true,
  number: true,
  amount: false,
  date: false,
  invoiceDueDate: false,
  issuedDate: false,
};
