import styled from "styled-components";
import { large, medium } from "../styles/breakpoints";

export const Display1 = styled.h1`
  font-style: normal;
  letter-spacing: ${(props) => props.letterSpacing || "-0.02em"};
  font-size: 40px;
  line-height: 48px;
  font-weight: 800;
  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `}
  ${medium(`
      font-size: 52px;
      line-height: 56px;
  `)}
`;

export const Display2 = styled.h2`
  font-style: normal;
  letter-spacing: ${(props) => props.letterSpacing || "-0.02em"};
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `}
  ${medium(`
    font-size: 40px;
    line-height: 48px;
  `)}
    ${large(`
    font-size: 44px;
  `)}
`;

export const Display3 = styled.h3`
  font-style: normal;
  letter-spacing: ${(props) => props.letterSpacing || "-0.02em"};
  font-size: 24px;
  line-height: 1.4;
  font-weight: 700;
  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `}
  ${medium(`
    font-size: 40px;
    line-height: 48px;
  `)}
    ${large(`
    font-size: 44px;
    line-height: 1.4;
  `)}
`;

export const Featured1 = styled.h1`
  font-style: normal;
  letter-spacing: ${(props) => props.letterSpacing || "0"};
  font-size: 32px;
  line-height: 40px;
  font-weight: 400;
  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `}
`;

export const Featured2 = styled.h2`
  font-style: normal;
  letter-spacing: ${(props) => props.letterSpacing || "0"};
  font-size: 24px;
  line-height: 1.4;
  font-weight: 400;
  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `}
  ${medium(`
      font-size: 28px;
  `)}
    ${large(`
      font-size: 32px;
  `)}
`;

export const Featured3 = styled.h3`
  font-style: normal;
  letter-spacing: ${(props) => props.letterSpacing || "0"};
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `}
`;

export const Title1 = styled.h1`
  font-style: normal;
  letter-spacing: ${(props) => props.letterSpacing || "-0.02em"};
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `}
  ${medium(`
      font-size: 50px;
      line-height: 54px;
  `)}
    ${large(`
      font-size: 52px;
      line-height: 56px;
  `)}
`;

export const Title2 = styled.h2`
  font-style: normal;
  letter-spacing: ${(props) => props.letterSpacing || "0"};
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `}
  ${medium(`
    font-size: 28px;
    line-height: 42px;
  `)}
    ${large(`
    font-size: 36px;
    line-height: 48px;
  `)}
`;

export const Title3 = styled.h3`
  font-style: normal;
  letter-spacing: ${(props) => props.letterSpacing || "0"};
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `}
  ${large(`
      font-weight: 700;
  `)}
`;

export const SectionTitle = styled.h2`
  font-style: normal;
  letter-spacing: ${(props) => props.letterSpacing || "0"};
  font-size: 30px;
  line-height: 1.4;
  font-weight: 500;
  max-width: 1400px;
  word-wrap: break-word;
  margin-right: 10px !important;
  align-items: baseline;
  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `};
`;

export const SectionSubtitle = styled.p`
  font-style: normal;
  letter-spacing: ${(props) => props.letterSpacing || "0"};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.grey800};
  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `}
`;

export const BodyStrong1 = styled.p`
  letter-spacing: ${(props) => props.letterSpacing || "0.01em"};
  font-size: 16px;
  line-height: 1.4;
  font-style: normal;
  font-weight: 600;
  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `}
  ${medium(`
    font-size: 20px;
  `)}
  ${large(`
    font-size: 24px;
  `)}
`;

export const BodyStrong2 = styled.p`
  font-style: normal;
  letter-spacing: ${(props) => props.letterSpacing || "0"};
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `}
`;

export const BodyMedium1 = styled.p`
  letter-spacing: ${(props) => props.letterSpacing || "0.01em"};
  font-size: 16px;
  line-height: 1.4;
  font-style: normal;
  font-weight: 500;
  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `}
  ${medium(`
    font-size: 18px;
  `)}
  ${large(`
    font-size: 24px;
  `)}
`;

export const BodyMedium2 = styled.p`
  font-style: normal;
  letter-spacing: ${(props) => props.letterSpacing || "0"};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `}
`;

export const Body1 = styled.p`
  letter-spacing: ${(props) => props.letterSpacing || "0.01em"};
  font-size: 16px;
  line-height: 1.4;
  font-style: normal;
  font-weight: 400;
  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `}
  ${medium(`
    font-size: 20px;
  `)}
    ${large(`
    font-size: 24px;
  `)}
`;

export const Body2 = styled.p`
  font-size: 10px;
  line-height: 1.4;
  font-style: normal;
  font-weight: 400;
  letter-spacing: ${(props) => props.letterSpacing || "0.05em"};
  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `}
  ${medium(`
      font-size: 12px;
    `)}
    ${large(`
      font-size: 14px;
    `)}
`;
