import styled from "styled-components";

const LoadingSpinner = styled.div`
  border: solid #eee;
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  ${(props) => `
    width: ${props.$size || "20px"};
    height: ${props.$size || "20px"};
    margin: ${props.$margin || "10px auto"};
    border-size: ${props.$thickness || "2px"};
  `}

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default LoadingSpinner;
