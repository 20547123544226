import styled from "styled-components";
import networkSiteBlue from "../images/icons/networkSiteBlue.svg";
import networkSitePurple from "../images/icons/networkSitePurple.svg";
import networkSiteYellow from "../images/icons/networkSiteYellow.svg";

const MapLegend = ({ mySitesLength, isShowingSpecificOffer, offerType }) => {
  return (
    <Container>
      <Legend>
        <Title style={{ color: "#a2a6ff" }}>
          {isShowingSpecificOffer && offerType === "buy" ? "Buying site" : ""}
          {isShowingSpecificOffer && offerType === "sell" ? "Selling site" : ""}
          {!isShowingSpecificOffer
            ? `Your ${mySitesLength > 1 ? "Sites" : "Site"}`
            : ""}
        </Title>
        <Icon src={networkSitePurple} />
      </Legend>
      <Legend>
        <Title
          style={
            isShowingSpecificOffer ? { color: "#DBA507" } : { color: "#2779a7" }
          }
        >
          {isShowingSpecificOffer && offerType === "buy" ? "Selling site" : ""}
          {isShowingSpecificOffer && offerType === "sell" ? "Buying site" : ""}
          {!isShowingSpecificOffer ? "InRange Network" : ""}
        </Title>
        <Icon
          src={isShowingSpecificOffer ? networkSiteYellow : networkSiteBlue}
        />
      </Legend>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  right: 8px;
  bottom: 8px;
  margin-top: 8px;
  z-index: 450;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 12px;
  gap: 12px;
  width: 150px;
  height: 120px;
  background: #ffffff;
  box-shadow:
    0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
`;
const Legend = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
`;

const Title = styled.span`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
`;
const Icon = styled.img`
  width: 25px;
  height: 25px;
`;

export default MapLegend;
