import styled from "styled-components";
import NumericalHeaderWithSubheader from "../NumericalHeaderWithSubheader";
import SectionHeaderWithSubHeaderDark from "../SectionHeaderWithSubHeaderDark";
import { Column } from "../layout/Flex";
import Grid from "../layout/Grid";

export const SummaryBoxMetricsMulti = ({
  header,
  metricsArr,
  grid,
  height,
  summaryChildren,
}) => {
  const metricDisplays = metricsArr.map((metrics) => {
    return metrics.map((metric, index) => {
      return (
        <NumericalHeaderWithSubheader
          numericalData={metric.value}
          textData={metric.valueSubText}
          dataTestId={metric.dataTestId}
          key={index}
          textAlign="left"
          fontSize="18px"
        />
      );
    });
  });

  return (
    <SummaryBoxMetricsContainer $height={height}>
      {metricDisplays.map((metricDisplay, index) => {
        return (
          <div key={index}>
            {index === 0 ? (
              <Column $margin={"21px 15px 0 15px"} key={index}>
                <SectionHeaderWithSubHeaderDark
                  mainHeader={header}
                  headerFontSize={"20px"}
                />
              </Column>
            ) : (
              <hr style={{ width: "85%" }} />
            )}
            {grid && metricDisplay.length > 1 ? (
              <Grid
                $cols={2}
                $colwidth={"1fr"}
                $margin={"15px"}
                $padding={"0px 8px"}
                $gap={"0px"}
              >
                {metricDisplay}
              </Grid>
            ) : (
              <Column $margin={"15px"} $gap={"15px"}>
                {metricDisplay}
              </Column>
            )}
            {summaryChildren[index]}
          </div>
        );
      })}
    </SummaryBoxMetricsContainer>
  );
};

export const SummaryBoxMetrics = ({
  header,
  metrics,
  grid,
  height,
  children,
}) => {
  const metricDisplays = metrics.map((metric, index) => {
    return (
      <NumericalHeaderWithSubheader
        numericalData={metric.value}
        textData={metric.valueSubText}
        dataTestId={metric.dataTestId}
        key={index}
        textAlign="left"
        fontSize="18px"
      />
    );
  });
  return (
    <SummaryBoxMetricsContainer $height={height}>
      <Column $margin={"21px 15px 0 15px"}>
        <SectionHeaderWithSubHeaderDark
          mainHeader={header}
          headerFontSize={"20px"}
        />
      </Column>
      {grid && metricDisplays.length > 1 ? (
        <Grid
          $cols={2}
          $colwidth={"1fr"}
          $margin={"15px"}
          $padding={"0px 8px"}
          $gap={"15px"}
        >
          {metricDisplays}
        </Grid>
      ) : (
        <Column $margin={"15px"} $gap={"15px"}>
          {metricDisplays}
        </Column>
      )}
      {children}
    </SummaryBoxMetricsContainer>
  );
};

const SummaryBoxMetricsContainer = styled(Column)`
  padding: 8px;
  width: 370px;
  background: #00022f;
  color: #ffffff;
  border-radius: 8px;
  flex-grow: 1;
  gap: 0;
`;
