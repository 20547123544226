import styled from "styled-components";

const SectionHeaderWithSubHeaderDark = ({
  mainHeader,
  subHeader,
  paragraph,
  headerFontSize,
}) => {
  return (
    <SectionContainer>
      <MainHeader $headerFontSize={headerFontSize}>{mainHeader}</MainHeader>
      <SubHeader>{subHeader}</SubHeader>
      <SubHeader>{paragraph}</SubHeader>
    </SectionContainer>
  );
};

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;
const MainHeader = styled.span`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 600;
  font-size: ${({ $headerFontSize }) => $headerFontSize || "20px"};
  line-height: 20px;
  letter-spacing: 0.003em;
  color: white;
`;
const SubHeader = styled.span`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.003em;
  color: white;
`;

export default SectionHeaderWithSubHeaderDark;
