import axiosConfig from "./axiosConfig";

const getSiteFileDownloadUrl = async (
  authToken,
  siteId,
  siteFileType,
  fileName,
  fileGuid,
  isAttachment,
  userOrgId
) => {
  const { data } = await axiosConfig.post(
    `/site/${siteId}/file/${fileGuid}${
      userOrgId ? `?userOrgId=${userOrgId}` : ""
    }`,
    {
      fileName,
      siteFileType,
      isAttachment,
    },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return data;
};

export default getSiteFileDownloadUrl;
