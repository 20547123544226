import "leaflet/dist/leaflet.css";
import styled from "styled-components";
import "./map.css";

import { getMapboxUrl } from "@inrange/building-manager-api-client";
import { useState } from "react";
import { GeoJSON, MapContainer, TileLayer } from "react-leaflet";

import Tooltip from "../Tooltip";

const Map = ({
  width,
  height,
  zoom,
  lat,
  lon,
  styledGeoJsons,
  setMap,
  mapStyle,
  tooltipText,
}) => {
  const [tileLoadingLabel, setTileLoadingLabel] = useState("tile-loading");
  const maxZoom = 18;

  let tileLayer = (
    <TileLayer
      attribution='&copy; <a href="https://www.mapbox.com/">MapBox</a>'
      url={getMapboxUrl("clemysf5c005s01o9u6mw58qc", "tom-inrange")}
      maxZoom={maxZoom}
      eventHandlers={{
        loading: () => setTileLoadingLabel("tile-loading"),
        load: () => setTileLoadingLabel("tile-loaded"),
      }}
    />
  );
  if (mapStyle === "satellite") {
    tileLayer = (
      <TileLayer
        attribution='&copy; <a href="https://www.mapbox.com/">MapBox</a>'
        url={getMapboxUrl("satellite-v9")}
        maxZoom={maxZoom}
        eventHandlers={{
          loading: () => setTileLoadingLabel("tile-loading"),
          load: () => setTileLoadingLabel("tile-loaded"),
        }}
      />
    );
  }
  if (mapStyle === "google_sat")
    tileLayer = (
      <TileLayer
        attribution='&copy; <a href="https://www.google.com/">Google</a>'
        url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
        subdomains={["mt0", "mt1", "mt2", "mt3"]}
        eventHandlers={{
          loading: () => setTileLoadingLabel("tile-loading"),
          load: () => setTileLoadingLabel("tile-loaded"),
        }}
      />
    );
  return (
    <MapWrapper width={width} height={height} data-testid={tileLoadingLabel}>
      {tooltipText && (
        <PvTooltip>
          <Tooltip text={tooltipText} position={"bottom"} />
        </PvTooltip>
      )}
      <MapContainer
        center={[lat, lon]}
        zoom={zoom}
        scrollWheelZoom={false}
        ref={setMap}
        style={{
          maxWidth: "92%",
          height: "80%",
          border: "16px solid white",
        }}
      >
        {tileLayer}

        {styledGeoJsons.map((styledGeoJson) => {
          return (
            <GeoJSON
              key={styledGeoJson.key}
              data={styledGeoJson.geoJson}
              color={styledGeoJson.color || "#DBA507"}
              weight={styledGeoJson.weight || 2}
              fillOpacity={0}
            />
          );
        })}
      </MapContainer>
    </MapWrapper>
  );
};

export default Map;

const MapWrapper = styled.div`
  ${({ width, height }) => `
    width: ${width};
    height: ${height};
  `}
`;

const PvTooltip = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 1010px;
`;
