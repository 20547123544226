import styled from "styled-components";

const Loaded = ({ children }) => {
  return <LoadedContainer>{children}</LoadedContainer>;
};

const LoadedContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #2779a7;
`;

export default Loaded;
