import { useState } from "react";
import styled from "styled-components";
import SimpleButton from "../buttons/SimpleButton";

const AddEmailUser = ({ onClick, addButtonDisabled, currentUserEmails }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [inputValue, setInputValue] = useState("");
  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  function handleInput(event) {
    const emailInput = event.target;
    emailInput.classList.remove("error");
    setErrorMessage("");
  }

  function handleBlur(event) {
    const emailInput = event.target;
    if (!emailInput.checkValidity()) {
      setErrorMessage(emailInput.validationMessage);
      emailInput.classList.add("error");
    } else {
      if (currentUserEmails.includes(emailInput.value)) {
        setErrorMessage("User already exists");
        emailInput.classList.add("error");
      } else {
        setErrorMessage("");
        emailInput.classList.remove("error");
      }
    }
  }

  return (
    <Container>
      <InputContainer>
        <Input
          value={inputValue}
          onChange={handleInputChange}
          onInput={handleInput}
          placeholder="Email"
          type="email"
          pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$"
          onBlur={handleBlur}
          id="email-input"
          required
          data-testid="user-email-input"
        />
        <SimpleButton
          height="40px"
          label="Add user"
          onClick={() => {
            if (!inputValue) {
              setErrorMessage("Please fill in this field.");
              return;
            }
            onClick(inputValue);
            setInputValue("");
          }}
          disabled={errorMessage || addButtonDisabled}
          data-testid="add-user-button"
        />
      </InputContainer>
      {errorMessage && (
        <Error>{errorMessage} Please provide a valid email address.</Error>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Input = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 56px 4px 8px;
  gap: 8px;
  margin-right: 10px;
  width: 446px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #bbc1d3;
  border-radius: 4px;
  flex-grow: 1;

  &:focus {
    outline: 1px solid #00022f;
    border: none;
  }

  ::placeholder {
    color: rgba(0, 0, 0, 0.2);
  }

  &.error {
    border-color: #f04438;
    border: 1px solid #f04438;
  }
`;

export const Error = styled.p`
  font-size: 14px;
  padding-left: 5px;
  line-height: 20px;
  font-style: normal;
  color: #f04438;
  padding-top: 3px;
  width: ${(props) => (props.width ? props.width : "unset")};
`;

export default AddEmailUser;
