import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useQuery } from "react-query";
import getRelatedLandlordSitesList from "../http/getRelatedLandlordSitesList";

const useRelatedLandlordSitesList = (orgId, siteId, userOrgId) => {
  const { getToken, getClaim } = useKindeAuth();
  const kindeTokenOrgId = getClaim("external_org_id")?.value;
  userOrgId = kindeTokenOrgId === "inrange.io" ? userOrgId : undefined;

  const fetchRelatedLandlordSitesList = useQuery(
    ["relatedLandlordSitesList", orgId, siteId],
    async () => {
      return getRelatedLandlordSitesList(
        await getToken(),
        orgId,
        siteId,
        userOrgId
      );
    },
    { enabled: !!orgId && !!siteId }
  );

  return {
    fetchRelatedLandlordSitesList,
  };
};

export default useRelatedLandlordSitesList;
