import { getApiHostname } from "@inrange/building-manager-api-client/config";

export const mapboxKey =
  "pk.eyJ1IjoidG9tLWlucmFuZ2UiLCJhIjoiY2t5aWhlejVqMXlvYjJvdXJhb3Q2bGdjdCJ9.Uy8CpeGLbj_qeKsDYR0BaA";

// to get theme string for a custom theme of a specific account, navigate to
// https://studio.mapbox.com,log in, select the theme and copy the theme
// string from the URL
// e.g. https://studio.mapbox.com/styles/tom-inrange/clemysf5c005s01o9u6mw58qc/edit/#16.58/40.703215/-74.008834
// would be clemysf5c005s01o9u6mw58qc
const getMapboxUrl = (theme, account = "mapbox") => {
  const isProduction = import.meta.env?.VITE_KINDE_AUTH_ENV === "PROD";
  const hostname = getApiHostname();
  return isProduction
    ? `https://api.mapbox.com/styles/v1/${account}/${theme}/tiles/{z}/{x}/{y}?access_token=${mapboxKey}`
    : `${hostname}/mapbox-tile/${account}/${theme}/{z}/{x}/{y}/${mapboxKey}`;
};

export default getMapboxUrl;
