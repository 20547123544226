function roundUpPayback(payback) {
  if (isNaN(payback)) return payback;
  if (payback > Math.floor(payback)) {
    return Math.ceil(payback);
  } else {
    return payback;
  }
}

export default roundUpPayback;
