import axiosConfig from "./axiosConfig";

const postSite = async (authToken, site, userOrgId) => {
  const { data } = await axiosConfig.post(
    `/site${userOrgId ? `?userOrgId=${userOrgId}` : ""}`,
    site,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return data;
};

export default postSite;
