import formatCurrency from "../../formatting/formatCurrency";
import { formatUnits } from "../../formatting/formatKiloUnits";
import formatPercentage from "../../formatting/formatPercentage";
import SiteLink from "./SiteLink";
import SiteNameAddressData from "./SiteNameAddressData";
import TableCellData from "./TableCellData";

const OperationalSiteRow = ({
  site,
  organisation,
  mode,
  lastMonthCost,
  lastMonthProcurement,
  lastMonthRevenue,
  lastMonthDemandFulfilled,
  lastMonthSavings,
  lastMonthGeneration,
}) => {
  return (
    <tr key={site.id} id={site.id}>
      <td>
        <TableCellData>
          <SiteNameAddressData
            siteName={site.name}
            siteAddress={site.address}
            siteId={site.id}
            orgId={organisation.id}
          />
        </TableCellData>
      </td>
      <td>
        <TableCellData>{formatCurrency(lastMonthCost, "GBP", 2)}</TableCellData>
      </td>
      <td>
        <TableCellData>
          {formatUnits(lastMonthProcurement, "kWh", 2)}
        </TableCellData>
      </td>
      {mode === "leased" ? (
        <td>
          <TableCellData>
            {formatPercentage(lastMonthDemandFulfilled, 0)}
          </TableCellData>
        </td>
      ) : (
        <td>
          <TableCellData>
            {formatCurrency(lastMonthRevenue, "GBP", 2)}
          </TableCellData>
        </td>
      )}
      <td>
        <TableCellData>
          {formatCurrency(lastMonthSavings, "GBP", 2)}
        </TableCellData>
      </td>
      <td>
        <TableCellData>
          {formatUnits(lastMonthGeneration, "kWh", 2)}
        </TableCellData>
      </td>
      <td style={{ width: "170px" }}>
        <SiteLink siteId={site.id} orgId={organisation.id} />
      </td>
    </tr>
  );
};

export default OperationalSiteRow;
