import { TableHeaderCell } from "./TableHeaderCell";
import {
  DescriptionHeader,
  InvoiceStatementNumberHeader,
  InvoiceStatementAmountHeader,
  IssueDateHeader,
  InvoiceDueDateHeader,
  DateHeader,
  ColumnContainer,
  ColumnTextStyle,
} from "./FileListHeaderComponents";

const FilesListHeader = ({
  isBilling,
  handleColumnClick,
  sortColumn,
  sortAscending,
}) => {
  const headerData = [
    {
      columnName: "description",
      sortValue: "description",
      headerComponent: DescriptionHeader,
    },
  ];

  if (isBilling) {
    headerData.push(
      {
        columnName: "number",
        sortValue: "number",
        headerComponent: InvoiceStatementNumberHeader,
      },
      {
        columnName: "amount",
        sortValue: "amount",
        headerComponent: InvoiceStatementAmountHeader,
      },
      {
        columnName: "issueDate",
        sortValue: "issueDate",
        headerComponent: IssueDateHeader,
      },
      {
        columnName: "invoiceDueDate",
        sortValue: "invoiceDueDate",
        headerComponent: InvoiceDueDateHeader,
      }
    );
  } else {
    headerData.push({
      columnName: "date",
      sortValue: "date",
      headerComponent: DateHeader,
    });
  }

  return (
    <tr>
      {headerData.map((header) => (
        <TableHeaderCell
          key={header.columnName}
          handleColumnClick={handleColumnClick}
          columnName={header.columnName}
          sortColumn={sortColumn}
          sortAscending={sortAscending}
          sortValue={header.sortValue}
          HeaderComponent={header.headerComponent}
        />
      ))}
      <th></th>
    </tr>
  );
};

export default FilesListHeader;

export const Header = ({ label }) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>{label}</ColumnTextStyle>
    </ColumnContainer>
  );
};
