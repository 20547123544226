import {
  NoPaddedRow,
  TableListSortFilters,
  TableListToggleButtons,
  isOrganizationInSiteScores,
} from "@inrange/theme-components";
import { LandlordSiteListMetricCards } from "@inrange/theme-components/site";
import {
  LandlordSiteListHeader,
  LandlordSiteRow,
  Table,
} from "@inrange/theme-components/table";
import { useEffect } from "react";
import calcIsOwnerOccupied from "../utils/calcIsOwnerOccupied";
import {
  defaultSortAscendingValues,
  filterSitesByText,
  sortByColumn,
  togglesortAscending,
} from "./SiteListSortMethods";

import { useState } from "react";
import {
  calcEmissionsAvoided,
  calcTotalAnnualGeneration,
  calcTotalEnergyProcured,
  calcTotalProjectCost,
  calcTotalRevenue,
  calcTotalSavings,
} from "../../src/routes/dashboard/aggregationCalculations";

const selectDefaultInvestmentModel = (
  isOwnerOccupied,
  leaseSitesCount,
  licenseSitesCount
) => {
  if (isOwnerOccupied) {
    // Prefer lease
    if (leaseSitesCount > 0 || licenseSitesCount === 0) {
      return "lease";
    } else {
      return "license";
    }
  } else {
    // Prefer license
    if (licenseSitesCount > 0 || leaseSitesCount === 0) {
      return "license";
    } else {
      return "lease";
    }
  }
};

const LandlordSiteListView = ({
  sites,
  organisation,
  isOwnerOccupied,
  isLeaseMetrics,
}) => {
  const orgID = organisation.id;
  const displayInRangeScoreCol = isOrganizationInSiteScores(orgID, sites);
  const allSitesNotStarted = sites.every(
    (site) => site.operationalStatus === "notStarted"
  );
  const [sortAscending, setSortAscending] = useState(false);
  const leaseSites = sites.filter(
    (site) => site.activeInvestmentModel === "lease"
  );
  const licenseSites = sites.filter(
    (site) => site.activeInvestmentModel === "license"
  );
  const defaultInvestmentModel = selectDefaultInvestmentModel(
    isOwnerOccupied,
    leaseSites.length,
    licenseSites.length
  );
  const sortColumnSuffix =
    defaultInvestmentModel === "license" ? "License" : "Lease";
  const [sortColumn, setSortColumn] = useState(
    displayInRangeScoreCol || !allSitesNotStarted
      ? `siteStatus${sortColumnSuffix}`
      : `networkAvailability${sortColumnSuffix}`
  );
  sortByColumn(sites, sortColumn, sortAscending, organisation); // Initial sort

  const [textFilter, setTextFilter] = useState("");
  const [filteredSites, setFilteredSites] = useState(
    sites.filter(
      (site) => site.activeInvestmentModel === defaultInvestmentModel
    )
  );
  const [investmentModel, setInvestmentModel] = useState(
    defaultInvestmentModel
  );

  // Required metrics
  const siteCount = sites.length;
  const totalAnnualGeneration = calcTotalAnnualGeneration(sites);
  const totalEnergyProcured = calcTotalEnergyProcured(sites);
  const emissionsAvoided = calcEmissionsAvoided(sites);
  const totalProjectCost = calcTotalProjectCost(sites);
  const totalRevenue = calcTotalRevenue(
    organisation,
    sites,
    defaultInvestmentModel
  );
  const totalSavings = calcTotalSavings(sites);

  const metrics = {
    totalSites: siteCount,
    totalRevenue: totalRevenue,
    totalOnSiteGeneration: totalAnnualGeneration,
    totalEmissionsAvoided: emissionsAvoided,
    totalEnergyProcured: totalEnergyProcured,
    totalSavings: totalSavings,
    totalProjectCost: totalProjectCost,
  };

  const changeInvestmentModel = (model) => {
    if (displayInRangeScoreCol || !allSitesNotStarted) {
      if (model === "license") {
        setSortColumn("siteStatusLicense");
      } else if (model === "lease") {
        setSortColumn("siteStatusLease");
      }
    } else {
      if (model === "license") {
        setSortColumn("networkAvailabilityLicense");
      } else if (model === "lease") {
        setSortColumn("networkAvailabilityLease");
      }
    }
    setInvestmentModel(model);
  };

  const modelSelectionTabs = {
    tab1: {
      label: defaultInvestmentModel === "license" ? "License" : "Lease",
      active: investmentModel === defaultInvestmentModel,
      onClick: () => {
        changeInvestmentModel(defaultInvestmentModel);
      },
      count: (defaultInvestmentModel === "license" ? licenseSites : leaseSites)
        .length,
    },
    tab2: {
      label: null,
      active: false,
      onClick: () => {},
    },

    tab3: {
      label: defaultInvestmentModel === "license" ? "Lease" : "License",
      active: investmentModel !== defaultInvestmentModel,
      onClick: () => {
        changeInvestmentModel(
          defaultInvestmentModel === "license" ? "lease" : "license"
        );
      },
      count: (defaultInvestmentModel === "license" ? leaseSites : licenseSites)
        .length,
    },
  };

  // Sort and filter
  useEffect(() => {
    let listedSites = sites.filter(
      (site) => site.activeInvestmentModel === investmentModel
    );
    // Text search based on name, address or tenant name
    if (textFilter.length > 2) {
      listedSites = filterSitesByText(sites, textFilter);
    }
    sortByColumn(listedSites, sortColumn, sortAscending, organisation);
    setFilteredSites(listedSites);
  }, [
    textFilter,
    sites,
    organisation,
    sortColumn,
    sortAscending,
    investmentModel,
  ]);

  const handleColumnClick = (columnKey) => {
    if (sortColumn === columnKey) {
      togglesortAscending(setSortAscending);
      return;
    }
    setSortColumn(columnKey);
    setSortAscending(defaultSortAscendingValues[columnKey]);
  };

  const tableHead = (
    <LandlordSiteListHeader
      mode={investmentModel}
      sortColumn={sortColumn}
      sortAscending={sortAscending}
      handleColumnClick={handleColumnClick}
      displayInRangeScoreCol={displayInRangeScoreCol}
      allSitesNotStarted={allSitesNotStarted}
      showTenantColumn={!isOwnerOccupied}
    />
  );

  const tableRows = filteredSites.length ? (
    filteredSites.map((site) => {
      const isOwnerOccupier = calcIsOwnerOccupied(site, organisation);

      const revenueModel = isOwnerOccupier
        ? site.financialModels.ownerOccupier
        : site.financialModels.landlord;

      const irr = revenueModel.license.irr;
      const payback = revenueModel.license.paybackMonths;

      return (
        <LandlordSiteRow
          site={site}
          organisation={organisation}
          mode={investmentModel}
          leaseRevenue={revenueModel.lease.revenue}
          licenseRevenue={revenueModel.license.revenue}
          irr={irr}
          payback={payback ?? "N/A"}
          key={site.id}
          displayInRangeScoreCol={displayInRangeScoreCol}
          allSitesNotStarted={allSitesNotStarted}
          showTenantColumn={!isOwnerOccupied}
        />
      );
    })
  ) : (
    <LandlordSiteRow mode={investmentModel} emptyRow={true} />
  );

  return (
    <>
      <LandlordSiteListMetricCards
        metrics={metrics}
        isLeaseMetrics={isLeaseMetrics}
      />
      <NoPaddedRow>
        <TableListToggleButtons tabs={modelSelectionTabs} />
        <TableListSortFilters
          textFilter={textFilter}
          setTextFilter={setTextFilter}
        />
      </NoPaddedRow>
      <NoPaddedRow>
        <Table
          head={tableHead}
          rows={tableRows}
          id={"landlord-site-list-table"}
        />
      </NoPaddedRow>
    </>
  );
};

export default LandlordSiteListView;
