import axiosConfig from "./axiosConfig";

const putSite = async (authToken, siteId, userOrgId, update) => {
  const { data } = await axiosConfig.put(
    `/site/${siteId}${userOrgId ? `?userOrgId=${userOrgId}` : ""}`,
    update,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return data;
};

export default putSite;
