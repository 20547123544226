/*
  roundCurrency(1234) => 1230
  roundCurrency(123456) => 123000
  roundCurrency(1234567) => 1235000
*/
const roundCurrency = (value) => {
  if (value > 100_000) {
    return Math.round(value / 1000) * 1000;
  } else {
    return Math.round(value / 10) * 10;
  }
};
export default roundCurrency;
