import { ResponsiveRadialBar } from "@nivo/radial-bar";
import styled from "styled-components";

const AnnualCarbonSavings = ({ emissionsAvoided, cfePeriodPercent }) => {
  return (
    <RadialChart>
      <RadialBar
        emissionsAvoided={emissionsAvoided}
        cfePeriodPercent={cfePeriodPercent}
      />
    </RadialChart>
  );
};

const RadialBar = ({ emissionsAvoided, cfePeriodPercent }) => {
  const chartData = [
    {
      id: "annualCarbonSavings",
      data: [{ y: cfePeriodPercent * 100 }],
    },
  ];
  const Metric = ({ center }) => {
    return (
      <>
        <text
          x={center[0]}
          y={center[1] - 2}
          textAnchor="middle"
          dominantBaseline="auto"
          style={{
            fontSize: 22,
            fill: "#00022F",
            fontFamily: "Manrope",
            fontWeight: 600,
            lineHeight: 48,
          }}
        >
          {emissionsAvoided.replace("CO2e", "")}
        </text>
        <text
          x={center[0]}
          y={center[1] + 2}
          textAnchor="middle"
          dominantBaseline="hanging"
          style={{
            paddingTop: "8px",
            fontSize: 22,
            fill: "#00022F",
            fontFamily: "Manrope",
            fontWeight: 400,
            lineHeight: 48,
          }}
        >
          CO2e/yr
        </text>
      </>
    );
  };

  return (
    <ResponsiveRadialBar
      width={194}
      height={194}
      maxValue={100}
      startAngle={0}
      animate={true}
      endAngle={360}
      cornerRadius={100}
      innerRadius={0.8}
      colors={"#00022F"}
      isInteractive={true}
      data={chartData}
      layers={["tracks", "bars", Metric]}
      tooltip={() => {}}
    />
  );
};

const RadialChart = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 194px;
  height: 195px;
  flex-grow: 1;
`;

export default AnnualCarbonSavings;
