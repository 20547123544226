import { trackUserInteraction } from "@inrange/building-manager-api-client/events";
import { ErrorSidebar, Loaded } from "@inrange/theme-components";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import * as Sentry from "@sentry/react";
import { useContext } from "react";
import { useRouteError } from "react-router-dom";
import { UserContext } from "../auth/UserContext";

function ErrorElement() {
  const { logout } = useKindeAuth();
  const error = useRouteError();
  const { user } = useContext(UserContext);
  const userEmail = user?.email;
  const url = window.location.href;
  Sentry.captureException(error);
  trackUserInteraction({ url }, "ERROR", "ERROR", userEmail, "customer-app");

  return (
    <ErrorSidebar onClickLogout={logout}>
      <Loaded>
        <p>
          {
            "Looks like there was an issue loading this page. We've alerted the team, so rest assured we're on it."
          }
        </p>
        <p>
          Feel free to reach out for immediate support -{" "}
          <a
            href="mailto:support@inrange.io?subject=InRange error"
            style={{ color: "#2779a7" }}
          >
            support@inrange.io
          </a>
        </p>
      </Loaded>
    </ErrorSidebar>
  );
}

export default ErrorElement;
