import { Column, NoPaddedRow, PageCardHeader } from "@inrange/theme-components";
import {
  LeaseComparisonItem,
  LicenseComparisonItem,
} from "@inrange/theme-components/leaseLicenseComparison";

const LeaseLicenseChoiceSummary = ({
  site,
  updateInvestmentModel,
  irr,
  ner,
  paybackMonths,
  isOwnerOccupier,
}) => {
  return (
    <>
      <Column>
        <PageCardHeader
          title="Select an investment model"
          label="This is not a commitment, and you can switch between models as you
        see fit."
        />
      </Column>
      <NoPaddedRow style={{ marginRight: 34 }}>
        <LicenseComparisonItem
          irr={irr}
          paybackMonths={paybackMonths}
          annualRevenue={
            isOwnerOccupier
              ? site.financialModels.ownerOccupier.license.revenue
              : site.financialModels.landlord.license.revenue
          }
          hardwareCosts={site.projectCosts.hardware}
          installationCosts={site.projectCosts.installation}
          gridConnectionCost={site.costInputs.gridConnectionCost}
          initialInvestment={site.projectCosts.initialInvestment}
          setInvestmentModel={() => {
            updateInvestmentModel("license");
          }}
        />
        <LeaseComparisonItem
          annualRevenue={
            isOwnerOccupier
              ? site.financialModels.ownerOccupier.lease.revenue
              : site.financialModels.landlord.lease.revenue
          }
          gridConnectionCost={site.costInputs.gridConnectionCost}
          ner={ner}
          setInvestmentModel={() => {
            updateInvestmentModel("lease");
          }}
        />
      </NoPaddedRow>
    </>
  );
};

export default LeaseLicenseChoiceSummary;
