import hash from "object-hash";
import { useContext, useEffect, useState } from "react";
import { SiteCalculationsContext } from "./SiteCalculationsContext";
import { buildSitePayload } from "./buildSitePayload";

const SitePreview = ({ children, previewSite, originalSite }) => {
  const { updateCalculations } = useContext(SiteCalculationsContext);
  const originalSiteHash = hash(buildSitePayload(originalSite));

  const [previousSiteHash, setPreviousSiteHash] = useState(originalSiteHash);

  useEffect(() => {
    if (Object.keys(previewSite).length === 0) return;
    const previewSitePayload = buildSitePayload(previewSite);
    const updatedSiteHash = hash(previewSitePayload);
    if (previousSiteHash === updatedSiteHash) return;

    // Debounce so we don't recalculate immediately on keypress
    const debounceWaitMillis = 300;
    const timeoutId = setTimeout(() => {
      setPreviousSiteHash(updatedSiteHash);
      updateCalculations(previewSitePayload);
    }, debounceWaitMillis);

    return () => clearTimeout(timeoutId);
  }, [previewSite, originalSiteHash, previousSiteHash, updateCalculations]);

  return children;
};

export default SitePreview;
