import toNearestTen from "./toNearestTen";

/*
  sqmToSqFt(100) => "1,076ft²"
  sqmToSqFt(1234) => "13,283ft²"
*/
export const sqmToSqFt = (meters) => {
  const ft = sqmToSqFtRaw(meters);
  return new Intl.NumberFormat("en-GB", {}).format(ft.toFixed(0)) + "ft²";
};

/*
  sqmToSqFtNearestTen(100) => "1,080ft²"  
  sqmToSqFtNearestTen(1234) => "13,280ft²"
*/
export const sqmToSqFtNearestTen = (meters) => {
  const ft = sqmToSqFtRaw(meters);
  const ftToNearestTen = toNearestTen(ft);
  return new Intl.NumberFormat("en-GB", {}).format(ftToNearestTen) + "ft²";
};

export const sqmToSqFtRaw = (meters) => {
  return meters * 10.7639104167;
};
