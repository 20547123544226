import styled from "styled-components";
import Tooltip from "./Tooltip";
import { SectionTitle } from "./Typography";

const TotalProjectCost = ({
  totalHardwareCost,
  totalInstallationCost,
  totalProjectCostPerKgCo2,
  totalMaintenanceCost,
}) => {
  return (
    <TotalProjectCostContainer>
      <MetricTooltip>
        <Tooltip
          text={
            "This is a breakdown of the total project costs of your portfolio. Assumes license model for all sites where a the lease selection has not been made. \n\nTotal lifetime maintenance cost is calculated as a percentage of the total hardware cost.\n\nCost of every CO2e tonne avoided is calculated by taking 1/20th of the total project cost, and dividing by the number of CO2e tonnes avoided in year 1."
          }
          position="bottom"
        />
      </MetricTooltip>
      <TotalProjectCostHeader>
        <TotalProjectCostTitle>Total project cost</TotalProjectCostTitle>
        <TotalProjectCostNote>
          Assuming you choose to invest (license) in panels instead of InRange
          investing (lease).
        </TotalProjectCostNote>
      </TotalProjectCostHeader>
      <TotalProjectCostDetail
        title="Total hardware cost"
        value={totalHardwareCost}
      />
      <TotalProjectCostDetail
        title="Total lifetime maintenance cost"
        value={totalMaintenanceCost}
      />
      <TotalProjectCostDetail
        title="Total installation cost"
        value={totalInstallationCost}
      />
      <TotalProjectCostDetail
        title="Cost of every CO2e tonne avoided"
        value={totalProjectCostPerKgCo2}
      />
    </TotalProjectCostContainer>
  );
};

const TotalProjectCostDetail = ({ title, value }) => {
  return (
    <TotalProjectCostDetailContainer>
      <TotalProjectCostMain>
        <TotalProjectDetailTitle>{title}</TotalProjectDetailTitle>
        <TotalProjectDetailValue>{value}</TotalProjectDetailValue>
      </TotalProjectCostMain>
    </TotalProjectCostDetailContainer>
  );
};

const MetricTooltip = styled.div`
  display: flex;
  align-self: flex-end;
`;

const TotalProjectCostContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 16px 16px 48px 16px;
  width: 440px;
  background: #f5f5f5;
  border-radius: 8px;
  flex-grow: 0;
`;

const TotalProjectCostMain = styled.div`
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 110px;
  height: 58px;
  flex-grow: 1;
`;

const TotalProjectCostDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 84px;
  flex-grow: 1;
  border-bottom: 0.5px solid #dfdfdf;
  padding: 19px 0px;

  :last-child {
    border: none;
  }
`;

const TotalProjectCostHeader = styled.div`
  width: 100%;
  flex-grow: 1;
  margin: 0px;
`;

const TotalProjectCostTitle = styled(SectionTitle)`
  width: 180px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #00022f;
  flex-grow: 1;
  margin-top: 0px;
  margin-bottom: 0px;
`;

const TotalProjectDetailTitle = styled.div`
  width: 255px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #000000;
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
`;

const TotalProjectCostNote = styled(TotalProjectDetailTitle)`
  font-size: 12px;
  color: #9f9f9f;
  width: 350px;
`;

const TotalProjectDetailValue = styled.div`
  width: 109px;
  height: 22px;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #00022f;
  margin: 0;
  padding: 0;
`;

export default TotalProjectCost;
