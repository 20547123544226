import styled from "styled-components";
import CircleTick from "../../images/icons/circleTick.svg";

export const Header = ({ modelName }) => {
  return <HeaderText>Investment via {modelName} model</HeaderText>;
};

const HeaderText = styled.h3`
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primaryLIght};
`;

export const SubHeader = ({ text }) => {
  return <SubHeaderText>{text}</SubHeaderText>;
};

const SubHeaderText = styled.p`
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.blueSolid};
`;

export const ComparisonList = ({ listItemTexts }) => {
  return (
    <ComparisonListUl>
      {listItemTexts.map((itemText, i) => (
        <ComparisonListItem key={i}>
          <img src={CircleTick} alt="" />
          {itemText}
        </ComparisonListItem>
      ))}
    </ComparisonListUl>
  );
};

const ComparisonListUl = styled.ul`
  padding: 0;
  margin: 0;
`;
const ComparisonListItem = styled.li`
  display: flex;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  padding: 0 0 8px;
  margin: 0;
  img {
    margin-right: 8px;
  }
`;
