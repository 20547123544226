import { getMapboxUrl } from "@inrange/building-manager-api-client";
import L, { latLngBounds } from "leaflet";
import "leaflet/dist/leaflet.css";
import { useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import {
  MapContainer,
  Marker,
  Polyline,
  Popup,
  TileLayer,
  useMap,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { formatKiloUnitsWithValue } from "../../formatting/formatKiloUnits";
import MapLegend from "../MapLegend";
import "./map.css";
import PulseMarker from "./markers/PulseMarker";

const mapUrl = getMapboxUrl("clemysf5c005s01o9u6mw58qc", "tom-inrange");

export const MySiteMarker = L.divIcon({
  html: ReactDOMServer.renderToString(
    <PulseMarker count={0} type={"mysite"} testId={"map-icon-my-site"} />
  ),
  className: "custom-marker-mysite",
  iconSize: L.point(24, 24, true),
});

export const NetworkMarker = L.divIcon({
  html: ReactDOMServer.renderToString(
    <PulseMarker count={0} type={"network"} testId={"map-icon-network-site"} />
  ),
  className: "custom-marker-network",
  iconSize: L.point(24, 24, true),
});

export const OfferDestinationMarker = L.divIcon({
  html: ReactDOMServer.renderToString(
    <PulseMarker
      count={0}
      type={"offerDestination"}
      testId={"map-icon-offerdestination-site"}
    />
  ),
  className: "custom-marker-offerdestination",
  iconSize: L.point(24, 24, true),
});

const createClusterMySite = function (cluster) {
  return L.divIcon({
    html: ReactDOMServer.renderToString(
      <PulseMarker
        count={cluster.getChildCount()}
        type={"mysite"}
        testId={"map-icon-my-site"}
      />
    ),
    className: "custom-marker-cluster-mysite",
    iconSize: L.point(24, 24, true),
  });
};

const createClusterNetwork = function (cluster) {
  return L.divIcon({
    html: ReactDOMServer.renderToString(
      <PulseMarker
        count={cluster.getChildCount()}
        type={"network"}
        testId={"map-icon-network-site"}
      />
    ),
    className: "custom-marker-cluster-network",
    iconSize: L.point(24, 24, true),
  });
};

const createClusterOfferDestination = function (cluster) {
  return L.divIcon({
    html: ReactDOMServer.renderToString(
      <PulseMarker
        count={cluster.getChildCount()}
        type={"offerDestination"}
        testId={"map-icon-offerdestination-site"}
      />
    ),
    className: "custom-marker-cluster-network",
    iconSize: L.point(24, 24, true),
  });
};

const SetBounds = ({ bounds, boundsOptions, allSiteIdsStr }) => {
  const map = useMap();
  const boundsRef = useRef();
  const boundsOptionsRef = useRef();
  const allSiteIdsStrRef = useRef();
  const prevBounds = boundsRef.current;
  // We zoom the map if the computed bounds change or if the list
  // of sites being shown on the map changes
  const shouldZoomMap =
    prevBounds === undefined ||
    !prevBounds.equals(bounds) ||
    allSiteIdsStrRef.current !== allSiteIdsStr;
  boundsRef.current = bounds;
  boundsOptionsRef.current = boundsOptions;
  allSiteIdsStrRef.current = allSiteIdsStr;
  useEffect(() => {
    if (shouldZoomMap) {
      map.fitBounds(boundsRef.current, boundsOptionsRef.current);
    }
  }, [map, shouldZoomMap]);
  return null;
};

const ownershipMap = {
  tenant: "Tenant",
  landlord: "Landlord",
  ownerOccupier: "Owner Occupier",
  agent: "Agent",
  broker: "Broker",
};

const Map = ({
  width,
  height,
  mySites,
  networkSites,
  orgID,
  onSiteClick,
  isShowingSpecificOffer,
  offerType,
  offer,
}) => {
  const [tileLoadingLabel, setTileLoadingLabel] = useState("tile-loading");
  const defaultCenter = [51.50032365386702, -0.12426640270284971];

  const getSiteCenter = (site) => {
    return [site.latitude, site.longitude];
  };
  const mapCenter =
    mySites.length > 0 ? getSiteCenter(mySites[0]) : defaultCenter;
  const bounds = latLngBounds([mapCenter]);

  const mySitesMarkers = mySites.map((site) => {
    const center = getSiteCenter(site);
    bounds.extend(center);

    return (
      <Marker
        key={site.id}
        position={center}
        icon={MySiteMarker}
        eventHandlers={{ popupopen: () => onSiteClick(site) }}
      >
        <Popup closeButton={false}>
          <PopupContent
            orgID={orgID}
            site={site}
            isNetwork={false}
            isOfferDestination={false}
            isShowingSpecificOffer={isShowingSpecificOffer}
            offerType={offerType}
            offer={offer}
          />
        </Popup>
      </Marker>
    );
  });

  const networkSiteMarkers = networkSites.map((site) => {
    let center = getSiteCenter(site);
    if (isShowingSpecificOffer) {
      bounds.extend(center);
    }
    // We don't zoom the map to show network sites when we're not rendering a
    // specific offer. This means that the map focuses on where the user's sites
    // are but still shows all the network sites if the user zooms out.
    return (
      <Marker
        key={site.id}
        position={center}
        icon={isShowingSpecificOffer ? OfferDestinationMarker : NetworkMarker}
        eventHandlers={{ popupopen: () => onSiteClick(site) }}
      >
        <Popup closeButton={false}>
          <PopupContent
            orgID={orgID}
            site={site}
            isNetwork={site.isNetwork}
            isOfferDestination={isShowingSpecificOffer}
            isShowingSpecificOffer={isShowingSpecificOffer}
            offerType={offerType}
            offer={offer}
          />
        </Popup>
      </Marker>
    );
  });

  const onClusterClick = (event) => {
    const map = event.target;
    const cluster = event.layer;
    const zoom = map._zoom;
    const maxZoom = map._maxZoom;

    if (zoom >= maxZoom) {
      cluster.spiderfy();
    } else {
      cluster.zoomToBounds({ padding: [20, 20] });
    }
  };

  const allSiteIdsStr =
    mySites.map((site) => site.id).join(",") +
    "," +
    networkSites.map((site) => site.id).join(",");

  return (
    <MapWrapper
      width={width}
      height={height}
      className={`map-offer-type-${isShowingSpecificOffer ? offerType : "map"}`}
      data-testid={tileLoadingLabel}
    >
      <MapContainer
        scrollWheelZoom={false}
        maxZoom={18}
        attributionControl={false}
      >
        <MapLegend
          mySitesLength={mySites.length}
          isShowingSpecificOffer={isShowingSpecificOffer}
          offerType={offerType}
        />
        <SetBounds
          bounds={bounds}
          boundsOptions={{
            padding: [200, 200],
            maxZoom: isShowingSpecificOffer ? undefined : 6,
          }}
          allSiteIdsStr={allSiteIdsStr}
        />
        <TileLayer
          attribution='&copy; <a href="https://www.mapbox.com/">MapBox</a>'
          url={mapUrl}
          eventHandlers={{
            loading: () => setTileLoadingLabel("tile-loading"),
            load: () => setTileLoadingLabel("tile-loaded"),
          }}
        />
        <MarkerClusterGroup
          showCoverageOnHover={false}
          spiderfyOnMaxZoom={false}
          spiderLegPolylineOptions={{ opacity: 0.4, color: "#a2a6ff" }}
          zoomToBoundsOnClick={false}
          onClick={onClusterClick}
          iconCreateFunction={createClusterMySite}
        >
          {mySitesMarkers}
        </MarkerClusterGroup>

        {!isShowingSpecificOffer && (
          <MarkerClusterGroup
            showCoverageOnHover={false}
            spiderfyOnMaxZoom={false}
            spiderLegPolylineOptions={{ opacity: 0.4, color: "#2779a7" }}
            zoomToBoundsOnClick={false}
            onClick={onClusterClick}
            iconCreateFunction={createClusterNetwork}
          >
            {networkSiteMarkers}
          </MarkerClusterGroup>
        )}

        {isShowingSpecificOffer && (
          <MarkerClusterGroup
            showCoverageOnHover={false}
            spiderfyOnMaxZoom={false}
            spiderLegPolylineOptions={{ opacity: 0.4, color: "#DBA507" }}
            zoomToBoundsOnClick={false}
            onClick={onClusterClick}
            iconCreateFunction={createClusterOfferDestination}
          >
            {networkSiteMarkers}
          </MarkerClusterGroup>
        )}

        {isShowingSpecificOffer && (
          <Polyline
            positions={[
              getSiteCenter(mySites[0]),
              getSiteCenter(networkSites[0]),
            ]}
            color={"#2779a7"}
            weight={2}
            opacity={0.5}
            className="energy-flow"
          />
        )}
      </MapContainer>
    </MapWrapper>
  );
};

export default Map;

const PopupContent = ({
  orgID,
  site,
  isNetwork,
  isOfferDestination,
  isShowingSpecificOffer,
  offerType,
  offer,
}) => {
  const navigate = useNavigate();

  const exported = site.energyFlowAnnual.exported;
  const gridImport = site.energyFlowAnnual.gridImport;
  const canSellAmount = formatKiloUnitsWithValue(exported, "Wh");
  const canBuyAmount = formatKiloUnitsWithValue(gridImport, "Wh");
  const commerciallyOperationalMonths = site.commerciallyOperationalMonths;
  const commerciallyOperationalString =
    commerciallyOperationalMonths > 0
      ? `${commerciallyOperationalMonths} ${
          commerciallyOperationalMonths > 1 ? "months" : "month"
        }`
      : "Operational";
  const networkImportTariff = (site.networkImportTariff * 100).toFixed(2) + "p";

  let relatedOwnershipElement = null;

  if (!isNetwork) {
    const orgRelationshipWithSite = site.siteOwnerships.find(
      (ownership) => ownership.orgID === orgID
    ).ownership;
    const relatedOwnershipType =
      orgRelationshipWithSite === "landlord" ? "tenant" : "landlord";
    const relatedOwnershipsCount = site.siteOwnerships.filter(
      (ownership) => ownership.ownership === relatedOwnershipType
    ).length;
    if (relatedOwnershipsCount > 0) {
      const firstRelatedOwnership = site.siteOwnerships.find(
        (ownership) => ownership.ownership === relatedOwnershipType
      );
      relatedOwnershipElement = (
        <>
          <PopupValueElement
            valueText={firstRelatedOwnership.name}
            subText={ownershipMap[firstRelatedOwnership.ownership]}
            marginTop="1.33em"
          />
          {relatedOwnershipsCount > 1 && (
            <span>+{relatedOwnershipsCount - 1} more</span>
          )}
        </>
      );
    }
  }

  const onPopupTitleClick = () => {
    const redirectString = `/org/${orgID}/site/${site.id}`;
    if (!isNetwork) {
      navigate(redirectString, { replace: true });
    }
  };

  return (
    <div>
      <PopupText
        $fontWeight="0"
        $clickable={!isNetwork}
        onClick={onPopupTitleClick}
      >
        {isNetwork ? "InRange Network Site" : site.name}
      </PopupText>
      {(!isShowingSpecificOffer ||
        (!isOfferDestination &&
          isShowingSpecificOffer &&
          offerType === "sell")) &&
        exported > 0 && (
          <>
            <PopupSubHeading $isBlue={isNetwork || isOfferDestination}>
              Total sellable excess
            </PopupSubHeading>
            <PopupValueElement valueText={canSellAmount} />
          </>
        )}
      {(!isShowingSpecificOffer ||
        (!isOfferDestination &&
          isShowingSpecificOffer &&
          offerType === "buy")) &&
        gridImport > 0 && (
          <>
            <PopupSubHeading $isBlue={isNetwork || isOfferDestination}>
              Total unmet demand
            </PopupSubHeading>
            <PopupValueElement valueText={canBuyAmount} />
            <PopupValueElement
              valueText={networkImportTariff}
              subText={"/ kWh as generated solar"}
            />
          </>
        )}
      {isShowingSpecificOffer && isOfferDestination && offerType === "sell" && (
        <>
          <PopupSubHeading $isBlue={true}>Offering to buy</PopupSubHeading>
          <PopupValueElement
            valueText={offer.values[0].main}
            subText={"matched"}
          />
          <PopupValueElement
            valueText={offer.sizePercent}
            subText={"of your excess energy"}
          />
          <PopupValueElement
            valueText={offer.values[1].main.replace(" p/kWh", "p")}
            subText={" / kWh as generated solar"}
          />
        </>
      )}
      {isShowingSpecificOffer && isOfferDestination && offerType === "buy" && (
        <>
          <PopupSubHeading $isBlue={true}>Offering to sell</PopupSubHeading>
          <PopupValueElement
            valueText={offer.values[0].main}
            subText={"matched"}
          />
          <PopupValueElement
            valueText={offer.sizePercent}
            subText={"of your unmet demand"}
          />
          <PopupValueElement
            valueText={offer.values[1].main.replace(" p/kWh", "p")}
            subText={"/ kWh as generated solar"}
          />
        </>
      )}
      {!isShowingSpecificOffer && relatedOwnershipElement}
      <PopupSubHeading $isBlue={isNetwork || isOfferDestination}>
        Est. commercially operational
      </PopupSubHeading>
      <PopupValueElement valueText={commerciallyOperationalString} />
      {isNetwork && (
        <PopupText
          $fontWeight="0"
          $fontSize="12px"
          style={{ marginTop: "1.33em" }}
        >
          * Location is approximate
        </PopupText>
      )}
    </div>
  );
};

const PopupValueElement = ({ valueText, subText, marginTop }) => {
  return (
    <PopupText style={{ marginTop: marginTop }}>
      {valueText} <SubPopupText>{subText}</SubPopupText>
    </PopupText>
  );
};

const MapWrapper = styled.div`
  position: relative;
  ${({ width, height }) => `
    width: ${width};
    height: ${height};
    z-index: 0;
  `}
`;

const PopupSubHeading = styled.h4`
  color: ${({ $isBlue }) => ($isBlue ? "#2779a7" : "#a2a6ff")};
  margin-bottom: 0;
`;

const PopupText = styled.div`
  font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : "16px")};
  font-weight: ${({ $fontWeight }) => ($fontWeight ? $fontWeight : 700)};
  cursor: ${({ $clickable }) => ($clickable ? "pointer" : "default")};
  width: fit-content;
`;

const SubPopupText = styled.span`
  font-size: 12px;
  font-weight: normal;
  width: fit-content;
`;
