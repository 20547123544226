import styled from "styled-components";
import TabButtons from "./buttons/TabButtons";

const TableListToggleButtons = ({ tabs, paddingLeft }) => {
  return (
    <TableListToggleButtonsContainer $paddingLeft={paddingLeft}>
      <TabButtons tab1={tabs.tab1} tab2={tabs.tab2} tab3={tabs.tab3} />
    </TableListToggleButtonsContainer>
  );
};

export default TableListToggleButtons;

const TableListToggleButtonsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  padding-left: ${({ $paddingLeft }) => $paddingLeft || "12px"};
`;
