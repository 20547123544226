import {
  generateId,
  mapboxKey,
  useRelatedOrganisationList,
  useSite,
} from "@inrange/building-manager-api-client";
import { trackUserInteraction } from "@inrange/building-manager-api-client/events";
import sqmToSqFtRounded from "@inrange/calculations/sqmToSqFtRounded";
import { useBuildingsByBounds } from "@inrange/inrange-data-api-client";
import {
  SiteCalculationsContext,
  SiteCalculationsProvider,
  SitePreview,
} from "@inrange/shared-components";
import { AddSiteConfig, AddSiteMap, Grid } from "@inrange/theme-components";
import center from "@turf/center";
import { points } from "@turf/helpers";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../auth/UserContext";

const addressWithoutPostcode = (address) => {
  let addressParts = address.split(",");
  addressParts = addressParts.slice(0, 2);
  return addressParts.join(",");
};

const mapboxAddressSearch = (lon, lat) => {
  return `https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?access_token=${mapboxKey}&autocomplete=true`;
};

const checkIsSiteNameUnchanged = (siteName, address, organisationName) => {
  // first get the site name set by the address search
  if (!siteName) return false;
  const siteNameWithOrg =
    addressWithoutPostcode(address) + " \u1806 " + organisationName;
  // we then split the site name by the unicode character and take the first two parts
  const newSiteNameSplit = siteName.split(" \u1806 ");
  const newSiteNameWithOrg =
    newSiteNameSplit[0] + " \u1806 " + newSiteNameSplit[1];
  return siteNameWithOrg === newSiteNameWithOrg;
};

const AddSite = ({ organisation, setAddSiteCrumb }) => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { createSite } = useSite({ userOrgId: organisation.id });
  const siteId = generateId();

  const [newSite, setNewSite] = useState({ id: siteId, name: "" });
  const [formValidity, setFormValidity] = useState({
    building: false,
    name: false,
    addressSearched: false, // This isn't referenced explicitly, but is used to trigger the form after the user has searched for an address
  });
  const [address, setAddress] = useState("");
  const [mapBounds, setMapBounds] = useState();
  const [relatedOrgName, setRelatedOrgName] = useState("");
  const [newSiteValues, setNewSiteValues] = useState({}); // shape of newSiteValues:
  // {
  //   isNewOrg: boolean,
  //   orgType: string,
  //   orgName: string,
  //   generator: boolean,
  //   sitePpaLength: number,
  //   siteTenantAnnualDemandKwh: number,
  // }
  const [addressError, setAddressError] = useState(false);
  const [position, setPosition] = useState([52.3555, -1.6743]);
  const [showValidationErrors, setShowValidationErrors] = useState(false); // This is used to trigger validation after the user has clicked 'add site'
  const [buildingsInBounds, setBuildingsInBounds] = useState({}); // These are selectable polygons
  const [renderedBuildings, setRenderedBuildings] = useState({}); // There are selected polygons
  const [kwpPerSqm, setKwpPerSqm] = useState(0);
  const totalBuildingArea = Object.values(renderedBuildings).reduce(
    (acc, b) => acc + b.surface_area_sqm,
    0
  );
  const isLandlord = organisation.orgType === "Landlord";
  const orgId = organisation.id;
  const orgName = organisation.name;

  const setInstalledCapacityUsingKwpPerSqm = useCallback(
    (updatedKwpPerSqm) => {
      const updatedInstalledCapacity =
        totalBuildingArea * updatedKwpPerSqm * 0.35;
      setNewSite((prevNewSite) => {
        return { ...prevNewSite, installedCapacity: updatedInstalledCapacity };
      });
      setKwpPerSqm(updatedKwpPerSqm);
    },
    [totalBuildingArea, setKwpPerSqm, setNewSite]
  );

  // Fetch all buildings in the map bounds
  const fetchBuildingsInBounds = useBuildingsByBounds(mapBounds);

  // Fetch list of all organisations related to this organisation
  const { fetchRelatedOrganisations } = useRelatedOrganisationList(
    orgId,
    orgId
  );
  const relatedOrgList = fetchRelatedOrganisations.data?.organisations || [];

  useEffect(() => {
    if (!fetchBuildingsInBounds.data) return;

    // If we're too zoomed out, don't render billions of buildings
    const mapSizeMeters = mapBounds
      .getNorthEast()
      .distanceTo(mapBounds.getSouthWest());
    if (mapSizeMeters > 7000) return;

    const unselectedBuildings = {};
    Object.keys(fetchBuildingsInBounds.data).forEach((key) => {
      if (!renderedBuildings[key]) {
        unselectedBuildings[key] = fetchBuildingsInBounds.data[key];
      }
    });
    setBuildingsInBounds(unselectedBuildings);
  }, [fetchBuildingsInBounds.data, renderedBuildings, mapBounds]);

  const setSiteNameAndCrumb = (siteName, relatedOrgName) => {
    setRelatedOrgName(relatedOrgName);
    const siteNameWithOrg =
      addressWithoutPostcode(address) + " \u1806 " + organisation.name;
    const isSiteNameUnchanged = checkIsSiteNameUnchanged(
      siteName,
      address,
      organisation.name
    );
    // If the user has changed the site name, use that. Otherwise,
    // add the related org name to the site name if it exists
    if (isSiteNameUnchanged) {
      if (relatedOrgName) {
        siteName = siteNameWithOrg + " \u1806 " + relatedOrgName;
      } else {
        siteName = siteNameWithOrg;
      }
    }
    setFormValidity({ ...formValidity, name: siteName.length > 0 });
    setNewSite({ ...newSite, name: siteName });
    const crumb = siteName.length > 0 ? `${siteName} (unsaved)` : "Add a site";
    setAddSiteCrumb(crumb);
  };

  const addressSearchedEvent = (address) => {
    trackUserInteraction(
      { address },
      "ADD_SITE",
      "ADD_SITE_ADDRESS_SEARCHED",
      user.email.toLowerCase(),
      "customer-app"
    );
  };

  const suggestionHandler = (suggestion) => {
    if (!suggestion) {
      setAddressError(true);
      setAddress("");
      return;
    }
    const center = suggestion.center;
    const lat = center[1];
    const long = center[0];
    const address = suggestion.place_name;
    setAddress(suggestion.place_name);
    const siteNamePrefix = `${addressWithoutPostcode(address)} \u1806 ${
      organisation.name
    }`;
    const siteNameSuffix = relatedOrgName ? ` \u1806 ${relatedOrgName}` : "";
    const siteName = siteNamePrefix + siteNameSuffix;

    const crumb = `${siteName} (unsaved)`;
    setAddSiteCrumb(crumb);
    setFormValidity({ ...formValidity, name: siteName.length > 0 });

    setRenderedBuildings({});
    setFormValidity({
      ...formValidity,
      addressSearched: true,
      name: true,
      building: false,
    });
    setAddressError(false);
    setNewSite({
      ...newSite,
      name: siteName,
      address: address,
    });
    setPosition([lat, long]);
    addressSearchedEvent(address);
  };

  // Controllers to add and remove the buildings from selected buildings
  const setBuildingsForPreview = (buildings) => {
    const previewBuildings = buildings.map((b) => {
      return {
        id: b.key,
        surfaceAreaSqM: b.surface_area_sqm,
        siteID: siteId,
        geometry: b.geometry,
      };
    });
    setNewSite({ ...newSite, buildings: previewBuildings });
  };

  const buildingClickedEvent = (
    buildingArea,
    latitude,
    longitude,
    buildingKey,
    buildingAdded
  ) => {
    const buildingAreaFt = sqmToSqFtRounded(buildingArea);
    fetch(mapboxAddressSearch(longitude, latitude))
      .then((res) => res.json())
      .then((data) => {
        const buildingAddress =
          data?.features.find((feature) =>
            feature.place_type.includes("postcode")
          ).place_name || "No address found";

        trackUserInteraction(
          {
            buildingArea: buildingAreaFt,
            buildingAdded,
            latitude,
            longitude,
            buildingKey,
            address: buildingAddress,
          },
          "ADD_SITE",
          "ADD_SITE_BUILDING_CLICKED",
          user.email.toLowerCase(),
          "customer-app"
        );
      });
  };

  const computeInstalledCapacity = (updatedBuildings) => {
    const updatedTotalBuildingArea = Object.values(updatedBuildings).reduce(
      (acc, b) => acc + b.surface_area_sqm,
      0
    );
    const updatedInstalledCapacity =
      updatedTotalBuildingArea * kwpPerSqm * 0.35;
    setNewSite((prevNewSite) => {
      return { ...prevNewSite, installedCapacity: updatedInstalledCapacity };
    });
  };

  const removeBuilding = (key) => {
    const { [key]: _value, ...updatedRenderedBuildings } = renderedBuildings;
    const building = renderedBuildings[key];
    const buildingArea = building.surface_area_sqm;
    const buildingKey = building.key;
    const buildingCeneter = center(building.geometry).geometry.coordinates;
    const latitude = buildingCeneter[1];
    const longitude = buildingCeneter[0];

    setFormValidity({
      ...formValidity,
      building: Object.values(updatedRenderedBuildings).length > 0,
    });
    setRenderedBuildings(updatedRenderedBuildings);
    computeInstalledCapacity(updatedRenderedBuildings);
    setBuildingsForPreview(Object.values(updatedRenderedBuildings));
    buildingClickedEvent(buildingArea, latitude, longitude, buildingKey, false);
  };
  const addBuilding = (key) => {
    const building = buildingsInBounds[key];
    const buildingArea = building.surface_area_sqm;
    const buildingKey = building.key;
    const buildingPoints = building.geometry.coordinates[0];
    const latitude = buildingPoints[0][1];
    const longitude = buildingPoints[0][0];
    setFormValidity({ ...formValidity, building: true });
    const updatedRenderedBuildings = {
      ...renderedBuildings,
      [key]: buildingsInBounds[key],
    };
    setRenderedBuildings(updatedRenderedBuildings);
    computeInstalledCapacity(updatedRenderedBuildings);
    setBuildingsForPreview(Object.values(updatedRenderedBuildings));
    buildingClickedEvent(buildingArea, latitude, longitude, buildingKey, true);
  };

  const siteValid = formValidity.name && formValidity.building;

  const handleAddSite = (siteOwnerships, siteValues) => {
    setShowValidationErrors(true);
    setNewSiteValues(siteValues);

    if (siteValid) {
      const buildingsForNewSite = Object.values(renderedBuildings);
      const newSiteBuildingCenters = Object.values(buildingsForNewSite).map(
        (building) => center(building.geometry).geometry.coordinates
      );
      const centerOfAllNewSiteBuildings = center(
        points(newSiteBuildingCenters)
      );
      const latitude = centerOfAllNewSiteBuildings.geometry.coordinates[1];
      const longitude = centerOfAllNewSiteBuildings.geometry.coordinates[0];
      const site = {
        ...newSite,
        ppaLength: siteValues.sitePpaLength || 10,
        buildingProfile: organisation.buildingProfile || "retail",
        siteOwnerships: siteOwnerships,
        buildings: buildingsForNewSite,
        latitude,
        longitude,
      };
      if (siteValues.siteTenantAnnualDemandKwh) {
        site.demandCoefficientKWhPerM2 =
          siteValues.siteTenantAnnualDemandKwh / totalBuildingArea;
      }
      if (!siteValues.generator) {
        site.installedCapacity = 0; // If the site is not a generator, set the installed capacity to 0 to represent offtaker sites
      }
      const sitePayload = { site };
      createSite.mutate(sitePayload);
    }
  };

  if (createSite.isError) {
    if (createSite.isError) {
      console.log(`Error creating site - ${createSite.error.message}`);
    }
  }

  useEffect(() => {
    if (createSite.isSuccess) {
      const response = createSite.data;
      const newSiteId = response.site_id;
      trackUserInteraction(
        {
          newOrg: newSiteValues.isNewOrg,
          relatedOrgType: newSiteValues.orgType,
          relatedOrgName: newSiteValues.orgName,
          generator: newSiteValues.generator,
          siteName: newSite.name,
          siteID: newSiteId,
          ppaLength: newSiteValues.sitePpaLength,
          demand: newSiteValues.siteTenantAnnualDemandKwh,
        },
        "ADD_SITE",
        "ADD_SITE_CREATED",
        user.email.toLowerCase(),
        "customer-app"
      );
      navigate(`/org/${organisation.id}/site/${response.site_id}`);
    }
  }, [
    createSite.isSuccess,
    createSite.data,
    newSite,
    organisation.id,
    newSiteValues,
    user.email,
    navigate,
  ]);

  return (
    <SiteCalculationsProvider userOrgId={orgId} site={newSite}>
      <SitePreview previewSite={newSite} originalSite={{}}>
        <AddSiteView
          position={position}
          isLandlord={isLandlord}
          orgId={orgId}
          orgName={orgName}
          buildingsInBounds={buildingsInBounds}
          setMapBounds={setMapBounds}
          addBuilding={addBuilding}
          removeBuilding={removeBuilding}
          renderedBuildings={renderedBuildings}
          formValidity={formValidity}
          showValidationErrors={showValidationErrors}
          suggestionHandler={suggestionHandler}
          newSite={newSite}
          setNewSite={setNewSite}
          addressError={addressError}
          relatedOrgList={relatedOrgList}
          totalBuildingArea={totalBuildingArea}
          setSiteNameAndCrumb={setSiteNameAndCrumb}
          handleAddSite={handleAddSite}
          siteCreating={createSite.isLoading}
          landlordOrgsLoading={fetchRelatedOrganisations.isLoading}
          setInstalledCapacityUsingKwpPerSqm={
            setInstalledCapacityUsingKwpPerSqm
          }
        />
      </SitePreview>
    </SiteCalculationsProvider>
  );
};

const AddSiteView = ({
  position,
  isLandlord,
  orgId,
  orgName,
  buildingsInBounds,
  setMapBounds,
  addBuilding,
  removeBuilding,
  renderedBuildings,
  formValidity,
  showValidationErrors,
  suggestionHandler,
  newSite,
  addressError,
  relatedOrgList,
  totalBuildingArea,
  setSiteNameAndCrumb,
  handleAddSite,
  siteCreating,
  landlordOrgsLoading,
  setInstalledCapacityUsingKwpPerSqm,
}) => {
  const { siteCalculations, isCalculating } = useContext(
    SiteCalculationsContext
  );

  const defaultDemand = parseFloat(
    siteCalculations.tenantAnnualDemandKwh.toFixed(2)
  );
  const kwpPerSqm = siteCalculations.halfHourlyGeneration?.kwpPerSqm || 0;
  useEffect(() => {
    setInstalledCapacityUsingKwpPerSqm(kwpPerSqm);
  }, [kwpPerSqm, setInstalledCapacityUsingKwpPerSqm]);

  return (
    <Grid $cols={2} $colwidth={"1fr"} $gap={"5px"} $margin={"5px 0px 0px 0px"}>
      <AddSiteMap
        position={position}
        mapZoom={6.3}
        buildingsInBounds={buildingsInBounds}
        setMapBounds={setMapBounds}
        addBuilding={addBuilding}
        removeBuilding={removeBuilding}
        selectedBuildings={renderedBuildings}
        buildingValidity={formValidity.building}
        showValidationErrors={showValidationErrors}
        fitWidth={true}
        height={"calc(100vh - 92px)"}
      />
      <AddSiteConfig
        suggestionHandler={suggestionHandler}
        siteName={newSite.name}
        addressError={addressError}
        isLandlord={isLandlord}
        orgId={orgId}
        orgName={orgName}
        relatedOrgList={relatedOrgList}
        totalBuildingArea={totalBuildingArea}
        setSiteNameAndCrumb={setSiteNameAndCrumb}
        formValidity={formValidity}
        handleAddSite={handleAddSite}
        showValidationErrors={showValidationErrors}
        siteCreating={siteCreating}
        isCalculating={isCalculating}
        orgDropdownLoading={landlordOrgsLoading && !isLandlord}
        defaultDemand={defaultDemand}
        isLoading={kwpPerSqm === 0}
      />
    </Grid>
  );
};

export default AddSite;
