import { KindeProvider } from "@kinde-oss/kinde-auth-react";

import { ReactNode, useContext } from "react";
import { KindeRedirectContext } from "./KindeRedirectContext";
import { KINDE_ENVIRONMENT } from "./kindeEnvironment";

interface KindeAppState {
  redirectTo?: string;
}

// Wrapper component needed to allow for use of the KindeRedirectContext for storing the redirect URL
const KindeAuthenticatedUI = ({
  kindeClientId,
  children,
  isDangerouslyUseLocalStorage,
}: {
  kindeClientId: string;
  children: ReactNode;
  isDangerouslyUseLocalStorage?: boolean;
}) => {
  const { setRedirectUrl } = useContext(KindeRedirectContext);
  return (
    <KindeProvider
      clientId={kindeClientId}
      domain={`https://${KINDE_ENVIRONMENT.KINDE_AUTH_DOMAIN}`}
      redirectUri={`${document.location.protocol}//${document.location.host}/login-redirect`}
      logoutUri={`${document.location.protocol}//${document.location.host}`}
      audience="inrange-api"
      onRedirectCallback={(_user, appState) => {
        const kindeAppState = appState as KindeAppState;
        if (kindeAppState?.redirectTo) {
          setRedirectUrl!(kindeAppState.redirectTo);
        }
      }}
      isDangerouslyUseLocalStorage={!!isDangerouslyUseLocalStorage}
    >
      {children}
    </KindeProvider>
  );
};

export default KindeAuthenticatedUI;
