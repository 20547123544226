import axiosConfig from "./axiosConfig";

const getRelatedLandlordSitesList = async (
  authToken,
  orgId,
  siteId,
  userOrgId
) => {
  const { data } = await axiosConfig.get(
    `/organisation/${orgId}/site/${siteId}/related_landlord_sites${
      userOrgId ? `?userOrgId=${userOrgId}` : ""
    }`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return data;
};

export default getRelatedLandlordSitesList;
