import { HeaderContainer, TabLink, TabLinks } from ".";
import LeasedSitesActive from "../../images/icons/LeasedSitesActive.svg";
import LeasedSitesInActive from "../../images/icons/LeasedSitesInActive.svg";
import OperationalSitesActive from "../../images/icons/OperationalSitesActive.svg";
import OperationalSitesInActive from "../../images/icons/OperationalSitesInActive.svg";
import OwnedSitesActive from "../../images/icons/OwnedSitesActive.svg";
import OwnedSitesInActive from "../../images/icons/OwnedSitesInActive.svg";
import SimpleButton from "../buttons/SimpleButton";

import styled from "styled-components";
import MapIcon from "../../images/icons/map.svg";
import MapIconActive from "../../images/icons/mapactive.svg";
import PortfolioTab from "../PortfolioTab";
import ShareLinkWithUser from "../modal/ShareLinkWithUser";

const PortfolioHeader = ({
  currentUrl,
  siteCounts,
  rootPath,
  dashboardMode,
  notifyAddSiteFn,
  shareAttributes,
}) => {
  const ownerOccupierSitesListUrl = `${rootPath}sites/ownerOccupier`;
  const tenantSitesListUrl = `${rootPath}sites/tenant`;
  const operationalSitesListUrl = `${rootPath}sites/operational`;
  const marketplaceUrl = `${rootPath}marketplace`;
  const addSiteUrl = `${rootPath}add-site`;
  const dashboardUrl = `${rootPath}dashboard`;

  const tenantTabLinks = (
    <TenantTabLinks
      currentUrl={currentUrl}
      dashboardUrl={dashboardUrl}
      marketplaceUrl={marketplaceUrl}
      operationalSitesListUrl={operationalSitesListUrl}
      addSiteUrl={addSiteUrl}
      notifyAddSiteFn={notifyAddSiteFn}
      ownerOccupierSitesListUrl={ownerOccupierSitesListUrl}
      siteCounts={siteCounts}
      tenantSitesListUrl={tenantSitesListUrl}
      shareAttributes={shareAttributes}
      dashboardMode={dashboardMode}
    />
  );

  const landlordTabLinks = (
    <LandlordTabLinks
      currentUrl={currentUrl}
      dashboardUrl={dashboardUrl}
      marketplaceUrl={marketplaceUrl}
      operationalSitesListUrl={operationalSitesListUrl}
      addSiteUrl={addSiteUrl}
      notifyAddSiteFn={notifyAddSiteFn}
      siteCounts={siteCounts}
      tenantSitesListUrl={tenantSitesListUrl}
      shareAttributes={shareAttributes}
      dashboardMode={dashboardMode}
    />
  );

  const tabLinks =
    dashboardMode === "Tenant" ? tenantTabLinks : landlordTabLinks;

  return <HeaderContainer>{tabLinks}</HeaderContainer>;
};

export default PortfolioHeader;

const TenantTabLinks = ({
  currentUrl,
  dashboardUrl,
  marketplaceUrl,
  addSiteUrl,
  operationalSitesListUrl,
  notifyAddSiteFn,
  ownerOccupierSitesListUrl,
  siteCounts,
  tenantSitesListUrl,
  shareAttributes,
  dashboardMode,
}) => {
  const ownerOccupierCount = siteCounts.ownerOccupier || 0;
  const tenantCount = siteCounts.tenant || 0;
  const operationalCount = siteCounts.operational || 0;

  const isTenantOnly = tenantCount > 0 && ownerOccupierCount === 0;
  const isOwnerOccupierOnly = tenantCount === 0 && ownerOccupierCount > 0;
  const isProposalSites = tenantCount > 0 || ownerOccupierCount > 0;

  return (
    <TabLinks>
      {isProposalSites ? (
        <TenantSiteTabs
          currentUrl={currentUrl}
          dashboardUrl={dashboardUrl}
          isOwnerOccupierOnly={isOwnerOccupierOnly}
          isTenantOnly={isTenantOnly}
          ownerOccupierSitesListUrl={ownerOccupierSitesListUrl}
          siteCounts={siteCounts}
          tenantSitesListUrl={tenantSitesListUrl}
        />
      ) : null}
      {operationalCount > 0 && (
        <TabLink
          $active={currentUrl === operationalSitesListUrl}
          to={operationalSitesListUrl}
        >
          <PortfolioTab
            active={currentUrl === operationalSitesListUrl}
            tabName={"Operational Sites"}
            icon={
              currentUrl === operationalSitesListUrl
                ? OperationalSitesActive
                : OperationalSitesInActive
            }
            siteCount={operationalCount}
          />
        </TabLink>
      )}
      <TabLink $active={currentUrl === marketplaceUrl} to={marketplaceUrl}>
        <PortfolioTab
          active={currentUrl === marketplaceUrl}
          tabName={"Marketplace"}
          icon={currentUrl === marketplaceUrl ? MapIconActive : MapIcon}
        />
      </TabLink>
      <ShareLinkWithUserContainer $active={currentUrl !== marketplaceUrl}>
        <ShareLinkWithUser
          shareAttributes={shareAttributes}
          button={true}
          dashboardMode={dashboardMode}
        />
      </ShareLinkWithUserContainer>
      <AddSiteButtonContainer $active={currentUrl !== marketplaceUrl}>
        <SimpleButton
          label={"Add a site"}
          onClick={notifyAddSiteFn}
          to={addSiteUrl}
          fontWeight="400"
          width="140px"
          height="40px"
        />
      </AddSiteButtonContainer>
    </TabLinks>
  );
};

const TenantSiteTabs = ({
  isTenantOnly,
  isOwnerOccupierOnly,
  siteCounts,
  currentUrl,
  tenantSitesListUrl,
  dashboardUrl,
  ownerOccupierSitesListUrl,
}) => {
  if (isTenantOnly) {
    const activeUrls = [tenantSitesListUrl, dashboardUrl];
    return (
      <TabLink $active={activeUrls.includes(currentUrl)} to={dashboardUrl}>
        <PortfolioTab
          active={activeUrls.includes(currentUrl)}
          tabName={"Sites Overview"}
          icon={
            activeUrls.includes(currentUrl)
              ? LeasedSitesActive
              : LeasedSitesInActive
          }
          siteCount={siteCounts.tenant}
        />
      </TabLink>
    );
  }

  if (isOwnerOccupierOnly) {
    const activeUrls = [ownerOccupierSitesListUrl, dashboardUrl];
    return (
      <TabLink $active={activeUrls.includes(currentUrl)} to={dashboardUrl}>
        <PortfolioTab
          active={activeUrls.includes(currentUrl)}
          tabName={"Sites Overview"}
          icon={
            activeUrls.includes(currentUrl)
              ? OwnedSitesActive
              : OwnedSitesInActive
          }
          siteCount={siteCounts.ownerOccupier}
        />
      </TabLink>
    );
  }

  return (
    <>
      <TabLink
        $active={currentUrl === ownerOccupierSitesListUrl}
        to={ownerOccupierSitesListUrl}
      >
        <PortfolioTab
          active={currentUrl === ownerOccupierSitesListUrl}
          tabName={"Owned Sites"}
          icon={
            currentUrl === ownerOccupierSitesListUrl
              ? OwnedSitesActive
              : OwnedSitesInActive
          }
          siteCount={siteCounts.ownerOccupier}
        />
      </TabLink>
      <TabLink
        $active={[tenantSitesListUrl, dashboardUrl].includes(currentUrl)}
        to={tenantSitesListUrl}
      >
        <PortfolioTab
          active={[tenantSitesListUrl, dashboardUrl].includes(currentUrl)}
          tabName={"Leased Sites"}
          icon={
            [tenantSitesListUrl, dashboardUrl].includes(currentUrl)
              ? LeasedSitesActive
              : LeasedSitesInActive
          }
          siteCount={siteCounts.tenant}
        />
      </TabLink>
    </>
  );
};

const LandlordTabLinks = ({
  currentUrl,
  dashboardUrl,
  siteCounts,
  marketplaceUrl,
  operationalSitesListUrl,
  addSiteUrl,
  notifyAddSiteFn,
  shareAttributes,
  dashboardMode,
}) => {
  const landlordSitesCount = siteCounts?.landlord || 0;
  const ownerOccupierSitesCount = siteCounts?.ownerOccupier || 0;
  const operationalSitesCount = siteCounts?.operational || 0;

  const yourSitesCount = ownerOccupierSitesCount + landlordSitesCount;

  return (
    <TabLinks>
      {yourSitesCount > 0 && (
        <TabLink to={dashboardUrl} $active={currentUrl === dashboardUrl}>
          <PortfolioTab
            active={currentUrl === dashboardUrl}
            tabName={"Sites Overview"}
            icon={
              currentUrl === dashboardUrl
                ? OwnedSitesActive
                : OwnedSitesInActive
            }
            siteCount={yourSitesCount}
          />
        </TabLink>
      )}
      {operationalSitesCount > 0 && (
        <TabLink
          $active={currentUrl === operationalSitesListUrl}
          to={operationalSitesListUrl}
        >
          <PortfolioTab
            active={currentUrl === operationalSitesListUrl}
            tabName={"Operational Sites"}
            icon={
              currentUrl === operationalSitesListUrl
                ? OperationalSitesActive
                : OperationalSitesInActive
            }
            siteCount={operationalSitesCount}
          />
        </TabLink>
      )}
      <TabLink $active={currentUrl === marketplaceUrl} to={marketplaceUrl}>
        <PortfolioTab
          active={currentUrl === marketplaceUrl}
          tabName={"Marketplace"}
          icon={currentUrl === marketplaceUrl ? MapIconActive : MapIcon}
        />
      </TabLink>
      <ShareLinkWithUserContainer $active={currentUrl !== marketplaceUrl}>
        <ShareLinkWithUser
          shareAttributes={shareAttributes}
          button={true}
          dashboardMode={dashboardMode}
        />
      </ShareLinkWithUserContainer>
      <AddSiteButtonContainer $active={currentUrl !== marketplaceUrl}>
        <SimpleButton
          label={"Add a site"}
          onClick={notifyAddSiteFn}
          to={addSiteUrl}
          fontWeight="400"
          width="140px"
          height="40px"
        />
      </AddSiteButtonContainer>
    </TabLinks>
  );
};

const AddSiteButtonContainer = styled.div`
  margin-left: 10px;
  display: ${(props) => (props.$active ? "block" : "none")};
`;

const ShareLinkWithUserContainer = styled.div`
  margin-left: auto;
  display: ${(props) => (props.$active ? "block" : "none")};
`;
