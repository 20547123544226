import styled from "styled-components";
import AddSiteForm from "../addSite/AddSiteForm";
import { Column } from "../layout/Flex";
import AddSiteAddressBar from "../mapping/AddSiteAddressBar";

const AddSiteConfig = ({
  suggestionHandler,
  siteName,
  addressError,
  isLandlord,
  orgId,
  orgName,
  relatedOrgList,
  setSiteNameAndCrumb,
  formValidity,
  handleAddSite,
  showValidationErrors,
  siteCreating,
  isCalculating,
  defaultDemand,
  orgDropdownLoading,
  isLoading,
}) => {
  const enableForm = Object.values(formValidity).some((validity) => validity); // If any of the initial form fields are valid, show the form

  return (
    <Column
      $padding={"29px"}
      $gap={"10px"}
      $height={"calc(100vh - 102px)"}
      style={{
        marginTop: 10,
        backgroundColor: "#FFFFFF",
        overflowY: "scroll",
        minHeight: "fit-content",
      }}
    >
      <Title>Add a site</Title>
      <Description>
        Search for a portfolio site by address, then select it on the map.
      </Description>
      <AddSiteAddressBar suggestionHandler={suggestionHandler} />
      {addressError && <AddressError>Provide a valid address.</AddressError>}
      {enableForm && (
        <AddSiteForm
          siteName={siteName}
          isLandlord={isLandlord}
          orgId={orgId}
          orgName={orgName}
          relatedOrgList={relatedOrgList}
          isCalculating={isCalculating}
          setSiteNameAndCrumb={setSiteNameAndCrumb}
          handleAddSite={handleAddSite}
          validSiteName={formValidity.name}
          showValidationErrors={showValidationErrors}
          defaultDemand={defaultDemand}
          siteCreating={siteCreating}
          orgDropdownLoading={orgDropdownLoading}
          isLoading={isLoading}
        />
      )}
    </Column>
  );
};

export default AddSiteConfig;

const AddressError = styled.span`
  color: ${({ theme }) => theme.colors.redSolid};
  font-size: 14px;
  margin-left: 8px;
`;

const Title = styled.div`
  font-size: 20px;
`;

const Description = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey700};
`;
