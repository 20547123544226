import { Link } from "react-router-dom";
import styled from "styled-components";

export const HeaderContainer = styled.div`
  background: white;
  padding: 10px 12px;
  h2,
  p {
    margin: 0;
  }
`;

export const ShareLinkIcon = styled.img`
  margin-left: 16px;
  cursor: pointer;
`;

export const TabLinks = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

export const TabLink = styled(Link)`
  text-decoration: none;
  margin: 0 4px 0 0;
  font-size: 14px;
`;
