import { getMapboxUrl } from "@inrange/building-manager-api-client";
import sqmToSqFtRounded from "@inrange/calculations/sqmToSqFtRounded";
import "leaflet/dist/leaflet.css";
import hash from "object-hash";
import { useEffect, useState } from "react";
import { GeoJSON, MapContainer, TileLayer, useMapEvent } from "react-leaflet";
import styled from "styled-components";
import tooltipInfoImg from "../../images/icons/circleInfo.svg";
import tooltipInfoErrorImg from "../../images/icons/circleInfoRed.svg";
import { Column } from "../layout/Flex";

const mapUrl = getMapboxUrl("clemysf5c005s01o9u6mw58qc", "tom-inrange");

const setBounds = (map, setMapBounds) => {
  const bounds = map.getBounds();
  setMapBounds(bounds);
};

const defaultCenter = [52.3555, -1.6743];

const MapEventView = ({ center, setMapBounds }) => {
  // useMapEvent is a hook from react-leaflet that cretes an event listener
  // as well as a map object
  const moveEndHandler = () => {
    setBounds(map, setMapBounds);
  };
  const map = useMapEvent("moveend", moveEndHandler);

  // if position changes, update the map center, zoom and bounds
  useEffect(() => {
    const isDefaultCenter =
      center[0] === defaultCenter[0] && center[1] === defaultCenter[1];
    map.setView(center, isDefaultCenter ? 6.3 : 17);
    setBounds(map, setMapBounds);
  }, [center, map, setMapBounds]);

  return null;
};

const AddSiteMap = ({
  position = defaultCenter,
  mapZoom,
  buildingsInBounds,
  setMapBounds,
  addBuilding,
  removeBuilding,
  selectedBuildings,
  buildingValidity,
  showValidationErrors,
  fitWidth,
  width,
  height,
}) => {
  const [tileLoadingLabel, setTileLoadingLabel] = useState("tile-loading");
  const onMapLoad = (event) => {
    const map = event.target;
    const bounds = map.getBounds();
    setMapBounds(bounds);
  };

  const buildingCount = Object.keys(buildingsInBounds).length;
  const selectedBuildingCount = Object.keys(selectedBuildings).length;
  const selectedBuildingArea = sqmToSqFtRounded(
    Object.values(selectedBuildings).reduce(
      (acc, b) => acc + b.surface_area_sqm,
      0
    )
  );

  return (
    <Column
      $width={fitWidth ? "100%" : width}
      $height={height}
      $justifyContent={"center"}
      $padding={"0 5px"}
      data-testid={tileLoadingLabel}
    >
      <MapContainer
        style={{
          width: "100%",
          height: "100%",
          marginTop: "10px",
          zIndex: "10",
        }}
        center={position}
        zoom={mapZoom}
        zoomSnap={0.5}
        whenReady={onMapLoad}
        scrollWheelZoom={false}
        attributionControl={false}
      >
        <MapEventView center={position} setMapBounds={setMapBounds} />
        <TileLayer
          attribution='&copy; <a href="https://www.mapbox.com/">MapBox</a>'
          url={mapUrl}
          eventHandlers={{
            loading: () => setTileLoadingLabel("tile-loading"),
            load: () => setTileLoadingLabel("tile-loaded"),
          }}
        />
        <GeoBuildings
          buildings={buildingsInBounds}
          style={buildingStyle}
          onBuildingClick={addBuilding}
        />
        <GeoBuildings
          buildings={selectedBuildings}
          style={selectedBuildingStyle}
          onBuildingClick={removeBuilding}
        />
      </MapContainer>
      {(buildingCount > 0 || showValidationErrors) && (
        <AddSiteMapTooltip
          selectedBuildingCount={selectedBuildingCount}
          totalBuildingArea={selectedBuildingArea}
          showValidationErrors={showValidationErrors}
          buildingValidity={buildingValidity}
        />
      )}
    </Column>
  );
};

export default AddSiteMap;

const AddSiteMapTooltip = ({
  selectedBuildingCount,
  totalBuildingArea,
  showValidationErrors,
  buildingValidity,
}) => {
  const tooltipError = showValidationErrors && !buildingValidity;
  const tooltipText = tooltipError
    ? "Click to select at least 1 building before adding."
    : selectedBuildingCount
      ? `${selectedBuildingCount} building${
          selectedBuildingCount > 1 ? "s" : ""
        }: ${totalBuildingArea} ft² surface area`
      : "Click buildings to add to or remove from this site.";

  return (
    <TooltipContainer>
      <TooltipIcon src={tooltipError ? tooltipInfoErrorImg : tooltipInfoImg} />
      <TooltipTextParagraph
        $error={tooltipError}
        data-testid={`addsite-map-tooltip`}
      >
        {tooltipText}
      </TooltipTextParagraph>
    </TooltipContainer>
  );
};

const GeoBuildings = ({ buildings, style, onBuildingClick }) => {
  return Object.values(buildings).map((b) => {
    const key = b.key || hash(b.geometry);
    const onClick = () => {
      onBuildingClick(key);
    };

    return (
      <GeoJSON
        key={"geojson " + key}
        data={b.geometry}
        style={style}
        eventHandlers={{
          click: onClick,
        }}
      />
    );
  });
};

const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  bottom: 8px;
  margin-top: -58px;
  left: 2.4%;
  width: 95%;
  height: 50px;
  background-color: #ffffff;
  border-radius: 5px;
  color: "#000000";
  padding: 5px;
  font-size: 18px;
  z-index: 100;
  box-shadow: 0 5px 5px #00000080;
`;

const TooltipIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 5px;
`;

const TooltipTextParagraph = styled.p`
  color: ${(props) => (props.$error ? props.theme.colors.redSolid : "#000000")};
`;

const buildingStyle = {
  fillColor: "#404040",
  fillOpacity: 0.3,
  color: "#808080",
  weight: 2,
  opacity: 1,
};

const selectedBuildingStyle = {
  fillColor: "#00022F",
  fillOpacity: 0.55,
  color: "#00022F",
  weight: 2,
  opacity: 1,
};
