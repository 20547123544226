import { Row } from "../../components/layout/Flex";
import Tooltip from "../Tooltip";
import {
  ColumnContainer,
  ColumnTextStyle,
  getSortingIcon,
} from "./LandlordSiteListHeaderTooltip";

export const SavingsHeader = ({ sortColumn, sortValue, sortAscending }) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Savings <br /> (year 1)
        <Row $padding={"0px"} $margin={"0px"} $alignItems={"center"}>
          {getSortingIcon(sortColumn, sortValue, sortAscending)}
        </Row>
      </ColumnTextStyle>
      <Row $padding={"0px"} $margin={"0px"} $alignItems={"center"}>
        <Tooltip text="The savings you’ll enjoy in year 1 from procuring energy from InRange. Calculated as the difference between the current market tariff (as stated on the overview) and a the site’s renewable energy tariff and demand." />
      </Row>
    </ColumnContainer>
  );
};

export const LandlordsHeader = ({ sortColumn, sortValue, sortAscending }) => {
  return (
    <ColumnTextStyle>
      Landlords
      {getSortingIcon(sortColumn, sortValue, sortAscending)}
    </ColumnTextStyle>
  );
};

export const CarbonSavingsHeader = ({
  sortColumn,
  sortValue,
  sortAscending,
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Carbon Savings <br /> (year 1)
        <Row $padding={"0px"} $margin={"0px"} $alignItems={"center"}>
          {getSortingIcon(sortColumn, sortValue, sortAscending)}
        </Row>
      </ColumnTextStyle>
      <Row $padding={"0px"} $margin={"0px"} $alignItems={"center"}>
        <Tooltip
          text={
            "Emissions avoided where the CO2e multiplier per MWh is based on the annual carbon intensity of the grid at this site location. \n\nCFE is calculated based on hourly data as (# MWh contracted with InRange)  + (#MWh consumed from grid * proportion of regional grid CFE)) / (# MWh total demand)."
          }
        />
      </Row>
    </ColumnContainer>
  );
};

export const EnergyProcuredHeader = ({
  sortColumn,
  sortValue,
  sortAscending,
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Energy Procured
        {getSortingIcon(sortColumn, sortValue, sortAscending)}
      </ColumnTextStyle>
      <Tooltip text="The total annual energy procured from InRange for this site. When your half hourly data is submitted per site, this number becomes more accurate." />
    </ColumnContainer>
  );
};

export const NetworkAvailabilityHeader = ({
  sortColumn,
  sortValue,
  sortAscending,
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Network Availability
        {getSortingIcon(sortColumn, sortValue, sortAscending)}
      </ColumnTextStyle>
      <Tooltip
        text={
          "The projected network availability at the substation for this site.\nAvailable: Expected to have sufficient capacity for your estimated generation.\nUnavailable: Expected to not have sufficient capacity for your estimated generation.\nComing soon: Insufficient data, pending DNO engagement.\nSubject to change based on DNO capacity changes."
        }
      />
    </ColumnContainer>
  );
};

export const SiteStatusHeader = ({
  sortColumn,
  sortValue,
  sortAscending,
  displayInRangeScoreCol,
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle
        style={{
          width: `${(110 + (displayInRangeScoreCol ? 20 : 0)).toString()}px`,
        }}
      >
        {`Site status${displayInRangeScoreCol ? " / score" : ""}`}
        <Row $padding={"0px"} $margin={"0px"} $alignItems={"center"}>
          {getSortingIcon(sortColumn, sortValue, sortAscending)}
        </Row>
      </ColumnTextStyle>
      {displayInRangeScoreCol && (
        <Row $padding={"0px"} $margin={"0px"} $alignItems={"center"}>
          <Tooltip
            text={
              "InRange score is calculated based on a number of factors, including the site’s savings potential, the likelihood of sufficient network availability, proximity of other InRange sites, and the characteristics of other sites in your portfolio."
            }
          />
        </Row>
      )}
    </ColumnContainer>
  );
};

export const SiteHeader = ({ sortColumn, sortValue, sortAscending }) => {
  return (
    <ColumnTextStyle>
      Site
      {getSortingIcon(sortColumn, sortValue, sortAscending)}
    </ColumnTextStyle>
  );
};

export const DemandFulfillment = ({ sortColumn, sortValue, sortAscending }) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Demand Fulfillment
        {getSortingIcon(sortColumn, sortValue, sortAscending)}
      </ColumnTextStyle>
      <Tooltip text="The site’s percentage of energy demand that can be fulfilled by that site’s on-site generation, and the percentage imported from the InRange network." />
    </ColumnContainer>
  );
};
