import styled from "styled-components";

const AccountMenu = ({ userEmail }) => {
  return (
    <AccountMenuContainer>
      <UserMenuDropdown title={userEmail}>
        {userEmail.charAt(0).toUpperCase()}
      </UserMenuDropdown>
    </AccountMenuContainer>
  );
};

export default AccountMenu;

const AccountMenuContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const UserMenuDropdown = styled.p`
  width: 48px;
  height: 48px;
  margin: 0 9px;
  border-radius: 50%;
  cursor: pointer;
  line-height: 40px;
  text-align: center;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grey100};
  background: ${({ theme }) => theme.colors.primary};
  border: 4px solid ${({ theme }) => theme.colors.grey200};
`;
