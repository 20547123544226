import axiosConfig from "./axiosConfig";

const putOrganisation = async (authToken, orgId, update) => {
  const { data } = await axiosConfig.put(`/organisation/${orgId}`, update, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return data;
};

export default putOrganisation;
