import {
  NoPaddedRow,
  TableListSortFilters,
  isOrganizationInSiteScores,
} from "@inrange/theme-components";
import { TenantSiteListMetricCards } from "@inrange/theme-components/site";
import {
  Table,
  TenantSiteHeader,
  TenantSiteRow,
} from "@inrange/theme-components/table";
import { useEffect, useState } from "react";
import {
  defaultSortAscendingValues,
  filterSitesByText,
  sortByColumn,
  togglesortAscending,
} from "./SiteListSortMethods";

import { useContext } from "react";
import {
  calcAverageTariff,
  calcEmissionsAvoided,
  calcTotalAnnualDemand,
  calcTotalEnergyProcuredCost,
  calcTotalSavings,
} from "../../src/routes/dashboard/aggregationCalculations";
import { AppDataContext } from "../AppDataContext";

const calcLandlordFrequency = (sites) => {
  const landlords = {};
  for (const site of sites) {
    // Set a baseline of -1 (for sites with an unknown landlord)
    site.landlordCount = -1;

    for (const siteOwnership of site.siteOwnerships) {
      if (siteOwnership.ownership === "landlord") {
        if (landlords[siteOwnership.name]) {
          landlords[siteOwnership.name] += 1;
        } else {
          landlords[siteOwnership.name] = 1;
        }
      }
      if (siteOwnership.ownership === "ownerOccupier") {
        site.landlordCount = 0;
      }
    }
  }
  // Iterate back through the list to set the landlord count
  for (const site of sites) {
    for (const siteOwnership of site.siteOwnerships) {
      if (siteOwnership.ownership === "landlord") {
        site.landlordCount = landlords[siteOwnership.name];
      }
    }
  }

  return landlords;
};

const TenantSiteListView = ({ sites, organisation, ownershipFilter }) => {
  const [textFilter, setTextFilter] = useState("");
  const [sortAscending, setSortAscending] = useState(false);
  const { appData } = useContext(AppDataContext);
  const orgID = organisation.id;
  const displayInRangeScoreCol = isOrganizationInSiteScores(orgID);
  const allSitesNotStarted = sites.every(
    (site) => site.operationalStatus === "notStarted"
  );
  const [sortColumn, setSortColumn] = useState(
    displayInRangeScoreCol || !allSitesNotStarted
      ? "siteStatusTenant"
      : "networkAvailabilityTenant"
  );
  sortByColumn(sites, sortColumn, sortAscending, organisation); // Initial sort
  const [filteredSites, setFilteredSites] = useState(sites);

  // Required metrics
  const siteCount = sites.length;
  const totalAnnualDemand = calcTotalAnnualDemand(sites);
  const totalEnergyProcuredCost = calcTotalEnergyProcuredCost(sites);
  const emissionsAvoided = calcEmissionsAvoided(sites);
  const totalSavings = calcTotalSavings(sites, organisation);
  const averageTariff = calcAverageTariff(sites);

  // Chart data

  const metrics = {
    totalSites: siteCount,
    totalAnnualDemand,
    averageTariff,
    totalEnergyProcuredCost,
    emissionsAvoided,
    totalSavings,
    averageMarketTariff: appData.averageMarketTariff,
  };

  useEffect(() => {
    calcLandlordFrequency(sites);
  }, [sites]);

  // Sort and filter sites
  useEffect(() => {
    let listedSites = [...sites];

    // Text search based on name, address or tenant name
    if (textFilter.length > 2) {
      listedSites = filterSitesByText(sites, textFilter);
    }

    sortByColumn(listedSites, sortColumn, sortAscending, organisation);
    setFilteredSites(listedSites);
  }, [
    textFilter,
    ownershipFilter,
    sites,
    organisation,
    sortColumn,
    sortAscending,
  ]);

  const handleColumnClick = (columnKey) => {
    if (sortColumn === columnKey) {
      togglesortAscending(setSortAscending);
      return;
    }
    setSortColumn(columnKey);
    setSortAscending(defaultSortAscendingValues[columnKey]);
  };

  const tableHead = (
    <TenantSiteHeader
      displayInRangeScoreCol={displayInRangeScoreCol}
      allSitesNotStarted={allSitesNotStarted}
      sortColumn={sortColumn}
      sortAscending={sortAscending}
      handleColumnClick={handleColumnClick}
    />
  );
  const tableRows = filteredSites.map((site) => (
    <TenantSiteRow
      key={site.id}
      site={site}
      organisation={organisation}
      displayInRangeScoreCol={displayInRangeScoreCol}
      allSitesNotStarted={allSitesNotStarted}
    />
  ));

  return (
    <>
      <TenantSiteListMetricCards metrics={metrics} />
      <NoPaddedRow>
        <TableListSortFilters
          textFilter={textFilter}
          setTextFilter={setTextFilter}
        />
      </NoPaddedRow>
      <NoPaddedRow>
        <Table
          head={tableHead}
          rows={tableRows}
          id={"tenant-site-list-table"}
        />
      </NoPaddedRow>
    </>
  );
};

export default TenantSiteListView;
