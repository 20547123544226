import { ResponsiveBar } from "@nivo/bar";
import styled from "styled-components";
import { colors, theme } from ".";
import {
  findMinValue,
  formatKiloUnitsOnly,
  formatUnits,
} from "../../formatting/formatKiloUnits";
import {
  ChartToolTip,
  ChartToolTipBody,
  ChartToolTipHeader,
} from "../ChartTooltip";
import GenerationVsDemandMetricCard from "../GenerationVsDemandMetricCard";

import Tooltip from "../Tooltip";
import { Chart, ChartLegend, ChartWrapper } from "./common";

const keysToCheck = ["Consumption"];

const chartLegend = [
  {
    label: "Generation",
    color: colors[0],
  },
  {
    label: "Demand",
    color: colors[1],
  },
];

const ResponsiveBarChart = ({ chartData, indexBy, keys, theme }) => {
  return (
    <ChartContainer>
      <ResponsiveBar
        data={chartData}
        indexBy={indexBy}
        groupMode="grouped"
        keys={keys}
        margin={{
          left: 60,
          bottom: 40,
          top: 10,
        }}
        padding={0.3}
        colors={colors}
        colorBy={chartData.index}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisLeft={{
          tickSize: 0,
          tickPadding: 5,
          legendPosition: "middle",
          legendOffset: -40,
          legend: formatKiloUnitsOnly(
            findMinValue(chartData, keysToCheck),
            "Wh"
          ),
          fontColor: "red",
          color: "red",
          text: {
            fill: "red",
          },
          format: (value) => {
            return `${value}`;
          },
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 90,
          legendPosition: "middle",
          legendOffset: 32,
        }}
        theme={theme}
        label={false}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        tooltip={function (e) {
          return (
            <ChartToolTip>
              <ChartToolTipHeader>{e.data.time}</ChartToolTipHeader>
              <ChartToolTipBody>
                Generation:{" "}
                <strong>
                  {formatUnits(e.data["Generation"]?.toFixed(1), "kWh")}
                </strong>
              </ChartToolTipBody>
              <ChartToolTipBody>
                Consumption:{" "}
                <strong>
                  {formatUnits(e.data["Consumption"]?.toFixed(1), "kWh")}
                </strong>
              </ChartToolTipBody>
            </ChartToolTip>
          );
        }}
      />
    </ChartContainer>
  );
};

const GenerationVsDemandChart = ({
  chartData,
  indexBy,
  keys,
  totalAnnualGeneration,
  totalAnnualDemand,
  height,
  showMetrics = true,
}) => {
  return (
    <ChartWrapper height={height}>
      <Container>
        <MetricTooltip>
          <Tooltip
            text={
              "The energy generation of an on-site installation vs. the demand for that site, broken down on a half-hourly basis for a “typical” summer day.\n\nSubmitted half hourly demand data improves accuracy."
            }
          />
        </MetricTooltip>
        <Header>
          <Title>Generation vs. demand (Typical summer day)</Title>
          <ChartLegendContainer>
            <ChartLegend chartLegend={chartLegend} />
          </ChartLegendContainer>
        </Header>
        <Header></Header>
        <Chart>
          <ResponsiveBarChart
            chartData={chartData}
            indexBy={indexBy}
            keys={keys}
            theme={theme}
          />
        </Chart>
        {showMetrics && (
          <GenerationVsDemandMetricCard
            totalAnnualGeneration={totalAnnualGeneration}
            totalAnnualDemand={totalAnnualDemand}
          />
        )}
      </Container>
    </ChartWrapper>
  );
};

const MetricTooltip = styled.div`
  align-self: flex-end;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 16px;
  border-radius: 8px;
  padding-top: 0px;
`;

const Title = styled.span`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #00022f;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ChartContainer = styled.div`
  width: 100%;
  height: 295px;
  flex-grow: 1;
`;

const ChartLegendContainer = styled.div``;
export default GenerationVsDemandChart;
