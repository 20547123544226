import { useOrganisation, useSite } from "@inrange/building-manager-api-client";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AppDataContext } from "./AppDataContext";
import { calcAverageMarketTariff } from "./routes/dashboard/aggregationCalculations";

function AppData({ children }) {
  const { orgId, siteId } = useParams();
  const { updateAppData } = useContext(AppDataContext);
  const { fetchOrganisation } = useOrganisation(orgId, orgId);
  const organisation = fetchOrganisation.data?.organisation;
  const organisationSiteListSites = fetchOrganisation.data?.sites;

  // Delay fetching site data until organisation is loaded
  const { fetchSite } = useSite({
    siteId: organisation ? siteId : undefined,
    userOrgId: orgId,
  });
  const site = fetchSite.data?.site;

  const averageMarketTariffData = calcAverageMarketTariff(
    organisationSiteListSites || []
  );
  const averageMarketTariff = (averageMarketTariffData * 100).toFixed(2);

  useEffect(() => {
    updateAppData({
      organisation: organisation,
      site: site,
      averageMarketTariff: averageMarketTariff,
    });
  }, [updateAppData, averageMarketTariff, organisation, site]);

  return <div>{children}</div>;
}

export default AppData;
