import { trackUserInteraction } from "@inrange/building-manager-api-client/events";
import { useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { AppDataContext } from "./AppDataContext";
import { UserContext } from "./auth/UserContext";

function trackPageView(userEmail, location, organisationName, site) {
  if (!organisationName) return null; // dont log event if organisationName is not set yet.
  trackUserInteraction(
    {
      location: location.pathname,
      organisation_name: organisationName,
      site_id: site?.id,
      site_name: site?.name,
      site_operational_status: site?.operationalStatus,
    },
    "BASIC_ACTION",
    "USER_VISIT",
    userEmail?.toLowerCase() || "unknown",
    "customer-app"
  );
}

const SITE_INDEPENDENT_PAGES = new Set([
  "dashboard",
  "tenant",
  "ownerOccupier",
  "marketplace",
  "add-site",
]);

function TrackPageView({ children }) {
  const location = useLocation();
  const { user } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  const userEmail = user?.email;

  const organisationName = appData?.organisation?.name
    ? appData.organisation.name
    : undefined;

  const pathParts = location.pathname.split("/");
  const pageName = pathParts[pathParts.length - 1];
  let site = SITE_INDEPENDENT_PAGES.has(pageName) ? undefined : appData?.site;

  // Read location through a ref so that we only write to Slack when the pageName changes
  const locationRef = useRef(location);
  locationRef.current = location;
  useEffect(() => {
    if (SITE_INDEPENDENT_PAGES.has(pageName) || site) {
      // Log immediately for site-independent pages
      // For pages which expect to log the site name, wait until the site name is available
      trackPageView(userEmail, locationRef.current, organisationName, site);
    }
  }, [pageName, organisationName, site, userEmail]);

  return <div>{children}</div>;
}

export default TrackPageView;
