import { KINDE_ENVIRONMENT, KindeRedirectContext } from "@inrange/auth";
import { Loading } from "@inrange/theme-components";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminChooseOrgPage from "./AdminChooseOrgPage";
import { UserContext } from "./UserContext";

const LoginRedirect = () => {
  const navigate = useNavigate();
  const { redirectUrl } = useContext(KindeRedirectContext);
  const { user } = useContext(UserContext);
  const [showAdminLink, setShowAdminLink] = useState(false);

  useEffect(() => {
    if (navigate && redirectUrl && user) {
      const allowAllOrgs =
        user.kinde_org_code === KINDE_ENVIRONMENT.KINDE_INRANGE_ORG_CODE;
      if (redirectUrl.startsWith("/org/")) {
        if (redirectUrl.startsWith(`/org/${user.org_id}/`) || allowAllOrgs) {
          navigate(redirectUrl, { replace: true });
        } else if (user.org_id !== undefined) {
          navigate(`/org/${user.org_id}/dashboard`, {
            replace: true,
          });
        }
      } else {
        if (allowAllOrgs) {
          setShowAdminLink(true);
        } else {
          navigate(`/org/${user.org_id}/dashboard`, {
            replace: true,
          });
        }
      }
    }
  }, [navigate, redirectUrl, user]);

  if (showAdminLink) {
    return <AdminChooseOrgPage />;
  }

  return <Loading label="Logging in ..." />;
};

export default LoginRedirect;
