import styled from "styled-components";

export const ChartWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.$padding || "16px 0px 0px 0px"};
  margin: 0;
  background: #ffffff;
  border-radius: 8px;
  height: ${(props) => props.height || "400px"};
  flex: 1;
`;

export const ChartHeader = styled.div`
  max-width: 504px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #00022f;
  padding: 0 16px 16px;
  label {
    display: block;
    font-size: 12px;
  }
`;

export const ChartHeaderWithTabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 16px;
`;

export const Chart = styled.div`
  height: ${(props) => props.$height || "75%"};
  width: calc(100% - 16px);
`;
export const ChartLegendWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 16px 8px;
  flex-grow: 1;
`;
export const ChartLegendItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 16px;
  align-items: start;
`;

export const ChartLegendItemColor = styled.div`
  height: 8px;
  width: 16px;
  background-color: ${(props) => props.color};
  border-radius: 2;
  margin-top: 6px;
`;

export const ChartLegendItemLabel = styled.div`
  padding-left: 5px;
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #1c1c1c;
`;

export const ChartLegend = ({ chartLegend }) => {
  return (
    <ChartLegendWrapper>
      {chartLegend.map((legend, index) => (
        <ChartLegendItem key={index}>
          <ChartLegendItemColor color={legend.color}></ChartLegendItemColor>
          <ChartLegendItemLabel>{legend.label}</ChartLegendItemLabel>
        </ChartLegendItem>
      ))}
    </ChartLegendWrapper>
  );
};
