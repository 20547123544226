import styled from "styled-components";
import MetricsCard from "./MetricsCard";
import Tooltip from "./Tooltip";
import RadialChart from "./charts/RadialChart";

const AnnualCarbonSavingsHorizontal = ({
  emissionsAvoided,
  cfePeriodPercent,
}) => {
  const hoursPerYear = cfePeriodPercent * 366 * 24;
  return (
    <AnnualCarbonSavingsContainer>
      <MetricTooltip>
        <Tooltip
          text={
            "CFE is calculated based on hourly data as (# MWh contracted with InRange)  + (#MWh consumed from grid * proportion of regional grid CFE)) / (# MWh total demand).\n\nEmissions avoided in year 1 across all sites where the CO2e multiplier per MWh is based on the annual carbon intensity of the grid at each location."
          }
        />
      </MetricTooltip>
      <AnnualCarbonSavingsTitle>
        Carbon savings in year 1
      </AnnualCarbonSavingsTitle>
      <AnnualCarbonSavingsMain>
        <ChartContainer>
          <RadialChart
            emissionsAvoided={emissionsAvoided}
            cfePeriodPercent={cfePeriodPercent}
          />
        </ChartContainer>
        <AnnualCarbonSavingsDetails>
          <MetricsCard
            title="Carbon free energy (CFE)"
            data={(cfePeriodPercent * 100).toFixed(1)}
            dataUnit="%"
            arrow={null}
            label={`${hoursPerYear.toFixed(0)} hours per year`}
          />
          <MetricsCard
            title="Emissions avoided in year 1"
            data={emissionsAvoided}
            dataUnit=""
            arrow={null}
            label=""
          />
        </AnnualCarbonSavingsDetails>
      </AnnualCarbonSavingsMain>
    </AnnualCarbonSavingsContainer>
  );
};

const AnnualCarbonSavingsContainer = styled.div`
  display: flex
  flex-direction: column;
  width: 500px;
  height: 312px;
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
`;

const AnnualCarbonSavingsTitle = styled.span`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #00022f;
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 195px;
  height: 195px;
  flex-grow: 1;
`;

const AnnualCarbonSavingsMain = styled.div`
  display: flex;
  margin-top: 15px;
`;

const AnnualCarbonSavingsDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 10px;
`;

const MetricTooltip = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default AnnualCarbonSavingsHorizontal;
