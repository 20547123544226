import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useQueries, useQuery } from "react-query";
import getNetworkSite from "../http/getNetworkSite";
import getNetworkSiteList from "../http/getNetworkSiteList";

const useNetworkSiteList = (orgId, enabled, userOrgId) => {
  const { getToken, getClaim } = useKindeAuth();
  const kindeTokenOrgId = getClaim("external_org_id")?.value;
  userOrgId = kindeTokenOrgId === "inrange.io" ? userOrgId : undefined;

  const fetchNetworkSiteList = useQuery(
    ["org", orgId, "networksites"],
    async () => {
      return getNetworkSiteList(await getToken(), orgId, userOrgId);
    },
    { enabled, staleTime: 60_000 }
  );

  const chunk = (arr, chunkSize) => {
    const chunks = [];
    const tmp = [...arr];
    while (tmp.length) {
      chunks.push(tmp.splice(0, chunkSize));
    }
    return chunks;
  };

  const networkSites = useQueries(
    chunk(
      (fetchNetworkSiteList.data?.sites || []).map(
        (networkSite) => networkSite.id
      ),
      10
    )
      .map((networkSiteIdsChunk) => networkSiteIdsChunk.join(","))
      .map(
        (networkSiteIdsChunkStr) => {
          return {
            queryKey: ["org", orgId, "networksites", networkSiteIdsChunkStr],
            queryFn: async () =>
              getNetworkSite(await getToken(), orgId, networkSiteIdsChunkStr),
            staleTime: 5 * 60 * 1000,
          };
        },
        { enabled }
      )
  );

  return {
    fetchNetworkSiteList,
    networkSites,
  };
};

export default useNetworkSiteList;
