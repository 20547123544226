import { toNearestTen } from "@inrange/theme-components/formatting";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const genSiteGenerationConsumptionChartData = (site) => {
  const chartData = [];
  let hour = 0;
  let minute = 0;

  for (let ix = 0; ix < site.energyFlowSummerDay.generation.length; ix++) {
    const hourText = hour.toString().padStart(2, "0");
    const minuteText = minute.toString().padStart(2, "0");

    // For half hourly level data we round to nearest integer rather than nearest 10
    chartData.push({
      time: `${hourText}:${minuteText}`,
      Generation: Math.round(site.energyFlowSummerDay.generation[ix]),
      Consumption: Math.round(site.energyFlowSummerDay.demand[ix]),
    });

    if (minute === 30) {
      minute = 0;
      hour += 1;
    } else {
      minute += 30;
    }
  }
  return chartData;
};

const operationalSiteChartDataSortFunction = (a, b) => {
  const aSortDate = a.actualsMetadata?.first_day ?? "9999-12-31";
  const bSortDate = b.actualsMetadata?.first_day ?? "9999-12-31"; // if no actuals, sort to the end
  return new Date(aSortDate) - new Date(bSortDate);
};

export const getCostOfEnergyProcuredChartData = (site) => {
  // for months without data, we use energyFlowMonthly instead of energyFlowMonthlyActuals
  const forcastedEnergyFlow = site.energyFlowMonthly;
  const actualsEnergyFlow = site.energyFlowMonthlyActuals;

  const chartData = [];

  for (const [month, energyFlow] of Object.entries(forcastedEnergyFlow)) {
    const actualsExist = !!actualsEnergyFlow[month].metadata;
    const actualsNetwork = actualsExist
      ? actualsEnergyFlow[month].energyFlow.networkImport *
        site.networkImportTariff
      : 0;
    const actualsOnSite = actualsExist
      ? actualsEnergyFlow[month].energyFlow.behindMeter * site.tenantTariff
      : 0;
    const forecastedOnSite = actualsExist
      ? 0
      : energyFlow.behindMeter * site.tenantTariff;
    const forecastedNetwork = actualsExist
      ? 0
      : energyFlow.networkImport * site.networkImportTariff;
    const actualsMetadata = actualsExist
      ? actualsEnergyFlow[month].metadata
      : {};

    chartData.push({
      month: months[month - 1],
      "On-site behind the meter": actualsOnSite,
      "From InRange network": actualsNetwork,
      "Forecasted on-site": toNearestTen(forecastedOnSite),
      "Forecasted network": toNearestTen(forecastedNetwork),
      actualsMetadata: actualsMetadata,
    });
  }

  chartData.sort(operationalSiteChartDataSortFunction);

  return chartData;
};

export const getProposalCostOfEnergyProcuredChartData = (
  site,
  isOwnerOccupierLicense
) => {
  const forcastedEnergyFlow = site.energyFlowMonthly;

  const chartData = [];

  for (const [month, energyFlow] of Object.entries(forcastedEnergyFlow)) {
    const forecastedOnSite = energyFlow.behindMeter * site.tenantTariff;
    const forecastedNetworkImport =
      energyFlow.networkImport * site.networkImportTariff;

    const monthData = {
      month: months[month - 1],
      "From InRange network": toNearestTen(forecastedNetworkImport),
    };
    if (!isOwnerOccupierLicense) {
      monthData["On-site behind the meter"] = toNearestTen(forecastedOnSite);
    }
    chartData.push(monthData);
  }

  return chartData;
};

export const getAmountOfEnergyProcuredChartData = (site) => {
  const forcastedEnergyFlow = site.energyFlowMonthly;
  const actualsEnergyFlow = site.energyFlowMonthlyActuals;

  const chartData = [];

  for (const [month, energyFlow] of Object.entries(forcastedEnergyFlow)) {
    const actualsExist = !!actualsEnergyFlow[month].metadata;
    const actualsNetworkImport = actualsExist
      ? actualsEnergyFlow[month].energyFlow.networkImport
      : 0;
    const actualsOnSite = actualsExist
      ? actualsEnergyFlow[month].energyFlow.behindMeter
      : 0;
    const forecastedOnSite = actualsExist ? 0 : energyFlow.behindMeter;
    const forecastedNetworkImport = actualsExist ? 0 : energyFlow.networkImport;
    const actualsMetadata = actualsExist
      ? actualsEnergyFlow[month].metadata
      : {};

    chartData.push({
      month: months[month - 1],
      "On-site behind the meter": actualsOnSite,
      "From InRange network": actualsNetworkImport,
      "Forecasted on-site": toNearestTen(forecastedOnSite),
      "Forecasted network": toNearestTen(forecastedNetworkImport),
      actualsMetadata: actualsMetadata,
    });
  }

  chartData.sort(operationalSiteChartDataSortFunction);

  return chartData;
};

const getNetworkExportRevenue = (matchedEnergy, revenueShare) => {
  let forecastedNetwork = 0;
  for (const matchedEnergyItem of matchedEnergy) {
    if (matchedEnergyItem.sinkType === "networkExport") {
      forecastedNetwork +=
        parseFloat(matchedEnergyItem.energyFlow.toFixed(2)) *
        matchedEnergyItem.energyMatchConfig.tariff *
        revenueShare;
    }
  }
  return forecastedNetwork;
};

export const getProposalRevenueChartData = (
  site,
  investmentModel,
  isOwnerOccupierLicense
) => {
  const forcastedEnergyFlow = site.energyFlowMonthly;
  const revenueShare =
    site[
      `landlord${investmentModel === "lease" ? "Lease" : "License"}RevenueShare`
    ];

  const chartData = [];

  for (const [month, energyFlow] of Object.entries(forcastedEnergyFlow)) {
    const forecastedOnSite =
      energyFlow.behindMeter * site.tenantTariff * revenueShare;
    const forecastedNetworkExport =
      energyFlow.exported * site.inrangeExportTariff * revenueShare +
      getNetworkExportRevenue(energyFlow.matchedEnergy, revenueShare);
    const monthData = {
      month: months[month - 1],
      "Exported to network": toNearestTen(forecastedNetworkExport),
    };
    if (!isOwnerOccupierLicense) {
      monthData["On-site to tenant"] = toNearestTen(forecastedOnSite);
    }
    chartData.push(monthData);
  }

  return chartData;
};

export const getOperationalRevenueChartData = (site) => {
  const forcastedEnergyFlow = site.energyFlowMonthly;
  const actualsEnergyFlow = site.energyFlowMonthlyActuals;
  const revenueShare =
    site[
      `landlord${site.activeInvestmentModel === "lease" ? "Lease" : "License"}RevenueShare`
    ];

  const chartData = [];

  for (const [month, energyFlow] of Object.entries(forcastedEnergyFlow)) {
    const actualsExist = !!actualsEnergyFlow[month].metadata;
    const actualsNetworkExport = actualsExist
      ? actualsEnergyFlow[month].energyFlow.exported *
          site.inrangeExportTariff *
          revenueShare +
        getNetworkExportRevenue(
          actualsEnergyFlow[month].energyFlow.matchedEnergy,
          revenueShare
        )
      : 0;
    const actualsOnSite = actualsExist
      ? actualsEnergyFlow[month].energyFlow.behindMeter *
        site.tenantTariff *
        revenueShare
      : 0;
    const forecastedOnSite = actualsExist
      ? 0
      : energyFlow.behindMeter * site.tenantTariff * revenueShare;
    const forecastedNetworkExport = actualsExist
      ? 0
      : energyFlow.exported * site.inrangeExportTariff * revenueShare +
        getNetworkExportRevenue(energyFlow.matchedEnergy, revenueShare);
    const actualsMetadata = actualsExist
      ? actualsEnergyFlow[month].metadata
      : {};

    chartData.push({
      month: months[month - 1],
      "On-site behind the meter": actualsOnSite,
      "Exported to network": actualsNetworkExport,
      "Forecasted on-site": toNearestTen(forecastedOnSite),
      "Forecasted network": toNearestTen(forecastedNetworkExport),
      actualsMetadata: actualsMetadata,
    });
  }

  chartData.sort(operationalSiteChartDataSortFunction);

  return chartData;
};

export const getProposalEnergySoldChartData = (site) => {
  const forcastedEnergyFlow = site.energyFlowMonthly;

  const chartData = [];

  for (const [month, energyFlow] of Object.entries(forcastedEnergyFlow)) {
    chartData.push({
      month: months[month - 1],
      "On-site to tenant": toNearestTen(energyFlow.behindMeter),
      "Exported to network": toNearestTen(
        energyFlow.exported + energyFlow.networkExport
      ),
    });
  }

  return chartData;
};

export const getOperationalEnergySoldChartData = (site) => {
  const forcastedEnergyFlow = site.energyFlowMonthly;
  const actualsEnergyFlow = site.energyFlowMonthlyActuals;

  const chartData = [];

  for (const [month, energyFlow] of Object.entries(forcastedEnergyFlow)) {
    const actualsExist = !!actualsEnergyFlow[month].metadata;
    const actualsNetworkExport = actualsExist
      ? actualsEnergyFlow[month].energyFlow.exported +
        actualsEnergyFlow[month].energyFlow.networkExport
      : 0;
    const actualsOnSite = actualsExist
      ? actualsEnergyFlow[month].energyFlow.behindMeter
      : 0;
    const forecastedOnSite = actualsExist ? 0 : energyFlow.behindMeter;
    const forecastedNetworkExport = actualsExist
      ? 0
      : energyFlow.exported + energyFlow.networkExport;
    const actualsMetadata = actualsExist
      ? actualsEnergyFlow[month].metadata
      : {};

    chartData.push({
      month: months[month - 1],
      "On-site behind the meter": actualsOnSite,
      "Exported to network": actualsNetworkExport,
      "Forecasted on-site": toNearestTen(forecastedOnSite),
      "Forecasted network": toNearestTen(forecastedNetworkExport),
      actualsMetadata: actualsMetadata,
    });
  }

  chartData.sort(operationalSiteChartDataSortFunction);

  return chartData;
};

export const genEnergyProcuredChartData = (site, period) => {
  const isBatterySite = !!site.batteryCapacity && !!site.batteryPower;
  if (period === "year")
    return genEnergyProcuredChartDataYear(site, isBatterySite);
  return genEnergyProcuredChartDataDay(site, isBatterySite, period);
};

export const genEnergyProcuredChartDataDay = (site, isBatterySite, period) => {
  const chartData = [];

  let hour = 0;
  let minute = 0;

  for (const _ of site.energyFlowSummerDay.behindMeterPV) {
    const hourText = hour.toString().padStart(2, "0");
    const minuteText = minute.toString().padStart(2, "0");

    chartData.push({
      time: `${hourText}:${minuteText}`,
    });

    if (minute === 30) {
      minute = 0;
      hour += 1;
    } else {
      minute += 30;
    }
  }

  const energyFlowDay =
    period === "summer" ? site.energyFlowSummerDay : site.energyFlowWinterDay;
  for (let ix = 0; ix < energyFlowDay.behindMeterPV.length; ix++) {
    // For half hourly level data we round to nearest integer rather than nearest 10
    if (isBatterySite) {
      chartData[ix]["On-site direct from PV"] = Math.round(
        energyFlowDay.behindMeterPV[ix]
      );
      chartData[ix]["On-site from battery discharge"] = Math.round(
        energyFlowDay.behindMeterBattery[ix]
      );
    } else {
      chartData[ix]["On-site behind the meter"] = Math.round(
        energyFlowDay.behindMeterPV[ix]
      );
    }
    chartData[ix]["From InRange network"] = Math.round(
      energyFlowDay.networkImport[ix]
    );
    chartData[ix]["From other suppliers"] = Math.round(
      energyFlowDay.gridImport[ix]
    );
  }

  return chartData;
};

export const genEnergyProcuredChartDataYear = (site, isBatterySite) => {
  const oneMonthChartData = (monthIx) => {
    const chartData = {};
    const siteData = site.energyFlowMonthly[monthIx];
    if (isBatterySite) {
      chartData["On-site direct from PV"] = toNearestTen(
        siteData.behindMeterPV
      );
      chartData["On-site from battery discharge"] = toNearestTen(
        siteData.behindMeterBattery
      );
    } else {
      chartData["On-site behind the meter"] = toNearestTen(
        siteData.behindMeter
      );
    }
    chartData["From InRange network"] = toNearestTen(siteData.networkImport);
    chartData["From other suppliers"] = toNearestTen(siteData.gridImport);

    return chartData;
  };

  return [
    {
      month: "Jan",
      ...oneMonthChartData("1"),
    },
    {
      month: "Feb",
      ...oneMonthChartData("2"),
    },
    {
      month: "Mar",
      ...oneMonthChartData("3"),
    },
    {
      month: "Apr",
      ...oneMonthChartData("4"),
    },
    {
      month: "May",
      ...oneMonthChartData("5"),
    },
    {
      month: "Jun",
      ...oneMonthChartData("6"),
    },
    {
      month: "Jul",
      ...oneMonthChartData("7"),
    },
    {
      month: "Aug",
      ...oneMonthChartData("8"),
    },
    {
      month: "Sep",
      ...oneMonthChartData("9"),
    },
    {
      month: "Oct",
      ...oneMonthChartData("10"),
    },
    {
      month: "Nov",
      ...oneMonthChartData("11"),
    },
    {
      month: "Dec",
      ...oneMonthChartData("12"),
    },
  ];
};

export const genPeakOffPeakChartDataYear = (site) => {
  const oneMonthPeakOffPeakData = (monthIx) => {
    const chartData = {
      "Peak hour energy supplied from on-site generation": 0,
      "Peak hour energy supplied from InRange Network": 0,
      "Peak hour energy not supplied from InRange": 0,
      "Off-Peak hour energy not supplied from InRange": 0,
    };

    const siteData = site.energyFlowMonthly[monthIx];
    chartData["Peak hour energy supplied from on-site generation"] =
      toNearestTen(siteData.peakBehindMeter);
    chartData["Peak hour energy supplied from InRange Network"] = toNearestTen(
      siteData.peakInRange
    );
    chartData["Peak hour energy not supplied from InRange"] = toNearestTen(
      siteData.peakGrid
    );
    chartData["Off-Peak hour energy not supplied from InRange"] = toNearestTen(
      siteData.offPeakGrid
    );

    return chartData;
  };

  return [
    {
      month: "Jan",
      ...oneMonthPeakOffPeakData("1"),
    },
    {
      month: "Feb",
      ...oneMonthPeakOffPeakData("2"),
    },
    {
      month: "Mar",
      ...oneMonthPeakOffPeakData("3"),
    },
    {
      month: "Apr",
      ...oneMonthPeakOffPeakData("4"),
    },
    {
      month: "May",
      ...oneMonthPeakOffPeakData("5"),
    },
    {
      month: "Jun",
      ...oneMonthPeakOffPeakData("6"),
    },
    {
      month: "Jul",
      ...oneMonthPeakOffPeakData("7"),
    },
    {
      month: "Aug",
      ...oneMonthPeakOffPeakData("8"),
    },
    {
      month: "Sep",
      ...oneMonthPeakOffPeakData("9"),
    },
    {
      month: "Oct",
      ...oneMonthPeakOffPeakData("10"),
    },
    {
      month: "Nov",
      ...oneMonthPeakOffPeakData("11"),
    },
    {
      month: "Dec",
      ...oneMonthPeakOffPeakData("12"),
    },
  ];
};
