import styled from "styled-components";

const Card = ({ children, dark, blue, ...props }) => {
  return (
    <CardContainer dark={dark} blue={blue} props={props}>
      {children}
    </CardContainer>
  );
};

const CardContainer = styled.div`
  background: white;
  border-radius: 8px;
  margin: 16px 8px;
  padding: 24px 16px 40px 24px;
  ${({ props }) => props}}
  ${({ dark, theme }) =>
    dark && {
      backgroundColor: theme.colors.grey200,
    }}
  ${({ blue }) =>
    blue && {
      backgroundColor: "#00022F",
    }}
`;

export default Card;
