import styled from "styled-components";
import circleQuestionMarkTick from "../../images/icons/circleQuestionMarkTick.svg";
import NumericalHeaderWithSubheader from "../NumericalHeaderWithSubheader";
import SectionHeaderWithSubHeaderDark from "../SectionHeaderWithSubHeaderDark";
import Tooltip from "../Tooltip";

const BlueBoxMetric = ({
  header,
  subheader,
  metrics,
  paragraph,
  toolTipText,
}) => {
  const metricDisplays = metrics.map((metric, index) => {
    return (
      <NumericalHeaderWithSubheader
        numericalData={metric.value}
        textData={metric.valueSubText}
        key={index}
      />
    );
  });
  return (
    <BlueBoxMetricContainer>
      <Section1>
        <SectionHeaderWithSubHeaderDark
          mainHeader={header}
          subHeader={subheader}
          paragraph={paragraph}
        />
        {toolTipText && (
          <BlueBoxtip>
            <Tooltip text={toolTipText}>
              <img src={circleQuestionMarkTick} alt="info" />
            </Tooltip>
          </BlueBoxtip>
        )}
      </Section1>
      <Section2>{metricDisplays}</Section2>
    </BlueBoxMetricContainer>
  );
};

export default BlueBoxMetric;

const BlueBoxtip = styled.div`
  display: flex;
  align-self: flex-start;
  margin-right: 15px;
`;

const BlueBoxMetricContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 500px;
  height: 219px;
  background: #00022f;
  border-radius: 16px;
  flex-grow: 1;
`;

const Section1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  margin-top: 25px;
`;

const Section2 = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-top: 60px;
`;
