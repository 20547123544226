import styled from "styled-components";

export const FlexNoDefaults = styled.div`
  display: flex;
  ${(props) =>
    props.$justifyContent &&
    `
    justify-content: ${props.$justifyContent};
  `}
  ${(props) =>
    props.$alignItems &&
    `
    align-items: ${props.$alignItems};
  `}
  ${(props) =>
    props.$margin &&
    `
    margin: ${props.$margin};
  `}
  ${(props) =>
    props.$width &&
    `
    width: ${props.$width};
  `}
  ${(props) =>
    props.$height &&
    `
    height: ${props.$height};
  `}
`;

const Flex = styled(FlexNoDefaults)`
  ${(props) => `
    padding: ${props.$padding || "0 8px"};
    gap: ${props.$gap || "8px"};
    justify-content: ${props.$justifyContent || "flex-start"};
  `}
  ${(props) =>
    props.$height &&
    `
    height: ${props.$height};
  `}
`;

export const Column = styled(Flex)`
  flex-direction: column;
`;

export const ColumnNoDefaults = styled(FlexNoDefaults)`
  flex-direction: column;
`;

export const Row = styled(Flex)`
  flex-direction: row;
  ${(props) => `
    margin: ${props.$margin || "8px 16px 16px"};
  `}
`;

export const RowNoDefaults = styled(FlexNoDefaults)`
  flex-direction: row;
`;
