import axiosConfig from "./axiosConfig";

const postPreviewSite = async (authToken, payload) => {
  const { data } = await axiosConfig.post(`/site/preview`, payload, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return data;
};

export default postPreviewSite;
