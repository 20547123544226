import * as Sentry from "@sentry/react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import AppData from "./AppData";
import { AppDataProvider } from "./AppDataContext";
import LoginRedirect from "./auth/LoginRedirect";
import RedirectToDashboard from "./auth/RedirectToDashboard";
import Dashboard from "./routes/dashboard";
import ErrorElement from "./routes/ErrorElement";
import SiteView from "./routes/SiteView";
import TrackPageView from "./TrackPageView";
import TooNarrow from "./views/TooNarrow";

const routes = [
  {
    path: "/org/:orgId/dashboard",
    component: Dashboard,
  },
  {
    path: "/org/:orgId/marketplace",
    component: Dashboard,
  },
  {
    path: "/org/:orgId/sites/:siteType",
    component: Dashboard,
  },
  {
    path: "/org/:orgId/add-site",
    component: Dashboard,
  },
  {
    path: "/org/:orgId/site/:siteId",
    component: SiteView,
  },
  {
    path: "/org/:orgId/site/:siteId/:currentTab",
    component: SiteView,
  },
  {
    path: "/login-redirect",
    component: LoginRedirect,
  },
  {
    path: "*",
    component: RedirectToDashboard,
  },
];

const sentryCreateBrowserRouter = import.meta.env.PROD
  ? Sentry.wrapCreateBrowserRouter(createBrowserRouter)
  : createBrowserRouter;

const router = sentryCreateBrowserRouter(
  routes.map((route) => {
    if (route.path === "/login-redirect") {
      return {
        path: route.path,
        element: <route.component />,
      };
    }
    return {
      path: route.path,
      element: (
        <AppDataProvider>
          <AppData>
            <TrackPageView>
              <TooNarrow>
                <route.component />
              </TooNarrow>
            </TrackPageView>
          </AppData>
        </AppDataProvider>
      ),
      errorElement: <ErrorElement />,
    };
  })
);

const AppRoutes = () => {
  return <RouterProvider router={router} />;
};

export default AppRoutes;
