import { useRef } from "react";
import styled from "styled-components";
import clearAddressBar from "../../images/icons/clearAddressBar.svg";

const IconInput = ({
  icon,
  type,
  placeholder,
  value,
  onChange,
  onBlur,
  onFocus,
  width,
  height,
  padding,
  borderRadius,
  ellipsis,
  testId,
  ...props
}) => {
  const inputRef = useRef(null);
  return (
    <IconInputContainer
      $padding={padding}
      $width={width}
      $height={height}
      $borderRadius={borderRadius}
    >
      <IconInputIcon src={icon} />
      <IconInputBox
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        ref={inputRef}
        $ellipsis={ellipsis}
        data-testid={testId}
      />
      {props.clear && (
        <IconInputIcon
          src={clearAddressBar}
          onClick={() => (inputRef.current.value = "")}
          $hover={true}
        />
      )}
    </IconInputContainer>
  );
};

const IconInputContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${(props) => props.$padding || "10px 14px"};
  gap: 8px;
  width: ${(props) => props.$width};
  height: ${(props) => props.$height || "44px"};
  background-color: #ffffff;
  border: 1px solid ${({ theme }) => theme.colors.grey250};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: ${(props) => props.$borderRadius || "8px"};
  align-self: stretch;
`;

const IconInputIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: ${(props) => (props.$hover ? "pointer" : "default")};
`;

const IconInputBox = styled.input`
  width: 100%;
  height: 24px;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 14px;
  line-height: 24px;
  border: none;
  text-overflow: ${(props) => (props.$ellipsis ? "ellipsis" : "clip")};
  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  &::placeholder {
    color: rgb(191, 191, 191, 1);
  }
`;

export default IconInput;
