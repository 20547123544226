import axiosConfig from "./axiosConfig";

const getOrganisationUsers = async (authToken, orgId, siteId, userOrgId) => {
  let url = `/organisation/${orgId}/user${siteId ? `?siteId=${siteId}` : ""}`;
  if (userOrgId) {
    url += `${siteId ? "&" : "?"}userOrgId=${userOrgId}`;
  }
  const { data } = await axiosConfig.get(url, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return data;
};

export default getOrganisationUsers;
