import { ReactNode, createContext, useState } from "react";

interface KindeRedirectContextValue {
  redirectUrl?: string;
  setRedirectUrl?: (url: string) => void;
}

const KindeRedirectContext = createContext<KindeRedirectContextValue>({});

const KindeRedirectProvider = ({ children }: { children: ReactNode }) => {
  const [redirectUrl, setRedirectUrl] = useState<string | undefined>(undefined);

  return (
    <KindeRedirectContext.Provider value={{ redirectUrl, setRedirectUrl }}>
      {children}
    </KindeRedirectContext.Provider>
  );
};

export { KindeRedirectContext, KindeRedirectProvider };
