import axiosConfig from "./axiosConfig";

const postSiteMarketplaceMatch = async (authToken, siteId, match) => {
  const { data } = await axiosConfig.post(
    `/site/${siteId}/marketplace`,
    match,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return data;
};

export default postSiteMarketplaceMatch;
