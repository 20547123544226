import axiosConfig from "./axiosConfig";

const getRelatedOrganisationList = async (authToken, orgId, userOrgId) => {
  const { data } = await axiosConfig.get(
    `/organisation/${orgId}/related_organisations${
      userOrgId ? `?userOrgId=${userOrgId}` : ""
    }`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return data;
};

export default getRelatedOrganisationList;
