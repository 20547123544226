import styled from "styled-components";

const ChartToolTip = ({ children }) => {
  return <ChartToolTipContainer>{children}</ChartToolTipContainer>;
};

const ChartToolTipContainer = styled.div`
  padding: 0 12px;
  color: ${({ theme }) => theme.colors.dark};
  background-color: white;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.dark};
`;

const ChartToolTipHeader = styled.h3``;

const ChartToolTipBody = styled.p`
  ${(props) =>
    props.$fontSize &&
    `
  font-size: ${props.$fontSize};
`}
`;

export { ChartToolTip, ChartToolTipBody, ChartToolTipHeader };
