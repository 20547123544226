import { useSite, useSiteSdm } from "@inrange/building-manager-api-client";
import { trackUserInteraction } from "@inrange/building-manager-api-client/events";
import { sortBy } from "@inrange/calculations/utils";
import { Grid, Loading } from "@inrange/theme-components";
import {
  formatPercentage,
  formatUnitsToNearestTen,
  roundCurrency,
} from "@inrange/theme-components/formatting";
import { MarketplaceMap } from "@inrange/theme-components/mapping";
import { MarketplaceOffer } from "@inrange/theme-components/marketplace";
import { useContext, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDataContext } from "../../AppDataContext";
import { UserContext } from "../../auth/UserContext";
import calcSiteOwnershipForOrg from "../../utils/calcSiteOwnershipForOrg";
import { siteCanBuy, siteCanSell } from "./marketplace-utils";

const formatAvalability = (availabilityMonths) => {
  return availabilityMonths > 0
    ? `${availabilityMonths} ${availabilityMonths > 1 ? "months" : "month"}`
    : "Available";
};

const toBuyOffer = (site, buyOffer, ownership) => {
  const availabilityMonths = buyOffer.dest.availabilityMonths;
  const availabilityString = formatAvalability(availabilityMonths);

  return {
    sizePercent: formatPercentage(
      buyOffer.config.volume / site.energyFlowAnnual.gridImport
    ),
    values: [
      {
        main: formatUnitsToNearestTen(buyOffer.config.volume, "kWh"),
        secondary: `${formatPercentage(
          buyOffer.config.volume / site.energyFlowAnnual.gridImport
        )} of unmet demand`,
      },
      {
        main: `${(buyOffer.config.tariff * 100).toFixed(2)} p/kWh`,
        secondary: "excl. non-commodity costs",
      },
      {
        main: availabilityString,
        secondary: "From 1 site",
      },
      {
        main: roundCurrency(
          ownership === "ownerOccupier"
            ? buyOffer.financialModels[ownership][site.activeInvestmentModel]
                .savings
            : buyOffer.financialModels[ownership].savings
        ),
        secondary: roundCurrency(
          ownership === "ownerOccupier"
            ? site.financialModels[ownership][site.activeInvestmentModel]
                .savings
            : site.financialModels[ownership].savings
        ),
        format: "currency",
      },
      {
        main:
          (buyOffer.config.volume +
            site.energyFlowAnnual.behindMeter +
            site.energyFlowAnnual.networkImport) /
          site.energyFlowAnnual.demand,
        secondary:
          (site.energyFlowAnnual.behindMeter +
            site.energyFlowAnnual.networkImport) /
          site.energyFlowAnnual.demand,
        format: "percent",
      },
      {
        main: buyOffer.emissionsAvoidedYearOne,
        mainSuffix: " CO2e",
        secondary: site.calculations.emissionsAvoided.totalAvoidance,
        format: "mass",
      },
    ],
  };
};

const toSellOffer = (site, sellOffer, ownership) => {
  const availabilityMonths = sellOffer.dest.availabilityMonths;
  const availabilityString = formatAvalability(availabilityMonths);

  return {
    sizePercent: formatPercentage(
      sellOffer.config.volume / site.energyFlowAnnual.exported
    ),
    values: [
      {
        main: formatUnitsToNearestTen(sellOffer.config.volume, "kWh"),
        secondary: `${formatPercentage(
          sellOffer.config.volume / site.energyFlowAnnual.exported
        )} of excess generation`,
      },
      {
        main: `${(sellOffer.config.tariff * 100).toFixed(2)} p/kWh`,
        secondary: "As generated solar",
      },
      {
        main: availabilityString,
        secondary: "From 1 site",
      },
      {
        main: roundCurrency(
          sellOffer.financialModels[ownership][site.activeInvestmentModel]
            .revenue
        ),
        secondary: roundCurrency(
          site.financialModels[ownership][site.activeInvestmentModel].revenue
        ),
        format: "currency",
      },
      {
        main: sellOffer.financialModels[ownership][site.activeInvestmentModel]
          .lifetimeRevenue,
        secondary:
          site.financialModels[ownership][site.activeInvestmentModel]
            .lifetimeRevenue,
        format: "currency",
      },
      {
        main: sellOffer.financialModels[ownership].license.irr,
        secondary: site.financialModels[ownership].license.irr,
        format: "percent",
      },
    ],
  };
};

const offerToLogEventDetails = (offerType, offer) => {
  if (!offer) {
    return undefined;
  }
  if (offerType === "buy") {
    return {
      volume: offer.values[0].main,
      tariff: offer.values[1].main,
      availability: offer.values[2].main,
      sites: offer.values[2].secondary,
      savingsy1_new: offer.values[3].main,
      savingsy1_old: offer.values[3].secondary,
      demandpct_new: offer.values[4].main,
      demandpct_old: offer.values[4].secondary,
      co2_new: offer.values[5].main,
      co2_old: offer.values[5].secondary,
    };
  }
  return {
    volume: offer.values[0].main,
    tariff: offer.values[1].main,
    availability: offer.values[2].main,
    sites: offer.values[2].secondary,
    revenuey1_new: offer.values[3].main,
    revenuey1_old: offer.values[3].secondary,
    irr_new: offer.values[5].main,
    irr_old: offer.values[5].secondary,
  };
};

// eslint-disable-next-line no-unused-vars
const Marketplace = ({ sites, networkSites, rootPath }) => {
  const { user } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const offerType = params.get("offerType") || "buy";
  let siteId = params.get("siteId") || undefined;
  const { fetchSite } = useSite({ siteId, userOrgId: appData.organisation.id });
  const site = fetchSite.data?.site;

  const ownership =
    site && appData.organisation
      ? calcSiteOwnershipForOrg(site, appData.organisation)
      : undefined;
  // Treat "none" as "license"
  const canShowBuyOffer =
    offerType === "buy" && siteCanBuy(appData.organisation, site);
  const canShowSellOffer =
    offerType === "sell" && siteCanSell(appData.organisation, site);
  if (siteId && !canShowBuyOffer && !canShowSellOffer) {
    // We're trying to render for a site that we can't show the selected energy offer for, so act like
    // no site is selected
    siteId = undefined;
  }
  const siteSelected = !!siteId;
  const { fetchSiteSdmList, bestBuyOffer, bestSellOffer, buyOfferEnergyFlows } =
    useSiteSdm({
      siteId,
      getAllResults: false,
      onlyLinkedOrgs: !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE,
      userOrgId: appData.organisation?.id,
    });

  const sitesById = useMemo(() => {
    return sites.reduce((acc, site) => {
      acc[site.id] = site;
      return acc;
    }, {});
  }, [sites]);

  const sortedSitesCanBuyOrSell = sites
    .filter((site) => {
      return (
        siteCanBuy(appData.organisation, site) ||
        siteCanSell(appData.organisation, site)
      );
    })
    .sort(sortBy((site) => site.name));

  const handleSiteIdChange = (newSiteId) => {
    if (newSiteId === undefined) {
      navigate(`${rootPath}marketplace`, { replace: true });
    } else {
      const newSite = sitesById[newSiteId];
      const canBuy = siteCanBuy(appData.organisation, newSite);
      const canSell = siteCanSell(appData.organisation, newSite);
      // When switching between sites in the dropdown, we always prefer to show a sell offer
      // unless the site can't sell
      const newOfferType = canBuy && !canSell ? "buy" : "sell";
      navigate(
        `${rootPath}marketplace?siteId=${newSiteId}&offerType=${newOfferType}`,
        { replace: true }
      );
    }
  };

  const [inputSdmOffer, offer] = useMemo(() => {
    if (canShowBuyOffer && bestBuyOffer) {
      return [bestBuyOffer, toBuyOffer(site, bestBuyOffer, ownership)];
    } else if (canShowSellOffer && bestSellOffer) {
      return [bestSellOffer, toSellOffer(site, bestSellOffer, ownership)];
    }
    return [undefined, undefined];
  }, [
    site,
    ownership,
    canShowBuyOffer,
    bestBuyOffer,
    canShowSellOffer,
    bestSellOffer,
  ]);

  const [mySitesOnMap, networkSitesOnMap] = useMemo(() => {
    // Default to showing all the user's sites and all the network sites
    let updatedMySitesOnMap = sites;
    let updatedNetworkSitesOnMap = networkSites;

    // However, if the user has selected a site, then show the sites relevant to the offer for that site
    if (site && (canShowBuyOffer || canShowSellOffer)) {
      updatedMySitesOnMap = [site];
      updatedNetworkSitesOnMap = [];
      if (canShowBuyOffer) {
        const buySiteId = bestBuyOffer?.dest?.id;
        // If we haven't loaded the buy offer yet, or there isn't one available, this code will select no sites
        if (sitesById[buySiteId] !== undefined) {
          updatedNetworkSitesOnMap = [sitesById[buySiteId]];
        } else {
          updatedNetworkSitesOnMap = networkSites.filter(
            (s) => s.id === buySiteId
          );
        }
      }
      if (canShowSellOffer) {
        const sellSiteId = bestSellOffer?.dest?.id;
        // If we haven't loaded the sell offer yet, or there isn't one available, this code will select no sites
        if (sitesById[sellSiteId] !== undefined) {
          updatedNetworkSitesOnMap = [sitesById[sellSiteId]];
        } else {
          updatedNetworkSitesOnMap = networkSites.filter(
            (s) => s.id === sellSiteId
          );
        }
      }
    }
    return [updatedMySitesOnMap, updatedNetworkSitesOnMap];
  }, [
    sites,
    networkSites,
    sitesById,
    site,
    canShowBuyOffer,
    canShowSellOffer,
    bestBuyOffer,
    bestSellOffer,
  ]);

  useEffect(() => {
    if (offer && site && appData.organisation) {
      trackUserInteraction(
        {
          organisation_name: appData.organisation.name,
          site_id: site.id,
          site_name: site.name,
          site_operational_status: site.operationalStatus,
          ownership: ownership,
          investment_model: site.activeInvestmentModel,
          offer_type: offerType,
          sdm_match: inputSdmOffer,
          offer_values: offer,
          offer_log_values: offerToLogEventDetails(offerType, offer),
        },
        "ENERGY_OFFER",
        "ENERGY_OFFER_SEEN",
        user.email.toLowerCase(),
        "customer-app"
      );
    }
    if (!fetchSiteSdmList.isLoading && !offer && site && appData.organisation) {
      trackUserInteraction(
        {
          organisation_name: appData.organisation.name,
          site_id: site.id,
          site_name: site.name,
          site_operational_status: site.operationalStatus,
          offer_type: offerType,
          offer_values: offer,
          offer_log_values: offerToLogEventDetails(offerType, offer),
        },
        "ENERGY_OFFER",
        "ENERGY_OFFER_SEEN",
        user.email.toLowerCase(),
        "customer-app"
      );
    }
  }, [
    user.email,
    appData.organisation,
    site,
    ownership,
    offerType,
    inputSdmOffer,
    offer,
    fetchSiteSdmList.isLoading,
  ]);

  if (fetchSite.isLoading) {
    return <Loading label="Loading your site data..." />;
  }

  return (
    <Grid $cols={2} $colwidth={"1fr"} $gap={"5px"} $margin={"5px 0px 0px 0px"}>
      <MarketplaceMap
        width="100%"
        height="750px"
        mySites={mySitesOnMap}
        networkSites={networkSitesOnMap}
        orgID={appData.organisation?.id}
        onSiteClick={(clickedSite) => {
          trackUserInteraction(
            {
              organisation_name: appData.organisation.name,
              site_id: clickedSite.id,
              site_name: clickedSite.name,
              site_operational_status: clickedSite.operationalStatus,
              offer_type: siteSelected
                ? params.get("offerType") || undefined
                : undefined,
            },
            "ENERGY_OFFER",
            "SITE_CLICKED",
            user.email.toLowerCase(),
            "customer-app"
          );
        }}
        isShowingSpecificOffer={offer !== undefined}
        offerType={offerType}
        offer={offer}
      />
      <MarketplaceOffer
        siteId={siteId}
        userEmail={user.email}
        sites={sortedSitesCanBuyOrSell}
        mySite={offer ? mySitesOnMap[0] : undefined}
        networkSite={offer ? networkSitesOnMap[0] : undefined}
        setSelectedSiteIdFn={handleSiteIdChange}
        addSiteUrl={`${rootPath}add-site`}
        notifyAddSiteFn={() => {
          trackUserInteraction(
            {
              from_page: "marketplace",
              site_name: site?.name,
            },
            "ADD_SITE",
            "ADD_SITE_ENTERED",
            user.email.toLowerCase(),
            "customer-app"
          );
        }}
        type={offerType}
        volume={
          site
            ? offerType == "buy"
              ? formatUnitsToNearestTen(site.energyFlowAnnual.gridImport, "kWh")
              : formatUnitsToNearestTen(site.energyFlowAnnual.exported, "kWh")
            : undefined
        }
        isLoading={siteSelected && fetchSiteSdmList.isLoading}
        offer={offer}
        buyOfferEnergyFlows={buyOfferEnergyFlows}
        acceptOfferFn={() => {
          if (offer) {
            trackUserInteraction(
              {
                organisation_name: appData.organisation.name,
                site_id: site.id,
                site_name: site.name,
                site_operational_status: site.operationalStatus,
                ownership: ownership,
                investment_model: site.activeInvestmentModel,
                offer_type: offerType,
                sdm_match: inputSdmOffer,
                offer_values: offer,
                offer_log_values: offerToLogEventDetails(offerType, offer),
              },
              "ENERGY_OFFER",
              "ENERGY_OFFER_REQUESTED",
              user.email.toLowerCase(),
              "customer-app"
            );
          } else {
            trackUserInteraction(
              {
                organisation_name: appData.organisation.name,
                site_id: site.id,
                site_name: site.name,
                site_operational_status: site.operationalStatus,
                ownership: ownership,
                investment_model: site.activeInvestmentModel,
                offer_type: offerType,
              },
              "ENERGY_OFFER",
              "ENERGY_OFFER_FIND_MATCH_REQUESTED",
              user.email.toLowerCase(),
              "customer-app"
            );
          }
        }}
      />
    </Grid>
  );
};

export default Marketplace;
