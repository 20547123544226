import styled from "styled-components";
import { formatKiloUnitsWithValue } from "../formatting/formatKiloUnits";

const GenerationVsDemandMetricCard = ({
  totalAnnualGeneration,
  totalAnnualDemand,
}) => {
  return (
    <MetricsRowContainer>
      <MetricCardContainer>
        <MetricsHeader>
          <MetricTitle>Total generation in year 1</MetricTitle>
          <MetricIcon></MetricIcon>
        </MetricsHeader>
        <MetricValue>
          {formatKiloUnitsWithValue(totalAnnualGeneration, "Wh")}
        </MetricValue>
        <MetricNote></MetricNote>
      </MetricCardContainer>
      <MetricCardContainer>
        <MetricsHeader>
          <MetricTitle>Total demand in year 1</MetricTitle>
        </MetricsHeader>
        <MetricValue>
          {formatKiloUnitsWithValue(totalAnnualDemand, "Wh")}
        </MetricValue>
        <MetricNote></MetricNote>
      </MetricCardContainer>
    </MetricsRowContainer>
  );
};

const MetricsRowContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
`;

const MetricCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 237px;
  height: 112px;
  margin: 5px;
  background: rgba(223, 223, 223, 0.5);
  border-radius: 16px;
  padding-left: 16px;
  padding-right: 16px;
`;

const MetricsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const MetricIcon = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const MetricTitle = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #1c1c1c;
`;

const MetricValue = styled.p`
  margin: 16px 0 0;
  letter-spacing: 0.01em;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: #1c1c1c;
`;

const MetricNote = styled.p`
  margin: 8px 0 0;
  font-size: 12px;
  color: #9f9f9f;
`;

export default GenerationVsDemandMetricCard;
