import { colors } from ".";
import { formatUnitsToNearestTen } from "../../formatting/formatKiloUnits";
import {
  ChartToolTip,
  ChartToolTipBody,
  ChartToolTipHeader,
} from "../ChartTooltip";
import BarChart from "./BarChart";
import {
  Chart,
  ChartHeader,
  ChartHeaderWithTabs,
  ChartLegend,
  ChartWrapper,
} from "./common";

export const showPeakOffPeakTooltip = (e) => {
  return (
    <ChartToolTip>
      <ChartToolTipHeader>{e.data.time}</ChartToolTipHeader>
      <ToolTipData data={e.data} />
    </ChartToolTip>
  );
};

const ToolTipData = ({ data }) => {
  return (
    <>
      <ChartToolTipBody>
        Peak - from on-site:{" "}
        <strong>
          {formatUnitsToNearestTen(
            data["Peak hour energy supplied from on-site generation"],
            "kWh"
          )}
        </strong>
      </ChartToolTipBody>
      <ChartToolTipBody>
        Peak - from InRange Network:{" "}
        <strong>
          {formatUnitsToNearestTen(
            data["Peak hour energy supplied from InRange Network"],
            "kWh"
          )}
        </strong>
      </ChartToolTipBody>
      <ChartToolTipBody>
        Peak - from other suppliers:{" "}
        <strong>
          {formatUnitsToNearestTen(
            data["Peak hour energy not supplied from InRange"],
            "kWh"
          )}
        </strong>
      </ChartToolTipBody>
      <ChartToolTipBody>
        Off-peak - from other suppliers:{" "}
        <strong>
          {formatUnitsToNearestTen(
            data["Off-Peak hour energy not supplied from InRange"],
            "kWh"
          )}
        </strong>
      </ChartToolTipBody>
    </>
  );
};

export const energyProcuredChartLegend = [
  {
    label: "Peak hour energy supplied from on-site generation",
    color: colors[0],
  },
  {
    label: "Peak hour energy supplied from InRange Network",
    color: colors[1],
  },
  {
    label: "Peak hour energy supplied from other suppliers",
    color: colors[2],
  },
  {
    label: "Off-peak hour energy supplied from other suppliers",
    color: colors[3],
  },
];

const keys = [
  "Peak hour energy supplied from on-site generation",
  "Peak hour energy supplied from InRange Network",
  "Peak hour energy not supplied from InRange",
  "Off-Peak hour energy not supplied from InRange",
];

const PeakOffPeakChart = ({
  chartData,
  toolTipFn,
  indexBy,
  totalOnSiteGeneration,
  isGeneratingSite,
  ...props
}) => {
  const chartKeys = isGeneratingSite ? keys : keys.slice(1);
  const chartLegend = isGeneratingSite
    ? energyProcuredChartLegend
    : energyProcuredChartLegend.slice(1);
  const chartColors = isGeneratingSite ? colors : colors.slice(1);

  const subHeader = isGeneratingSite ? (
    <ChartHeader>
      {formatUnitsToNearestTen(totalOnSiteGeneration, "kWh")}
      <label>Total on-site generation by this site in year 1</label>
    </ChartHeader>
  ) : null;
  return (
    <ChartWrapper height={props.height}>
      <ChartHeaderWithTabs>
        <div>
          <ChartHeader>Energy supply by hours and source</ChartHeader>
          {subHeader}
        </div>
      </ChartHeaderWithTabs>
      <Chart>
        <ChartLegend chartLegend={chartLegend} />
        <BarChart
          chartData={chartData}
          tooltipFn={toolTipFn}
          indexBy={indexBy}
          keys={chartKeys}
          barColors={chartColors}
        />
      </Chart>
    </ChartWrapper>
  );
};

export default PeakOffPeakChart;
