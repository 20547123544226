import { createContext, useMemo, useState } from "react";

export const AppDataContext = createContext({
  appData: undefined,
  updateAppData: undefined,
});

export const AppDataProvider = ({ children }) => {
  const [appData, setAppData] = useState({});

  const updateAppData = useMemo(
    () => (data) => {
      setAppData({
        ...data,
      });
    },
    [setAppData]
  );

  return (
    <AppDataContext.Provider
      value={{
        appData,
        updateAppData,
      }}
    >
      {children}
    </AppDataContext.Provider>
  );
};
