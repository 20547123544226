const DEV_KINDE_ENV = {
  KINDE_INRANGE_ORG_CODE: "org_930bea1210ea",
  KINDE_AUTH_DOMAIN: "auth.inrange.dev",
  KINDE_MANAGEMENT_DOMAIN: "inrange-development.eu.kinde.com",
  KINDE_ADMIN_APP_CLIENT_ID: "14d120a38cb54d508d9ec8b0a289fc74",
  KINDE_CUSTOMER_APP_CLIENT_ID: "f220f81b0c8e47ebbc7e22e5a70876dc",
};

const PROD_KINDE_ENV = {
  KINDE_INRANGE_ORG_CODE: "org_4dd2d1370b1",
  KINDE_AUTH_DOMAIN: "auth.inrange.io",
  KINDE_MANAGEMENT_DOMAIN: "inrange.kinde.com",
  KINDE_ADMIN_APP_CLIENT_ID: "eea0f67423f0443681627d5bc81fcc10",
  KINDE_CUSTOMER_APP_CLIENT_ID: "e5f80c46a36549cd80da9c2c38d63168",
};

export const KINDE_ENVIRONMENT =
  import.meta.env.VITE_KINDE_AUTH_ENV === "PROD"
    ? PROD_KINDE_ENV
    : DEV_KINDE_ENV;
