const calcIsOwnerOccupied = (site, organisation) => {
  if (!site || !organisation) {
    return undefined;
  }
  const ownership = site.siteOwnerships.find(
    (siteOwnerhip) => siteOwnerhip.orgID === organisation.id
  )?.ownership;
  const isOwnerOccupier = ownership === "ownerOccupier";
  return isOwnerOccupier;
};

export default calcIsOwnerOccupied;
