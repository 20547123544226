import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useQuery } from "react-query";
import getRelatedOrganisationList from "../http/getRelatedOrganisationList";

const useRelatedOrganisationList = (orgId, userOrgId) => {
  const { getToken, getClaim } = useKindeAuth();
  const kindeTokenOrgId = getClaim("external_org_id")?.value;
  userOrgId = kindeTokenOrgId === "inrange.io" ? userOrgId : undefined;

  const fetchRelatedOrganisations = useQuery(
    ["relatedOrganisations", orgId],
    async () => {
      return getRelatedOrganisationList(await getToken(), orgId, userOrgId);
    },
    { enabled: !!orgId }
  );

  return {
    fetchRelatedOrganisations,
  };
};

export default useRelatedOrganisationList;
