import axiosConfig from "./axiosConfig";

const postOrganisationUser = async (authToken, orgId, payload, userOrgId) => {
  const { data } = await axiosConfig.post(
    `/organisation/${orgId}/user${userOrgId ? `?userOrgId=${userOrgId}` : ""}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return data;
};

export default postOrganisationUser;
