import styled from "styled-components";
import formatCurrency from "../../formatting/formatCurrency";
import { formatUnits } from "../../formatting/formatKiloUnits";
import {
  ChartToolTip,
  ChartToolTipBody,
  ChartToolTipHeader,
} from "../ChartTooltip";
import Tooltip from "../Tooltip";
import { Column } from "../layout/Flex";
import BarChart from "./BarChart";
import { Chart, ChartHeader, ChartLegend, ChartWrapper } from "./common";

const colors = ["#00022F", "#2779A7", "#2A2C76"];
const nonGeneratingColors = ["#2779A7", "#2A2C76"];

const ProposalSiteChart = ({
  header,
  chartData,
  toolTipText,
  currency,
  keys,
  isGeneratingSite,
  isProcuringSite,
  buyEnergyBlock,
  height,
  barColors,
}) => {
  if (!barColors) {
    barColors = isGeneratingSite ? colors : nonGeneratingColors;
  }
  const chartLegend = keys.map((key, index) => ({
    label: key,
    color: barColors[index],
  }));

  return (
    <ChartWrapper height={height} $padding={"15px 0 0 0"}>
      <ProposalChartHeader>
        <ChartHeader>{header}</ChartHeader>
        <Tooltip text={toolTipText} position={"bottomLeft"} />
      </ProposalChartHeader>
      <Chart>
        <ChartLegend chartLegend={chartLegend} />
        <BarChart
          chartData={chartData}
          tooltipFn={(e) => showProposalSiteChartTooltip(e, keys, currency)}
          indexBy="month"
          keys={keys}
          paddingProp={0.3}
          tickRotationProp={0}
          barColors={barColors.slice(0, keys.length)}
          stacked={true}
          legend={currency ? "£" : "kWh"}
        />
        {!isProcuringSite && (
          <NonGeneratingSiteOverlay>
            <Column style={{ maxWidth: "570px" }}>
              <span
                style={{
                  fontSize: "30px",
                  color: "grey",
                  margin: "0 15px 15px 15px",
                  padding: "0 8px",
                }}
              >
                Your site is not yet procuring energy.
              </span>
              {buyEnergyBlock}
            </Column>
          </NonGeneratingSiteOverlay>
        )}
      </Chart>
    </ChartWrapper>
  );
};

export default ProposalSiteChart;

const showProposalSiteChartTooltip = (e, keys, currency) => {
  const headerText = e.data["month"];
  return (
    <ChartToolTip>
      <ChartToolTipHeader>{headerText}</ChartToolTipHeader>
      <MonthlyTooltip data={e.data} keys={keys} currency={currency} />
    </ChartToolTip>
  );
};

const MonthlyTooltip = ({ data, keys, currency }) => {
  const formatValue = (key) => {
    const value = data[key] !== undefined ? Math.round(data[key] / 10) * 10 : 0;
    return (
      <strong>
        {currency ? formatCurrency(value, "GBP", 0) : formatUnits(value, "kWh")}
      </strong>
    );
  };

  return (
    <>
      {keys.map((key) => (
        <ChartToolTipBody key={key}>
          <span>{key + ": "}</span>
          {formatValue(key)}
        </ChartToolTipBody>
      ))}
    </>
  );
};

const ProposalChartHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 16px;
`;

// Overlay for non generating sites to be displayed on top of the chart
const NonGeneratingSiteOverlay = styled(Column)`
  position: absolute;
  top: 0;
  left: 15%;
  width: 70%;
  height: 100%;
  background-color: transparent;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
