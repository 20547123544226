import styled from "styled-components";

const NumericalHeaderWithSubheader = ({
  numericalData,
  textData,
  dataTestId,
  textAlign,
  fontSize,
}) => {
  return (
    <IndividualDataContainer>
      <NumericalData
        $textAlign={textAlign}
        $fontSize={fontSize}
        data-testid={dataTestId}
      >
        {numericalData}
      </NumericalData>
      {textData && (
        <TextData $textAlign={textAlign}>
          {textData instanceof Array
            ? textData.map((v, index) => <div key={index}>{v}</div>)
            : textData}
        </TextData>
      )}
    </IndividualDataContainer>
  );
};

const IndividualDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  text: center;
  width: fit-content;
`;
const NumericalData = styled.span`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 550;
  font-size: ${({ $fontSize }) => $fontSize || "25px"};
  line-height: 28px;
  text-align: ${({ $textAlign }) => $textAlign || "center"};
  letter-spacing: 0.003em;
  color: white;
`;
const TextData = styled.span`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 34px;
  text-align: ${({ $textAlign }) => $textAlign || "center"};
  letter-spacing: 0.003em;
  white-space: pre-line;
  color: white;
  line-height: 20px;
`;

export default NumericalHeaderWithSubheader;
