import axiosConfig from "./axiosConfig";

const getNetworkSite = async (authToken, orgId, networkSiteIdsChunkStr) => {
  const { data } = await axiosConfig.get(
    `/organisation/${orgId}/network_site?siteIds=${networkSiteIdsChunkStr}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return data;
};

export default getNetworkSite;
