import { ErrorSidebar, Loaded } from "@inrange/theme-components";
import { useContext } from "react";
import getAdminAppDomain from "../utils/getAdminAppDomain";
import { UserContext } from "./UserContext";

const AdminChooseOrgPage = () => {
  const { user, logout } = useContext(UserContext);

  return (
    <ErrorSidebar onClickLogout={logout}>
      <Loaded>
        <div>Welcome {user?.email}. </div>
        <div>
          Please pick an organisation in the{" "}
          <a href={getAdminAppDomain()}>Admin Site</a>.
        </div>
      </Loaded>
    </ErrorSidebar>
  );
};

export default AdminChooseOrgPage;
