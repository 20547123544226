import hostnameMap from "./hostnames.json";

// Allow the setting of a backend API endpoint
// without the danger of accidentally deploying to production with the wrong one.

// When deploying in a production environment (and NODE_ENV=production is set in QA deployments),
// then the hostname will be set to the matching object in hostnames.cfg:

// You can add to hostnames.json with an object like this:
// {
//   "id-branch-name": {
//     "hostname": "https://api.example.com"
//   }
// }
// and then when deployed to QA that backend will be used.

// If you don't add a hostname for a branch, it will use the default hostname
// which is already set in hostnames.cfg

// When deploying to AWS where NODE_ENV=production and VITE_BRANCH_NAME=main,
// then the production API will be used.

export const getApiHostname = () => {
  const nodeEnvProduction = import.meta.env?.PROD;
  const branchName = import.meta.env?.VITE_BRANCH_NAME || undefined;
  if (nodeEnvProduction && branchName === "main") {
    return hostnameMap["production"].hostname;
  }

  const forceApiHostname =
    import.meta.env?.VITE_FORCE_API_HOSTNAME || undefined;
  if (forceApiHostname) {
    return forceApiHostname;
  }

  const ticketNo = branchName?.split("-").slice(0, 2).join("-");
  return hostnameMap[ticketNo]?.hostname || hostnameMap["default"].hostname;
};
