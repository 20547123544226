import styled from "styled-components";
import { Row } from "../../components/layout/Flex";
import ascending from "../../images/icons/ascending.svg";
import descending from "../../images/icons/descending.svg";

import Tooltip from "../Tooltip";

export const PaybackHeader = ({ sortColumn, sortAscending, sortValue }) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Payback
        {getSortingIcon(sortColumn, sortValue, sortAscending)}
      </ColumnTextStyle>
      <Tooltip text="The number of years required to pay back the up front investment given the expected annual revenue."></Tooltip>
    </ColumnContainer>
  );
};

export const TenantsHeader = ({ sortColumn, sortValue, sortAscending }) => {
  return (
    <ColumnTextStyle>
      Tenants
      {getSortingIcon(sortColumn, sortValue, sortAscending)}
    </ColumnTextStyle>
  );
};

export const IRRHeader = ({ sortColumn, sortAscending, sortValue }) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        IRR
        {getSortingIcon(sortColumn, sortValue, sortAscending)}
      </ColumnTextStyle>
      <Tooltip text="IRR is calculated based on expected revenue as it relates to project cost."></Tooltip>
    </ColumnContainer>
  );
};

export const RevenueHeader = ({
  mode,
  sortColumn,
  sortAscending,
  sortValue,
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Revenue <br />
        (year 1)
        <Row $padding={"0px"} $margin={"0px"} $alignItems={"center"}>
          {getSortingIcon(sortColumn, sortValue, sortAscending)}
        </Row>
      </ColumnTextStyle>
      <Row $padding={"0px"} $margin={"0px"} $alignItems={"center"}>
        <Tooltip
          text={
            mode === "lease"
              ? "Estimated revenue generated in the first year based on cost savings from existing behind the meter usage as well as generated from selling excess energy."
              : "Estimated revenue generated in the first year based on turnover rent - a percentage of operating profit derived from the on-site and export PPAs payable in arrears over the turnover rent period."
          }
        ></Tooltip>
      </Row>
    </ColumnContainer>
  );
};

export const SiteHeader = ({ sortColumn, sortValue, sortAscending }) => {
  return (
    <ColumnTextStyle>
      Site
      {getSortingIcon(sortColumn, sortValue, sortAscending)}
    </ColumnTextStyle>
  );
};

export const CarbonSavingsHeader = ({
  mode,
  sortColumn,
  sortAscending,
  sortValue,
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Carbon Savings
        <br />
        (year 1)
        <Row $padding={"0px"} $margin={"0px"} $alignItems={"center"}>
          {getSortingIcon(sortColumn, sortValue, sortAscending)}
        </Row>
      </ColumnTextStyle>
      <Row $padding={"0px"} $margin={"0px"} $alignItems={"center"}>
        <Tooltip
          text={
            mode === "lease"
              ? "Emissions avoided in the first year where the CO2e multiplier per MWh is based on the annual carbon intensity of the grid at this site location.\n\nCFE is calculated based on hourly data  as (# MWh contracted with InRange)  + (#MWh consumed from grid * proportion of regional grid CFE)) / (# MWh total demand)."
              : "Emissions avoided in the first year  where the CO2e multiplier per MWh is based on the annual carbon intensity of the grid at this site location.\n\nCFE is calculated based on hourly data as (# MWh contracted with InRange)  + (#MWh consumed from grid * proportion of regional grid CFE)) / (# MWh total demand)."
          }
        ></Tooltip>
      </Row>
    </ColumnContainer>
  );
};

export const SiteStatusHeader = ({
  sortColumn,
  sortValue,
  sortAscending,
  displayInRangeScoreCol,
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle
        style={{
          width: `${(110 + (displayInRangeScoreCol ? 20 : 0)).toString()}px`,
        }}
      >
        {`Site status${displayInRangeScoreCol ? " / score" : ""}`}
        <Row $padding={"0px"} $margin={"0px"} $alignItems={"center"}>
          {getSortingIcon(sortColumn, sortValue, sortAscending)}
        </Row>
      </ColumnTextStyle>
      {displayInRangeScoreCol && (
        <Row $padding={"0px"} $margin={"0px"} $alignItems={"center"}>
          <Tooltip
            text={
              "InRange score is calculated based on a number of factors, including the site’s savings potential, the likelihood of sufficient network availability, proximity of other InRange sites, and the characteristics of other sites in your portfolio."
            }
          />
        </Row>
      )}
    </ColumnContainer>
  );
};

export const InitialInvestmentHeader = ({
  sortColumn,
  sortAscending,
  sortValue,
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Up front investment
        {getSortingIcon(sortColumn, sortValue, sortAscending)}
      </ColumnTextStyle>
      <Tooltip text="The up front investment for the site including hardware and installation."></Tooltip>
    </ColumnContainer>
  );
};

export const NetworkAvailabilityHeader = ({
  mode,
  sortColumn,
  sortValue,
  sortAscending,
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Network Availability
        {getSortingIcon(sortColumn, sortValue, sortAscending)}
      </ColumnTextStyle>

      <Tooltip
        text={
          mode === "lease"
            ? "The projected network availability at the substation for this site.\nAvilable: Expected to have sufficient capacity for your estimated generation.\nUnavailable: Expected to not have sufficient capacity for your estimated generation.\nComing soon: Insufficient data, pending DNO engagement.\nSubject to change based on DNO capacity changes."
            : "The projected network availability at the substation for this site.\nAvailable: Expected to have sufficient capacity for your estimated generation.\nUnavailable: Expected to not have sufficient capacity for your estimated generation.\nComing soon: Insufficient data, pending DNO engagement.Subject to change based on DNO capacity changes."
        }
      ></Tooltip>
    </ColumnContainer>
  );
};

export const RevenuePerAreaHeader = ({
  sortColumn,
  sortAscending,
  sortValue,
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Revenue per ft² <br /> (year 1)
        <Row $padding={"0px"} $margin={"0px"} $alignItems={"center"}>
          {getSortingIcon(sortColumn, sortValue, sortAscending)}
        </Row>
      </ColumnTextStyle>
      <Row $padding={"0px"} $margin={"0px"} $alignItems={"center"}>
        <Tooltip text="Estimated revenue generated in the first year divided by the size of the roof in ft2."></Tooltip>
      </Row>
    </ColumnContainer>
  );
};

export const RooftopLeasedHeader = ({
  sortColumn,
  sortAscending,
  sortValue,
}) => {
  return (
    <ColumnContainer>
      <ColumnTextStyle>
        Rooftop Leased
        {getSortingIcon(sortColumn, sortValue, sortAscending)}
      </ColumnTextStyle>
      <Tooltip text="Total rooftop space available on this site to be leased by InRange."></Tooltip>
    </ColumnContainer>
  );
};

export const ColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ColumnTextStyle = styled.div`
  display: flex;
  justify-content: start;
  gap: 5px;
`;

export const getSortingIcon = (sortColumn, sortValue, sortAscending) => {
  if (sortColumn === sortValue) {
    return sortAscending ? (
      <img
        src={ascending}
        alt="sortIcon"
        style={{ width: "16px", height: "16px" }}
      />
    ) : (
      <img
        src={descending}
        alt="sortIcon"
        style={{ width: "16px", height: "16px" }}
      />
    );
  }
  return <div style={{ width: "16px", height: "16px" }}></div>;
};
