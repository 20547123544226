import styled from "styled-components";

const TableCellData = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  justify-items: center;
  text-align: left;
`;

export default TableCellData;
