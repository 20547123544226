import { formatKiloUnitsWithValue } from "../../formatting/formatKiloUnits";
import TableCellData from "./TableCellData";

import available from "../../images/icons/available.svg";
import comingSoon from "../../images/icons/comingSoon.svg";
import unavailable from "../../images/icons/unavailable.svg";

const GenerationHeadroomCell = ({ site }) => {
  const { success, generationHeadroomKva } = site.substationData;
  return (
    <GenerationHeadroomCelllView
      success={success}
      generationHeadroomKva={generationHeadroomKva}
    />
  );
};

const GenerationHeadroomCelllView = ({ success, generationHeadroomKva }) => {
  let networkAvailabilityIcon = comingSoon;
  let networkAvailabilityAlt = "Coming Soon";

  if (!success) {
    return (
      <TableCellData>
        <img src={networkAvailabilityIcon} alt={networkAvailabilityAlt} />
      </TableCellData>
    );
  }

  if (generationHeadroomKva > 0) {
    networkAvailabilityIcon = available;
    networkAvailabilityAlt = "Available";
  } else {
    networkAvailabilityIcon = unavailable;
    networkAvailabilityAlt = "Unavailable";
  }

  return (
    <>
      <TableCellData>
        <img src={networkAvailabilityIcon} alt={networkAvailabilityAlt} />
        <br />
        {formatKiloUnitsWithValue(generationHeadroomKva, "VA")}
      </TableCellData>
    </>
  );
};

export default GenerationHeadroomCell;
