import { Link } from "react-router-dom";
import styled from "styled-components";

const SiteNameAddressData = ({ siteName, siteAddress, siteId, orgId }) => {
  const siteUrl = `/org/${orgId}/site/${siteId}`;
  return (
    <TableCellSite>
      <SiteName to={siteUrl}>{siteName}</SiteName>
      <SiteAddress>{siteAddress}</SiteAddress>
    </TableCellSite>
  );
};

const TableCellSite = styled.span`
  display: flex;
  flex-direction: column;
`;

const SiteName = styled(Link)`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #101828;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

const SiteAddress = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #475467;
`;

export default SiteNameAddressData;
