import calcSiteOwnershipForOrg from "../../utils/calcSiteOwnershipForOrg";

export const siteCanBuy = (organisation, site) => {
  if (organisation && site) {
    const isNorthernIreland =
      site.countryCode === "GBR" && site.dno?.name === "Unknown";
    if (isNorthernIreland) {
      return false;
    }
    const ownership = calcSiteOwnershipForOrg(site, organisation);
    if (ownership == "tenant" || ownership == "ownerOccupier") {
      return true;
    }
  }
  return false;
};

export const siteCanSell = (organisation, site) => {
  if (organisation && site) {
    const isNorthernIreland =
      site.countryCode === "GBR" && site.dno?.name === "Unknown";
    if (isNorthernIreland) {
      return false;
    }
    if (site.installedCapacity === 0) {
      return false;
    }
    const ownership = calcSiteOwnershipForOrg(site, organisation);
    if (
      site.installedCapacity > 0 &&
      (ownership === "landlord" || ownership === "ownerOccupier") &&
      site.activeInvestmentModel === "license"
    ) {
      return true;
    }
  }
  return false;
};
