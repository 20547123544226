export const siteStatusMap = {
  notStarted: ["Not started"],
  contractSigned: ["Contract signed"],
  loaPending: ["LOA pending"],
  gridAppPendingSurveyInProgress: ["Grid app pending", "Survey in progress"],
  gridAppPendingSurveyCompleted: ["Grid app pending", "Survey completed"],
  constructionPrelimsInProgress: ["Construction prelims", "in progress"],
  installationRoofWorks: ["Installation", "Roof works"],
  installationElectrical: ["Installation", "Electrical"],
  witnessTestingInProgress: ["Witness testing in progress"],
  waitingOnDnoWitnessTest: ["Waiting on DNO", "Witness test"],
  operational: ["Operational"],
};
