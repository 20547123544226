import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useMutation, useQueries, useQuery, useQueryClient } from "react-query";
import deleteOrganisationUser from "../http/deleteOrganisationUser";
import getOrganisation from "../http/getOrganisation";
import getSite from "../http/getSite";
import postOrganisation from "../http/postOrganisation";
import postOrganisationUser from "../http/postOrganisationUser";
import putOrganisation from "../http/putOrganisation";

const useOrganisation = (orgId, userOrgId) => {
  const { getToken, getClaim } = useKindeAuth();
  const kindeTokenOrgId = getClaim("external_org_id")?.value;
  userOrgId = kindeTokenOrgId === "inrange.io" ? userOrgId : undefined;

  const queryClient = useQueryClient();
  const fetchOrganisation = useQuery(
    ["org", orgId, "relatedorgs"],
    async () => {
      return getOrganisation(await getToken(), orgId, userOrgId);
    },
    { enabled: !!orgId }
  );

  const useOrganisationSites = (siteOwnerships = []) => {
    const siteQueries = useQueries(
      siteOwnerships.map((siteOwnership) => {
        const siteId = siteOwnership.siteID;
        return {
          queryKey: ["site", siteId],
          queryFn: async () => getSite(await getToken(), siteId, userOrgId),
          staleTime: 5 * 60 * 1000,
        };
      })
    );
    return siteQueries;
  };

  const updateOrganisation = useMutation(
    async (update) => putOrganisation(await getToken(), orgId, update),
    {
      onSuccess: (_) => {
        queryClient.invalidateQueries(["org", orgId]);
      },
    }
  );

  const addOrgUser = useMutation(
    async (data) => {
      return postOrganisationUser(await getToken(), orgId, data, userOrgId);
    },
    {
      onSuccess: (_) => {
        queryClient.invalidateQueries(["org", orgId]);
      },
    }
  );

  const removeOrgUser = useMutation(
    async (user) => {
      return deleteOrganisationUser(await getToken(), orgId, user);
    },
    {
      onSuccess: (_) => {
        queryClient.invalidateQueries(["org", orgId]);
      },
    }
  );

  const createOrg = useMutation(
    async (org) => postOrganisation(await getToken(), org),
    {
      onSuccess: (addedOrg) => {
        console.log("addedOrg", addedOrg);
      },
    }
  );

  return {
    addOrgUser,
    removeOrgUser,
    createOrg,
    fetchOrganisation,
    useOrganisationSites,
    updateOrganisation,
  };
};

export default useOrganisation;
