import { KINDE_ENVIRONMENT } from "@inrange/auth";
import { Loading } from "@inrange/theme-components";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminChooseOrgPage from "./AdminChooseOrgPage";
import { UserContext } from "./UserContext";

const RedirectToDashboard = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (navigate && user && user.org_id !== "inrange.io") {
      navigate(`/org/${user.org_id}/dashboard`, {
        replace: true,
      });
    }
  }, [navigate, user]);

  if (user?.kinde_org_code === KINDE_ENVIRONMENT.KINDE_INRANGE_ORG_CODE) {
    return <AdminChooseOrgPage />;
  }

  return <Loading label="Logging in ..." />;
};

export default RedirectToDashboard;
