import styled from "styled-components";

const Dropdown = styled.select`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  width: 100%;
  height: 24px;
  font-size: 14px;
  margin-bottom: 1px;
  border: none;
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 6px) center !important;
  appearance: none !important;
  padding-right: 2rem !important;
`;
// Background url is a down arrow svg, we provide this so that we can style the select element
// and move the arrow to the desired position

export const DarkDropdown = styled(Dropdown)`
  background-color: #00022d;
  background-image: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23FFFFFF' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>");
  color: white;
`;

export default Dropdown;
