import styled from "styled-components";
import formatCurrency from "../../formatting/formatCurrency";

export const ProjectCosts = ({
  hardwareCost,
  modelName,
  installationCost,
  gridConnectionCost,
}) => {
  return (
    <ProjectCostList>
      <ProjectCost label="Hardware" value={formatCurrency(hardwareCost)} />
      <ProjectCost
        label="Installation"
        value={formatCurrency(installationCost)}
      />
      <ProjectCost
        label="Grid Connection"
        value={
          modelName === "License"
            ? formatCurrency(gridConnectionCost)
            : formatCurrency(0)
        }
      />
    </ProjectCostList>
  );
};

const ProjectCostList = styled.ul`
  margin: 0 8px;
  padding: 0;
  list-style: none;
`;

const ProjectCost = ({ label, value }) => {
  return (
    <ProjectCostListItem>
      <ProjectCostListItemLabel>{label}</ProjectCostListItemLabel>
      <ProjectCostListIteValue>{value}</ProjectCostListIteValue>
    </ProjectCostListItem>
  );
};

const ProjectCostListItem = styled.li`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  padding: 0 8px;
`;
const ProjectCostListItemLabel = styled.p``;
const ProjectCostListIteValue = styled.p`
  text-align: right;
`;
