import axiosConfig from "./axiosConfig";

const getSiteSdmList = async (
  authToken,
  siteId,
  getAllResults,
  onlyLinkedOrgs,
  userOrgId
) => {
  const { data } = await axiosConfig.get(
    `/site/${siteId}/marketplace?${getAllResults ? "&getAllResults=true" : ""}${
      onlyLinkedOrgs ? "&onlyLinkedOrgs=true" : ""
    }${userOrgId ? `&userOrgId=${userOrgId}` : ""}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return data;
};

export default getSiteSdmList;
