import styled from "styled-components";
import formatCurrency from "../formatting/formatCurrency";
import formatPercentage from "../formatting/formatPercentage";
import roundUpPayback from "../utils/roundUpPayback";
import Tooltip from "./Tooltip";
import SimpleButton from "./buttons/SimpleButton";
import { Row } from "./layout/Flex";

const SiteTariffDisplay = ({
  behindMeter,
  tariff,
  networkImport,
  networkImportTariff,
  isLease,
  irr,
  payback,
  buyOffer, // { loading, description, buyOfferFn, buyOfferUrl }
}) => {
  const behindMeterValue = behindMeter * tariff;
  const networkImportValue = networkImport * networkImportTariff;
  const total = networkImportValue + behindMeterValue;

  const CostDisplay = ({ isLease, irr, payback, totalCost }) => {
    return isLease ? (
      <TotalCostDisplay totalCost={totalCost} />
    ) : (
      <IrrPaybackDisplay irr={irr} payback={payback} />
    );
  };

  return (
    <SiteTariffDisplayWrapper>
      <ProcurementData>
        <MetricTooltip>
          <Tooltip
            text={
              isLease
                ? "Procured behind the meter is your demand during daylight hours that can be met by your on-site generation, multiplied by a fixed tariff.\n\nIf your demand during daylight hours is higher than your on-site generation, you can procure energy from the InRange network, multiplied by the fixed  tariff length."
                : "Procured behind the meter is your demand during daylight hours that can be met by your on-site generation, multiplied by  a fixed tariff.\n\nIf your demand during daylight hours is higher than your on-site generation, you can procure energy from the InRange network, multiplied by your fixed tariff."
            }
          />
        </MetricTooltip>
        <h3>Cost of energy procured from InRange</h3>
        <div>
          <h2>Procured behind the meter - fixed tariff</h2>
          <Gap>
            <Subtitle data-testid={`tariff-bhm-tariff`}>
              Tariff: {(tariff * 100).toFixed(2)}p/kWh
            </Subtitle>
            <Subtitle data-testid={`tariff-bhm-demand`}>
              Demand: {behindMeter.toFixed(2)} kWh
            </Subtitle>
          </Gap>
          <p data-testid={`tariff-bhm-value`}>
            {formatCurrency(behindMeterValue)}
          </p>
        </div>
        <Border />
        <div>
          <h2>Procured from InRange network - fixed tariff</h2>
          <Gap>
            <Subtitle data-testid={`tariff-network-tariff`}>
              Tariff: {(networkImportTariff * 100).toFixed(2)}p/kWh
            </Subtitle>
            <Subtitle data-testid={`tariff-network-demand`}>
              Demand: {networkImport.toFixed(2)} kWh
            </Subtitle>
          </Gap>
          <p data-testid={`tariff-network-value`}>
            {formatCurrency(networkImportValue)}
          </p>
          {buyOffer && (
            <Row $alignItems={"center"} $margin={"0px"} $padding={"0px"}>
              <div style={{ flex: 1, fontSize: "14px", fontWeight: "500" }}>
                {buyOffer.loading && (
                  <span>Finding a Marketplace buy offer…</span>
                )}
                {!buyOffer.loading && <>{buyOffer.description}</>}
              </div>
              <SimpleButton
                style={{ width: "160px" }}
                fontWeight="400"
                label={"Buy more energy"}
                onClick={buyOffer.buyOfferFn}
                to={buyOffer.buyOfferUrl}
                data-testid={`tariff-network-buy-button`}
              />
            </Row>
          )}
        </div>
      </ProcurementData>
      <CostDisplay
        totalCost={total}
        irr={irr}
        payback={payback}
        isLease={isLease}
      />
    </SiteTariffDisplayWrapper>
  );
};

export default SiteTariffDisplay;

const IrrPaybackDisplay = ({ irr, payback }) => {
  return (
    <PaybackIRRData>
      <MetricTooltip>
        <Tooltip
          text={
            "IRR is calculated based on expected revenue as it relates to project cost. Payback is the number of years required to pay back the up front investment given the expected annual revenue."
          }
        />
      </MetricTooltip>

      <h2>IRR and Payback</h2>
      <p>
        Projected IRR and payback from this site's PV system given known demand
        data.
      </p>
      <span>
        <div>
          <h3>{formatPercentage(irr)}</h3>
          <p>IRR</p>
        </div>
        <div>
          <h3>{roundUpPayback(payback)} months</h3>
          <p>Payback</p>
        </div>
      </span>
    </PaybackIRRData>
  );
};

const TotalCostDisplay = ({ totalCost }) => {
  return (
    <TotalData>
      <h2>Total cost of energy procured from InRange</h2>
      <h3>{formatCurrency(totalCost)}</h3>
      <p>As generated in year 1</p>
    </TotalData>
  );
};

const SiteTariffDisplayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  height: 520px;
`;

const Gap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
const Border = styled.div`
  border: 0.5px solid rgba(0, 0, 0, 0.1);
`;

const Subtitle = styled.span`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #9f9f9f;
`;

const ProcurementData = styled.div`
  border-radius: 16px;
  width: 430px;
  background: white;
  padding: 16px 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  h2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  h3 {
    margin-top: 0px;
  }
  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }
`;

const TotalData = styled.div`
  border-radius: 16px;
  width: 430px;
  height: 184px;
  background: ${({ theme }) => theme.colors.primary}};
  padding: 32px 16px 8px;
  display: flex;
  flex-direction: column;

  h2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    margin: 0;
    padding: 0;
    color: #fff;
  }
  h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    margin: 8px 0 4px;
    padding: 0;
    color: #fff;
  }
  p {
    font-weight: 400;
    font-size: 10px;
    margin: 10;
    padding: 0;
    text-align: center;
    color: #fff;
  }
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
  span div{
    display: flex;
    flex-direction: column;
  }
`;

const PaybackIRRData = styled(TotalData)`
  padding: 10px 16px 9px;
  h2 {
    text-align: left;
    font-size: 20px;
  }
  h3 {
    margin: 25px 0 0 0px;
  }
  > p {
    text-align: left;
  }
  span {
    margin-top: -15px;
  }
`;

const MetricTooltip = styled.div`
  align-self: flex-end;
`;
