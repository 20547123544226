import styled from "styled-components";
import formatCurrency from "../../formatting/formatCurrency";
import formatPercentage from "../../formatting/formatPercentage";

import ChartUpRightIcon from "../../images/icons/chartUpRight.svg";
import CurrencyIcon from "../../images/icons/currencyGbp.svg";
import OverlappingCirclesIcon from "../../images/icons/overlappingCircles.svg";
import SwappingCirclesIcon from "../../images/icons/swappingCircles.svg";

export const MetricsRow = styled.div`
  display: flex;
  width: 100%;
`;

export const Metric = ({ iconSrc, label, value }) => {
  return (
    <MetricWrapper>
      <MetricIcon src={iconSrc} />
      <MetricLabel>{label}</MetricLabel>
      <MetricValue>{value}</MetricValue>
    </MetricWrapper>
  );
};

const MetricWrapper = styled.div`
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  padding: 16px;
  flex-basis: 100%;
  margin: 8px;
`;

const MetricIcon = styled.img`
  width: 25px;
  height: 25px;
  background: ${({ theme }) => theme.colors.blueFaint};
`;
const MetricLabel = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`;

const MetricValue = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
`;

export const LeaseMetrics = ({ ner, annualRevenue }) => {
  return (
    <>
      <Metric
        iconSrc={ChartUpRightIcon}
        label="Airspace rent (per ft²)"
        value={(ner * 100).toFixed(2) + "p"}
      />
      <MetricsRow>
        <Metric
          iconSrc={OverlappingCirclesIcon}
          label="Up front investment"
          value={formatCurrency(0, "gbp")}
        />
        <Metric
          iconSrc={CurrencyIcon}
          label="Revenue in year 1"
          value={formatCurrency(annualRevenue, "GBP")}
        />
      </MetricsRow>
    </>
  );
};

export const LicenseMetrics = ({
  irr,
  paybackMonths,
  initialInvestment,
  annualRevenue,
}) => {
  return (
    <>
      <MetricsRow>
        <Metric
          iconSrc={SwappingCirclesIcon}
          label="IRR"
          value={formatPercentage(irr)}
        />
        <Metric
          iconSrc={ChartUpRightIcon}
          label="Payback (months)"
          value={paybackMonths}
        />
      </MetricsRow>
      <MetricsRow>
        <Metric
          iconSrc={OverlappingCirclesIcon}
          label="Up front investment"
          value={formatCurrency(initialInvestment, "gbp")}
        />
        <Metric
          iconSrc={CurrencyIcon}
          label="Revenue in year 1"
          value={formatCurrency(annualRevenue, "GBP")}
        />
      </MetricsRow>
    </>
  );
};
