import styled from "styled-components";
import AccountMenu from "./AccountMenu";
import BreadCrumbs from "./Breadcrumbs";
import SiteStatus from "./SiteStatus";

const Header = ({
  crumbs,
  userEmail,
  rootPath,
  score,
  siteStatus,
  siteOperationalDateEpochSeconds,
}) => {
  return (
    <HeaderContainer id={"layout-header"}>
      <BreadCrumbs crumbs={crumbs} rootPath={rootPath} />
      <SiteStatus
        score={score}
        siteStatus={siteStatus}
        siteOperationalDateEpochSeconds={siteOperationalDateEpochSeconds}
      />
      <AccountMenu userEmail={userEmail} />
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled.div`
  background: white;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 16px 20px;
  height: 72px;
`;
