import styled from "styled-components";
import { formatCurrencyToNearestTen } from "../formatting/formatCurrency";
import { formatUnitsToNearestTen } from "../formatting/formatKiloUnits";
import formatMass from "../formatting/formatMass";
import formatPercentage from "../formatting/formatPercentage";
import tariffToPence from "../formatting/tariffToPence";
import circleQuestionMarkTick from "../images/icons/circleQuestionMarkTick.svg";
import Tooltip from "./Tooltip";
import { Row } from "./layout/Flex";
import Grid from "./layout/Grid";

export const OwnerOccupierSiteMetricCards = ({ metrics }) => {
  const hhDemandDataString = metrics.hhDataUploaded
    ? ""
    : " Submitted half hourly demand data improves accuracy.";
  return (
    <MetricCardsRow>
      <Grid
        $cols={1}
        $colwidth={"1fr"}
        $gap={"8px"}
        $margin={"0"}
        $width={"100%"}
        $minWidth={"170px"}
      >
        <SiteMetricCard
          title="Revenue year 1"
          value={formatCurrencyToNearestTen(
            metrics.revenueYearOne,
            "GBP",
            0,
            false
          )}
          tooltipText="Revenue from turnover rent per month, which is a percentage of operating profit derived from the on-site and export PPAs."
          tooltipPosition="left"
          color="white"
          height="80px"
          width="100%"
          titleColor="#5486AD"
        />
        <SiteMetricCard
          title="Lifetime revenue"
          value={formatCurrencyToNearestTen(
            metrics.lifetimeRevenue,
            "GBP",
            0,
            false
          )}
          tooltipText="Revenue from turnover rent per month, which is a percentage of operating profit derived from the on-site and export PPAs."
          tooltipPosition="left"
          color="white"
          height="80px"
          width="100%"
          titleColor="#5486AD"
        />
      </Grid>
      <Grid
        $cols={1}
        $colwidth={"1fr"}
        $gap={"8px"}
        $margin={"0"}
        $width={"100%"}
        $minWidth={"192px"}
      >
        <SiteMetricCard
          title="Up front investment"
          value={formatCurrencyToNearestTen(
            metrics.initialInvestment,
            "GBP",
            0,
            false
          )}
          tooltipText="Up front investment is the cost to procure and install the on-site system, as well as accounts for grid connection costs."
          tooltipPosition="left"
          color="white"
          height="80px"
          width="100%"
          titleColor="#5486AD"
        />
        <SiteMetricCard
          title="Total project cost"
          value={formatCurrencyToNearestTen(
            metrics.totalProjectCost,
            "GBP",
            0,
            false
          )}
          tooltipText="Total project cost accounts for up front investment as well as lifetime costs like operations, maintenance, and insurance."
          tooltipPosition="left"
          color="white"
          height="80px"
          width="100%"
          titleColor="#5486AD"
        />
      </Grid>
      <SiteMetricCard
        title="IRR"
        value={formatPercentage(metrics.irr)}
        tooltipText="IRR is calculated based on expected monthly revenue as it relates to total project cost."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        visible={metrics.investmentModel === "license"}
      />
      <SiteMetricCard
        title="Payback"
        value={metrics.payback ? metrics.payback + " months" : "N/A"}
        tooltipText="Payback is the number of months required to pay back the up front investment given the expected annual revenue."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        visible={metrics.investmentModel === "license"}
      />
      <SiteMetricCard
        title="Generation potential year 1"
        value={formatUnitsToNearestTen(
          metrics.generationPotentialYearOne,
          "kWh"
        )}
        tooltipText="Estimated potential for energy generated by the on-site system in the first year."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        visible={metrics.investmentModel === "lease"}
      />
      <SiteMetricCard
        title="Demand fulfilled by InRange vs. others"
        value={formatPercentage(metrics.demandFulfilledByInRange)}
        tooltipText={`Projected percentage of overall that will be fulfilled by on-site generation and/or the InRange network instead of by other suppliers.${hhDemandDataString}`}
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        visible={metrics.investmentModel === "lease"}
      />
      <SiteMetricCard
        title="Savings in year 1"
        value={formatCurrencyToNearestTen(
          metrics.savingsYearOne,
          "GBP",
          0,
          false
        )}
        note={`vs. ${(metrics.marketTariff * 100).toFixed(
          2
        )} p/kWh market tariff`}
        tooltipText="The potential savings from the energy procured in year 1, considering the difference between the current market tariff and the InRange tariff."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
      <SiteMetricCard
        title="Procurement cost from InRange in year 1"
        value={formatCurrencyToNearestTen(
          metrics.procurementCostYearOne,
          "GBP",
          0,
          false
        )}
        tooltipText={`Procured behind the meter demand that can be met by on-site generation and/or the InRange network in year 1, multiplied by the relevant fixed tariff.${hhDemandDataString}`}
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
    </MetricCardsRow>
  );
};

export const LandlordSiteMetricCards = ({ metrics }) => {
  const hhDemandDataString = metrics.hhDataUploaded
    ? ""
    : " Submitted half hourly demand data improves accuracy.";
  return (
    <MetricCardsRow>
      <Grid
        $cols={1}
        $colwidth={"1fr"}
        $gap={"8px"}
        $margin={"0"}
        $width={"100%"}
        $minWidth={"170px"}
      >
        <SiteMetricCard
          title="Revenue year 1"
          value={formatCurrencyToNearestTen(
            metrics.revenueYearOne,
            "GBP",
            0,
            false
          )}
          tooltipText="Revenue from turnover rent per month, which is a percentage of operating profit derived from the on-site and export PPAs."
          tooltipPosition="left"
          color="white"
          height="80px"
          width="100%"
          titleColor="#5486AD"
        />
        <SiteMetricCard
          title="Lifetime revenue"
          value={formatCurrencyToNearestTen(
            metrics.lifetimeRevenue,
            "GBP",
            0,
            false
          )}
          tooltipText="Lifetime revenue from turnover rent per month, which is a percentage of operating profit derived from the on-site and export PPAs."
          tooltipPosition="left"
          color="white"
          height="80px"
          width="100%"
          titleColor="#5486AD"
        />
      </Grid>
      <Grid
        $cols={1}
        $colwidth={"1fr"}
        $gap={"8px"}
        $margin={"0"}
        $width={"100%"}
        $minWidth={"192px"}
      >
        <SiteMetricCard
          title="Up front investment"
          value={formatCurrencyToNearestTen(
            metrics.initialInvestment,
            "GBP",
            0,
            false
          )}
          tooltipText="Up front investment is the cost to procure and install the on-site system, as well as accounts for grid connection costs."
          tooltipPosition="left"
          color="white"
          height="80px"
          width="100%"
          titleColor="#5486AD"
        />
        <SiteMetricCard
          title="Total project cost"
          value={formatCurrencyToNearestTen(
            metrics.totalProjectCost,
            "GBP",
            0,
            false
          )}
          tooltipText="Total project cost accounts for up front investment as well as lifetime costs like operations, maintenance, and insurance."
          tooltipPosition="left"
          color="white"
          height="80px"
          width="100%"
          titleColor="#5486AD"
        />
      </Grid>
      <SiteMetricCard
        title="IRR"
        value={formatPercentage(metrics.irr)}
        tooltipText="IRR is calculated based on expected monthly revenue as it relates to total project cost."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        visible={metrics.investmentModel === "license"}
      />
      <SiteMetricCard
        title="Payback"
        value={metrics.payback ? metrics.payback + " months" : "N/A"}
        tooltipText="Payback is the number of months required to pay back the up front investment given the expected annual revenue."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        visible={metrics.investmentModel === "license"}
      />
      <SiteMetricCard
        title="Revenue per ft²"
        value={`${(metrics.revenuePerFt2 * 100).toFixed(2)}p`}
        tooltipText="Estimated revenue generated in the first year divided by the size of the roof in ft²."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        visible={metrics.investmentModel === "lease"}
      />
      <SiteMetricCard
        title="On-site demand year 1"
        value={formatUnitsToNearestTen(metrics.onSiteDemandYearOne, "kWh")}
        tooltipText={`Estimated demand for the on-site tenant in the first year.${hhDemandDataString}`}
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        visible={metrics.investmentModel === "lease"}
      />
      <SiteMetricCard
        title="Generation potential year 1"
        value={formatUnitsToNearestTen(
          metrics.generationPotentialYearOne,
          "kWh"
        )}
        tooltipText="Estimated potential for energy generated by the on-site system in the first year."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
      <SiteMetricCard
        title="On-site generation consumed"
        value={formatPercentage(metrics.onSiteGenerationConsumed)}
        tooltipText={`Projected percentage of generation that will be consumed by the occupational tenant.${hhDemandDataString}`}
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
      <SiteMetricCard
        title="Emissions avoided year 1"
        value={[
          `${formatMass(metrics.emissionsAvoidedYearOne)} CO2e`,
          `${formatPercentage(metrics.cfeScore)} CFE`,
        ]}
        tooltipText="Emissions avoided in the first year after installation where the CO2e multiplier per MWh is based on the annual carbon intensity of the grid at each this site location."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        $minWidth={"140px"}
      />
    </MetricCardsRow>
  );
};

export const TenantSiteMetricCards = ({ metrics }) => {
  const hhDemandDataString = metrics.hhDataUploaded
    ? ""
    : " Submitted half hourly demand data improves accuracy.";
  const hhDemandDataStringShort = metrics.hhDataUploaded
    ? ""
    : "Submitted HH demand increases accuracy.";
  return (
    <MetricCardsRow>
      <SiteMetricCard
        title="Procurement cost from InRange in year 1"
        value={formatCurrencyToNearestTen(
          metrics.procurementCostYearOne,
          "GBP",
          0,
          false
        )}
        tooltipText={`Procured behind the meter demand that can be met by on-site generation and/or the InRange network in year 1, multiplied by your fixed tariff.${hhDemandDataString}`}
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
      <SiteMetricCard
        title="Procured energy from InRange year 1"
        value={formatUnitsToNearestTen(metrics.procuredEnergyYearOne, "kWh")}
        tooltipText={`Projected behind the meter demand that can be met by on-site generation and/or the InRange network in year 1.${hhDemandDataString}`}
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
      <SiteMetricCard
        title="Savings in year 1"
        value={formatCurrencyToNearestTen(
          metrics.savingsYearOne,
          "GBP",
          0,
          false
        )}
        note={`vs. ${(metrics.marketTariff * 100).toFixed(
          2
        )} p/kWh market tariff`}
        tooltipText="The potential savings from the energy procured in year 1, considering the difference between the current market tariff and the InRange tariff."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
      <SiteMetricCard
        title="Total demand in year 1"
        value={formatUnitsToNearestTen(metrics.totalDemandYearOne, "kWh")}
        tooltipText={`The total estimated energy demand in the first year. ${hhDemandDataString}`}
        note={hhDemandDataStringShort}
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
      <SiteMetricCard
        title="Demand fulfilled by InRange vs. others"
        value={formatPercentage(metrics.demandFulfilledByInRange)}
        tooltipText={`Projected percentage of overall that will be fulfilled by on-site generation and/or the InRange network instead of by other suppliers. ${hhDemandDataString}`}
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
      <SiteMetricCard
        title="Emissions avoided in year 1"
        value={[
          `${formatMass(metrics.emissionsAvoidedYearOne)} CO2e`,
          `${formatPercentage(metrics.cfeScore)} CFE`,
        ]}
        tooltipText="Emissions avoided in the first year after installation where the CO2e multiplier per MWh is based on the annual carbon intensity of the grid at this site location."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
    </MetricCardsRow>
  );
};

export const LandlordSiteListMetricCards = ({ metrics, isLeaseMetrics }) => {
  return (
    <MetricCardsRow>
      <SiteMetricCard
        title="Total sites"
        value={metrics.totalSites}
        color="grey"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
      <SiteMetricCard
        title={`Total revenue in year 1 (${
          isLeaseMetrics ? "lease" : "licensed"
        })`}
        value={formatCurrencyToNearestTen(
          metrics.totalRevenue,
          "GBP",
          0,
          false
        )}
        tooltipText="Revenue generated in year 1 across all sites, assuming licensing option and not accounting for one-time project cost."
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
      {!isLeaseMetrics && (
        <SiteMetricCard
          title="Total project cost (licensed)"
          value={formatCurrencyToNearestTen(
            metrics.totalProjectCost,
            "GBP",
            0,
            false
          )}
          tooltipText="The total  cost of the project including installation and maintenance across all sites, assuming the license option for all sites."
          color="white"
          height="168px"
          width="100%"
          titleColor="#5486AD"
        />
      )}
      <SiteMetricCard
        title="Total on-site generation in year 1"
        value={formatUnitsToNearestTen(metrics.totalOnSiteGeneration, "kWh")}
        tooltipText="Energy generated in the first year across all sites."
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
      {isLeaseMetrics && (
        <SiteMetricCard
          title="Total savings in year 1"
          value={formatCurrencyToNearestTen(
            metrics.totalSavings,
            "GBP",
            0,
            false
          )}
          tooltipText="The total annual savings you’ll enjoy from procuring energy from InRange. Calculated as the difference between the current market tariff (as stated) and a per site renewable energy tariff and demand."
          color="white"
          height="168px"
          width="100%"
          titleColor="#5486AD"
        />
      )}
      <SiteMetricCard
        title="Total emissions avoided in year 1"
        value={formatMass(metrics.totalEmissionsAvoided) + " CO2e"}
        tooltipText="Emissions avoided in the first year after installation  across all sites where the CO2e multiplier per MWh is based on the annual carbon intensity of the grid at each site location."
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
      <SiteMetricCard
        title="Total procured from InRange in year 1"
        value={formatUnitsToNearestTen(metrics.totalEnergyProcured, "kWh")}
        tooltipText="The amount of solar energy you procure from InRange in the first year. Includes behind the meter consumption from your own generation as well as energy imported from the InRange network."
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
    </MetricCardsRow>
  );
};

export const TenantSiteListMetricCards = ({ metrics }) => {
  const hhDemandDataString = metrics.hhDataUploaded
    ? ""
    : " Submitting your half hourly data per site will increase accuracy.";
  return (
    <MetricCardsRow>
      <SiteMetricCard
        title="Total sites"
        value={metrics.totalSites}
        color="grey"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
      <SiteMetricCard
        title="Total annual demand"
        value={formatUnitsToNearestTen(metrics.totalAnnualDemand, "kWh")}
        tooltipText={`The total estimated energy demand across all sites.${hhDemandDataString}`}
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
      <SiteMetricCard
        title="Average tariff for solar energy"
        value={tariffToPence(metrics.averageTariff)}
        tooltipText="The average tariff for procuring solar energy from InRange across your sites."
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
      <SiteMetricCard
        title="Total procured from InRange in year 1"
        value={formatCurrencyToNearestTen(
          metrics.totalEnergyProcuredCost,
          "GBP",
          0,
          false
        )}
        note="From on-site generation and the InRange network"
        tooltipText="The total amount for all energy procured across sites, given site-specific tariffs and energy demand. Includes energy procured behind the meter from an on-site installation, as well as energy imported from the InRange network."
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
      <SiteMetricCard
        title="Total savings in year 1"
        value={formatCurrencyToNearestTen(
          metrics.totalSavings,
          "GBP",
          0,
          false
        )}
        note={`Based on market tariff of  ${metrics.averageMarketTariff} p/kWh`}
        tooltipText="The total annual savings you’ll enjoy from procuring energy from InRange. Calculated as the difference between the current market tariff (as stated) and a per site renewable energy tariff and demand."
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
      <SiteMetricCard
        title="Total emissions avoided in year 1"
        value={formatMass(metrics.emissionsAvoided) + " CO2e"}
        tooltipText="The total emissions you will have avoided by consuming solar energy across sites. The CO2e multiplier per MWh is based on the annual carbon intensity of the grid at each site location."
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
    </MetricCardsRow>
  );
};

export const SiteProcurementCards = ({
  behindTheMeter,
  inrangeNetwork,
  otherSources,
  behindTheMeterPercent,
  inrangeNetworkPercent,
  otherSourcesPercent,
}) => {
  return (
    <Row $width={"720px"} $height={"120px"} $margin={"0px"} $padding={"0px"}>
      <SiteMetricCard
        title="Procured on-site behind the meter"
        value={formatUnitsToNearestTen(behindTheMeter, "kWh")}
        note={`${behindTheMeterPercent.toFixed(2)} % of your total demand`}
        color="grey"
      />
      <SiteMetricCard
        title="Procured from InRange network"
        value={formatUnitsToNearestTen(inrangeNetwork, "kWh")}
        note={`${inrangeNetworkPercent.toFixed(2)} % of your total demand`}
        color="grey"
      />
      <SiteMetricCard
        title="Procured from other suppliers"
        value={formatUnitsToNearestTen(otherSources, "kWh")}
        note={`${otherSourcesPercent.toFixed(2)} % of your total demand`}
        color="grey"
      />
      <Tooltip
        text={
          "On-site behind the meter is your demand during daylight hours that can be met by your on-site generation.\n\n If your demand during daylight hours is higher than your on-site generation, you can procure energy from the InRange network.\n\nRemaining demand (e.g. during the  night) is procured from other suppliers."
        }
      />
    </Row>
  );
};

export const SiteMetricCard = ({
  title,
  value,
  note,
  color,
  tooltipText,
  width,
  height,
  dataTestId,
  titleHeight,
  tooltipPosition,
  visible = true,
  titleColor,
  $minWidth,
  $maxWidth,
  $flex,
}) => {
  return (
    <MetricCard
      color={color}
      width={width}
      height={height}
      visible={visible}
      $minWidth={$minWidth}
      $maxWidth={$maxWidth}
      $flex={$flex}
    >
      <SiteMetricCardStyle>
        <SiteMetricTitle
          color={color}
          fontColor={titleColor}
          height={titleHeight}
        >
          {title}
        </SiteMetricTitle>
        {tooltipText && (
          <MetricTooltip>
            <Tooltip text={tooltipText} position={tooltipPosition || "right"}>
              <img src={circleQuestionMarkTick} alt="info" />
            </Tooltip>
          </MetricTooltip>
        )}
      </SiteMetricCardStyle>
      <SiteMetricValue data-testid={dataTestId} color={color}>
        {value instanceof Array
          ? value.map((v, index) => <div key={index}>{v}</div>)
          : value}
      </SiteMetricValue>
      {note && <SiteMetricNote>{note}</SiteMetricNote>}
    </MetricCard>
  );
};

const MetricCardsRow = styled(Row)`
  height: 168px;
  margin: 8px 12px;
  padding: 0;
`;

const MetricTooltip = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const SiteMetricCardStyle = styled.div`
  display: flex;
  height: 60%;
  padding-top: 8px;
  justify-content: space-between;
`;

const MetricCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 8px;
  background: white;
  width: ${(props) => (props.width ? props.width : "230px")};
  height: ${(props) => (props.height ? props.height : "112px")};
  /* the below css gives us a neat animation to help inform the user of the updated
  cards */
  overflow: "hidden";
  ${(props) =>
    props.visible
      ? `opacity: 1; transition: opacity 0.5s ease-out;`
      : `width: 0; opacity: 0; padding: 0; margin: -4px;`}
  ${(props) =>
    props.$maxWidth &&
    `
    max-width: ${props.$maxWidth};
  `}
  ${(props) =>
    props.$minWidth &&
    `
    min-width: ${props.$minWidth};
  `}
  ${(props) =>
    props.$flex &&
    `
    flex: ${props.$flex};
  `}
  ${({ color }) => color === "blue" && `background: #00022F`}
  ${({ color }) => color === "grey" && `background: #DFDFDF`}
`;

const SiteMetricTitle = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  height: ${(props) => (props.height ? "35px" : null)};
  margin: 0;
  padding: 0;
  color: ${({ color, fontColor }) =>
    fontColor ? fontColor : color === "blue" && "white"};
`;

const SiteMetricValue = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  margin: 0;
  padding: 0;
  ${({ color }) => color === "blue" && `color: white`}
`;

const SiteMetricNote = styled.p`
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  text-align: left;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.grey900};
`;
