import { useState } from "react";
import styled from "styled-components";
import formatCurrency from "../formatting/formatCurrency";
import NumericalHeaderWithSubheader from "./NumericalHeaderWithSubheader";
import SectionHeaderWithSubHeaderDark from "./SectionHeaderWithSubHeaderDark";
import DualButton from "./buttons/DualButton";

const InvesmentVsSavings = ({ totalProjectCosts, totalAnnualSavings }) => {
  const [lifetimeTimeline, setLifetimeTimeline] = useState(true);

  const useAnnual = () => {
    setLifetimeTimeline(false);
  };
  const useLifetime = () => {
    setLifetimeTimeline(true);
  };

  return (
    <InvesmentVsSavingsContainer>
      <Section1>
        <SectionHeaderWithSubHeaderDark
          mainHeader={"Investments vs Savings"}
          subHeader={"How much your solar system saves you"}
        />
        <DualButton
          button1={{
            label: "Lifetime",
            active: lifetimeTimeline,
            onClick: useLifetime,
            testId: "lifetime-button",
          }}
          button2={{
            label: "Annual",
            active: !lifetimeTimeline,
            onClick: useAnnual,
            testId: "annual-button",
          }}
        />
      </Section1>
      <Section2>
        <NumericalHeaderWithSubheader
          numericalData={formatCurrency(totalProjectCosts)}
          textData={"Total project cost"}
        />
        <NumericalHeaderWithSubheader
          numericalData={formatCurrency(
            lifetimeTimeline ? totalAnnualSavings * 20 : totalAnnualSavings
          )}
          textData={`Total saved ${
            lifetimeTimeline ? "over system lifetime " : "annually"
          } `}
        />
      </Section2>
    </InvesmentVsSavingsContainer>
  );
};

const InvesmentVsSavingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  place-content: space-around;
  width: 500px;
  height: 220px;
  background: #00022f;
  border-radius: 16px;
  flex-grow: 1;
`;

const Section1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
`;

const Section2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

export default InvesmentVsSavings;
