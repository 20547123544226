import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useQuery } from "react-query";
import getSiteSdmList from "../http/getSiteSdmList";

const useSiteSdm = ({ siteId, getAllResults, onlyLinkedOrgs, userOrgId }) => {
  const { getToken, getClaim } = useKindeAuth();
  const kindeTokenOrgId = getClaim("external_org_id")?.value;
  userOrgId = kindeTokenOrgId === "inrange.io" ? userOrgId : undefined;

  onlyLinkedOrgs = onlyLinkedOrgs || false;

  const fetchSiteSdmList = useQuery(
    ["site", siteId, "sdmList"],
    async () => {
      return getSiteSdmList(
        await getToken(),
        siteId,
        getAllResults,
        onlyLinkedOrgs,
        userOrgId
      );
    },
    { enabled: !!siteId }
  );

  const bestBuyOffer = fetchSiteSdmList.data?.result?.buy;
  const bestSellOffer = fetchSiteSdmList.data?.result?.sell;

  const buyOfferEnergyFlows = fetchSiteSdmList.data?.buyOfferEnergyFlows;

  return {
    fetchSiteSdmList,
    bestBuyOffer,
    bestSellOffer,
    buyOfferEnergyFlows,
  };
};

export default useSiteSdm;
