import styled from "styled-components";

const SiteListTable = ({ head, rows, id }) => {
  return (
    <TableContainer>
      <Table id={id}>
        <thead>{head}</thead>
        <tbody>{rows}</tbody>
      </Table>
    </TableContainer>
  );
};

export default SiteListTable;

const TableContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 8px 12px;
  gap: 20px;
  border-radius: 8px;
  background: white;
  th {
    cursor: pointer;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  th {
    text-align: left;
    box-sizing: border-box;
    padding: 12px 24px 12px 12px;
    gap: 12px;
    width: fit-content;
    height: 44px;
    background: #ffffff;
    border-bottom: 1px solid #eaecf0;
    align-self: stretch;
    width: 29px;
    height: 18px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #00022f;
  }
  td {
    box-sizing: border-box;
    text-align: left;
    padding: 16px 24px 16px 12px;
    gap: 12px;
    width: 237px;
    height: 108px;
    border-bottom: 1px solid #eaecf0;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #808080;
  }
`;
