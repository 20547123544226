import axiosConfig from "./axiosConfig";

const postSiteBatteryAnalysis = async (authToken, payload) => {
  const { data } = await axiosConfig.post(
    `/site/preview/battery_analysis`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return data;
};

export default postSiteBatteryAnalysis;
