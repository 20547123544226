import styled from "styled-components";
import magnifyingGlass from "../images/icons/magnifyingGlass.svg";
import IconInput from "./input/IconInput";

const TableListSortFilters = ({ textFilter, setTextFilter, paddingRight }) => {
  return (
    <TableListHeaderRight $paddingRight={paddingRight}>
      <IconInput
        icon={magnifyingGlass}
        type="search"
        placeholder="Search"
        value={textFilter}
        onChange={(e) => setTextFilter(e.target.value)}
        width="214px"
      />
    </TableListHeaderRight>
  );
};

export default TableListSortFilters;

const TableListHeaderRight = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  flex-grow: 1;
  padding-right: ${({ $paddingRight }) => $paddingRight || "12px"};
`;
