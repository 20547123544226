import styled from "styled-components";
import { formatCurrencyToNearestTen } from "../../formatting/formatCurrency";
import formatMass from "../../formatting/formatMass";
import formatPercentage from "../../formatting/formatPercentage";
import LoadingSpinner from "../LoadingSpinner";

const format = (value, format) => {
  if (format === "currency") {
    return formatCurrencyToNearestTen(value, "GBP", 0, false);
  } else if (format === "percent") {
    return formatPercentage(value);
  } else if (format === "mass") {
    return formatMass(value);
  }
  return value;
};

const MetricCard = ({ title, isLoading, value }) => {
  const changeStr =
    value.main === value.secondary
      ? "Unchanged from "
      : value.main >= value.secondary
        ? "Up from "
        : "Down from ";

  return (
    <MetricContainer>
      <TitleRow>
        <Title>{title}</Title>
      </TitleRow>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <>
          <Value>
            {format(value.main, value.format)}
            {value.mainSuffix || ""}
          </Value>
          <SubValue>
            {value.format ? changeStr : ""}
            {format(value.secondary, value.format)}
          </SubValue>
        </>
      )}
    </MetricContainer>
  );
};

const MetricContainer = styled.div`
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 10px;
  max-width: 200px;
  height: 150px;
`;

const TitleRow = styled.div`
  height: 60%;
`;

const Title = styled.div`
  font-size: 15px;
  color: #5486ad;
  font-weight: 500;
`;

const Value = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const SubValue = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.grey900};
`;

export default MetricCard;
