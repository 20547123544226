import styled from "styled-components";
import NetworkMarkerIcon from "../../../images/icons/networkSiteBlue.svg";
import MySiteMarkerIcon from "../../../images/icons/networkSitePurple.svg";
import OfferDestinationMarkerIcon from "../../../images/icons/networkSiteYellow.svg";

const mySitePurple = "#a2a6ff";
const networkSiteBlue = "#2779a7";
const offerDestinationYellow = "#DBA507";

const PulseMarker = ({ count, type, testId }) => {
  const center = getCenter(count, type);
  return (
    <Wrapper data-testid={testId}>
      {center}
      {["0s", "1s", "1.5s", "2s", "2.5s"].map((animationDelay) => (
        <PulsingCircles
          key={animationDelay}
          style={{ animationDelay }}
          type={type}
        />
      ))}
    </Wrapper>
  );
};

export default PulseMarker;

const getCenter = (count, type) => {
  let color;
  let icon;
  if (type === "mysite") {
    color = mySitePurple;
    icon = MySiteMarkerIcon;
  } else if (type === "network") {
    color = networkSiteBlue;
    icon = NetworkMarkerIcon;
  } else if (type === "offerDestination") {
    color = offerDestinationYellow;
    icon = OfferDestinationMarkerIcon;
  } else {
    throw new Error("Invalid type");
  }
  if (count === undefined || count < 1) {
    return <img src={icon} alt="building marker" height={24} />;
  } else {
    return <MultiCount color={color}>{count}</MultiCount>;
  }
};

const PulsingCircles = ({ style, type }) => {
  return <Circle className={`pulsing-circle-${type}`} style={style} />;
};

const MultiCount = styled.div`
  position: absolute;
  ${({ color }) => `background: ${color};`}
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 25px;
  opacity: 0.8;
`;

const Wrapper = styled.div`
  width: 90px;
  height: 90px;
  margin-left: -33px;
  margin-top: -33px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  pointer-events: none;
`;

const Circle = styled.div`
  border-radius: 50%;
  width: 28px;
  height: 28px;
  position: absolute;
  opacity: 0;
`;
