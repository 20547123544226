import axiosConfig from "./axiosConfig";

const getOrganisation = async (authToken, orgId, userOrgId) => {
  const { data } = await axiosConfig.get(
    `/organisation/${orgId}${userOrgId ? `?userOrgId=${userOrgId}` : ""}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return data;
};

export default getOrganisation;
