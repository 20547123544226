const calcSiteOwnershipForOrg = (site, organisation) => {
  const siteOwnership = site.siteOwnerships.find((so) => {
    return so.orgID === organisation.id;
  })?.ownership;
  if (siteOwnership === "ownerOccupier") {
    return siteOwnership;
  } else {
    return organisation.orgType.toLowerCase();
  }
};

export default calcSiteOwnershipForOrg;
