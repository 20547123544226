import axiosConfig from "./axiosConfig";

const getSiteValues = async (authToken) => {
  const { data } = await axiosConfig.get(`/site/values`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return data;
};

export default getSiteValues;
