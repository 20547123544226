import { Link } from "react-router-dom";
import styled from "styled-components";
import { Body1, SectionSubtitle, SectionTitle } from "./Typography";

const TopSitesSidebar = ({ siteFilters }) => {
  if (siteFilters.length === 0) return null;
  return (
    <TopSitesContainer>
      <TopSitesHeader>Top sites</TopSitesHeader>
      {siteFilters.map((siteFilter, index) => (
        <TopSiteLink key={index} siteFilter={siteFilter} />
      ))}
    </TopSitesContainer>
  );
};

export const siteFilters = [
  {
    label: "Top landlords in your sites ",
    url: "#",
    description: "Find out the landlords who unlock the most value.",
  },
  {
    label: "Highest annual savings",
    url: "#",
    description: "Find out your sites with the highest annual savings",
  },
  {
    label: "Best network capacity",
    url: "#",
    description: "Find out your sites with the best network capacity",
  },
];

const TopSiteLink = ({ siteFilter }) => {
  return (
    <TopSiteLinkContainer to={siteFilter.url}>
      <TopSiteLinkHeader>{siteFilter.label}</TopSiteLinkHeader>
      <TopSiteLinkFooter>
        <TopSiteLinkDescription>
          {siteFilter.description}
        </TopSiteLinkDescription>
        <ViewAll>View{"\u00A0"}all</ViewAll>
      </TopSiteLinkFooter>
    </TopSiteLinkContainer>
  );
};

const TopSitesContainer = styled.div`
  width: 250px;
  flex: 0 0 auto;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  padding: 12px 18px 36px;
  margin-left: 16px;
`;

const TopSiteLinkContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  padding: 8px 8px;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  margin: 16px 0;
  border-radius: 8px;
  text-decoration: none;
  color: white;
  :hover {
    color: ${({ theme }) => theme.colors.primary};
    background-color: white;
  }
`;

const TopSitesHeader = styled(SectionTitle)`
  font-size: 18px;
  line-height: 20px;
  weight: 800;
`;

const TopSiteLinkDescription = styled(Body1)`
  font-size: 9px;
  line-height: 14px;
`;

const TopSiteLinkHeader = styled(SectionSubtitle)`
  font-size: 12px;
  line-height: 18px;
  weight: 700;
  color: inherit;
`;

const ViewAll = styled(TopSiteLinkDescription)`
  color: ${({ theme }) => theme.colors.blueSolid};
  font-weight: 800;
`;

const TopSiteLinkFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export default TopSitesSidebar;
