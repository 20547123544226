import axiosConfig from "./axiosConfig";

const getNetworkSiteList = async (authToken, orgId, userOrgId) => {
  const { data } = await axiosConfig.get(
    `/organisation/${orgId}/network_site${
      userOrgId ? `?userOrgId=${userOrgId}` : ""
    }`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return data;
};

export default getNetworkSiteList;
