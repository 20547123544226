import { useRef } from "react";
import { colors, colorsWithBattery } from ".";
import BarChart from "./BarChart";

import { ChartToolTip, ChartToolTipBody } from "../ChartTooltip";
import { NonOverflowTooltip } from "./NonOverflowTooltip";

import { formatUnitsToNearestTen } from "../../formatting/formatKiloUnits";
import TabButtons from "../buttons/TabButtons";
import {
  Chart,
  ChartHeader,
  ChartHeaderWithTabs,
  ChartLegend,
  ChartWrapper,
} from "./common";

const YearDayEnergyChart = ({
  header,
  chartData,
  barColors,
  indexBy, // Key in the objects in chartData to use for x axis
  legendKeys,
  energyProcuredChartPeriod, // ["year", "summer", "winter"]
  setEnergyProcuredChartPeriod,
  height,
  chartHeight,
  isGeneratingSite,
  isBatterySite,
  $padding,
  tooltipFontSize,
}) => {
  const ref = useRef(null);
  if (!barColors) {
    barColors = isBatterySite ? colorsWithBattery : colors;
  }
  if (!legendKeys) {
    legendKeys = Object.keys(chartData[0])
      .filter((key) => key !== indexBy)
      .reduce((acc, key) => {
        acc[key] = key;
        return acc;
      }, {});
    if (!isGeneratingSite) {
      barColors = colors.slice(1);
      delete legendKeys["On-site behind the meter"];
    }
  }

  const ToolTipData = ({ data }) => {
    return (
      <>
        {Object.keys(legendKeys).map((key, index) => (
          <ChartToolTipBody key={index} $fontSize={tooltipFontSize}>
            {legendKeys[key]}:{" "}
            <strong>{formatUnitsToNearestTen(data[key], "kWh")}</strong>
          </ChartToolTipBody>
        ))}
      </>
    );
  };

  const showToolTipFn = (e) => {
    return (
      <NonOverflowTooltip
        point={{ x: e.x, y: e.y }}
        containerSize={ref.current.getBoundingClientRect()}
      >
        <ChartToolTip>
          <ToolTipData data={e.data} />
        </ChartToolTip>
      </NonOverflowTooltip>
    );
  };

  return (
    <ChartWrapper ref={ref} height={height} $padding={$padding}>
      <ChartHeaderWithTabs>
        <div>
          {header && (
            <>
              <ChartHeader>{header}</ChartHeader>
            </>
          )}
        </div>
        <TabButtons
          tab1={{
            label: "Year 1",
            active: energyProcuredChartPeriod === "year",
            onClick: () => setEnergyProcuredChartPeriod("year"),
          }}
          tab2={{
            label: "Daily (Summer)",
            active: energyProcuredChartPeriod === "summer",
            onClick: () => setEnergyProcuredChartPeriod("summer"),
          }}
          tab3={{
            label: "Daily (Winter)",
            active: energyProcuredChartPeriod === "winter",
            onClick: () => setEnergyProcuredChartPeriod("winter"),
          }}
        />
      </ChartHeaderWithTabs>
      <Chart $height={chartHeight}>
        {Object.keys(legendKeys).length > 1 && (
          <ChartLegend
            chartLegend={Object.keys(legendKeys).map((key, index) => ({
              label: legendKeys[key],
              color: barColors[index],
            }))}
          />
        )}
        <BarChart
          chartData={chartData}
          barColors={barColors}
          tooltipFn={showToolTipFn}
          indexBy={indexBy}
          keys={Object.keys(legendKeys)}
          legend={"kWh"}
          stacked={true}
        />
      </Chart>
    </ChartWrapper>
  );
};

export default YearDayEnergyChart;
