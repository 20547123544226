import axiosConfig from "./axiosConfig";

const postOrganisation = async (authToken, org) => {
  const { data } = await axiosConfig.post("/organisation", org, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return data;
};

export default postOrganisation;
